define('webapp/pods/aquisicao/compra/empenho/listagem/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin'], function (exports, Ember, CrudMixin) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {

    needs: ['aquisicao'],

    aquisicaoDivulgadaNoPncp: (function () {
      if (this.get('controllers.aquisicao.model.pncpano') && this.get('controllers.aquisicao.model.pncpseq')) {
        return true;
      }
      return false;
    }).property('controllers.aquisicao.model')

  });

});