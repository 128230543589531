define('webapp/components/tag-situacao-empenho', ['exports', 'ember', 'webapp/pods/pncp/constantes-pncp-envio'], function (exports, Ember, Constantes) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({

    comunicador: Ember['default'].inject.service(),

    constantesOperacoes: Constantes['default'].operacoes,
    constantesUnidades: Constantes['default'].descricoesUnidades,

    actions: {
      enviarAgora: function enviarAgora() {
        this.get('comunicador').servico('post', 'compra/empenho/' + this.empenho.id + '/enviaPNCP', null).then(function () {
          window.location.reload();
        });
      },
      abrirModalPendenciasDeEnvioAoPNCP: function abrirModalPendenciasDeEnvioAoPNCP() {
        this.set('mostrarModalPendenciasDeEnvioAoPNCP', true);
      }
    },

    tiposSituacao: [{ id: "VIGENTE", nome: "Vigente", codigo: 1, cor: "verde" }, { id: "NAO_VIGENTE", nome: "Não Vigente", codigo: 2, cor: "vermelho" }],
    situacaoEmpenho: null,
    mostrarModalPendenciasDeEnvioAoPNCP: false,

    descricaoSituacao: (function () {
      var _this = this;

      if (this.tiposSituacao.find(function (tipo) {
        return tipo.id === _this.situacaoEmpenho;
      })) {
        var situacao = this.tiposSituacao.find(function (tipo) {
          return tipo.id === _this.situacaoEmpenho;
        }).nome;
        if (this.empenho.pncpano && this.empenho.pncpseq) {
          return this.empenho.pendenteEnvioAoPNCP ? situacao + " (Pendente de Envio ao PNCP)" : situacao + " (Divulgado no PNCP)";
        }
        if (!this.empenho.dataVigenciaFim || !this.empenho.dataVigenciaInicio) {
          return situacao;
        }
        return situacao + " (Pendente de Envio ao PNCP)";
      }
    }).property('empenho.dataVigenciaInicio', 'empenho.dataVigenciaFim', 'empenho.pendenteEnvioAoPNCP'),

    aquisicaoDivulgadaNoPNCP: (function () {
      return this.aquisicao.pncpseq && this.aquisicao.pncpano;
    }).property('aquisicao.pncpseq', 'aquisicao.pncpano'),

    corPainel: (function () {
      var _this2 = this;

      return this.tiposSituacao.find(function (tipo) {
        return tipo.id === _this2.situacaoEmpenho;
      }) ? this.tiposSituacao.find(function (tipo) {
        return tipo.id === _this2.situacaoEmpenho;
      }).cor : 'verde';
    }).property('empenho.dataVigenciaInicio', 'empenho.dataVigenciaFim'),

    mostrarLinkPNCP: (function () {
      return this.empenho.urlPNCP;
    }).property('empenho.urlPNCP'),

    possuiPendenciasDeEnvio: (function () {
      return this.empenho.pncpenvioDTO;
    }).property('empenho.pncpenvioDTO'),

    calculaSituacao: (function () {
      var data = new Date();
      data.setHours(0, 0, 0, 0);
      if (this.empenho.dataVigenciaFim && this.empenho.dataVigenciaInicio) {
        if (this.empenho.dataVigenciaFim.getTime() < data.getTime() || this.empenho.dataVigenciaInicio.getTime() > data.getTime()) {
          this.situacaoEmpenho = 'NAO_VIGENTE';
        } else {
          this.situacaoEmpenho = 'VIGENTE';
        }
      } else {
        this.situacaoEmpenho = 'NAO_VIGENTE';
      }
    }).on('init').observes('descricaoSituacao'),

    getDescricaoDaOperacao: function getDescricaoDaOperacao(envioPNCP) {
      // Tenta converter o tipo para uma das opções nas constantes
      var operacao = this.constantesOperacoes.find(function (operacao) {
        return operacao.id === envioPNCP.descricaoDaOperacao;
      });
      if (operacao) {
        return operacao.nome;
      }
      return envioPNCP.descricaoDaOperacao;
    },

    getDescricaoDaUnidade: function getDescricaoDaUnidade(envioPNCP) {
      // Tenta converter o tipo para uma das opções nas constantes
      var unidadeDeEnvio = this.constantesUnidades.find(function (unidade) {
        return unidade.id === envioPNCP.idUnidadeDeEnvio;
      });
      if (unidadeDeEnvio) {
        return unidadeDeEnvio.nome;
      }
      return envioPNCP.descricaoDaUnidadeDeEnvio;
    }
  });

});