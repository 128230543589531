define('webapp/components/tag-situacao-aquisicao', ['exports', 'ember', 'webapp/utils/id', 'webapp/pods/pncp/constantes-pncp-envio'], function (exports, Ember, UtilID, Constantes) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({

    constantesOperacoes: Constantes['default'].operacoes,
    constantesUnidades: Constantes['default'].descricoesUnidades,

    comunicador: Ember['default'].inject.service(),

    mostrarModalAjusteSituacao: false,
    mostrarModalVinculacaoIdPNCP: false,
    mostrarModalConfirmacaoVinculacaoIdPNCP: false,
    mostrarModalPendenciasDeEnvioAoPNCP: false,
    entidadeEmAnalise: {}, //para uso interno do componente (de onde serão
    //buscados os dados a serem apresentados, etc.)

    tiposSituacao: [{ id: "ATIVA", nome: "Ativa", codigo: 1, cor: "verde" }, { id: "REVOGADA", codigo: 2, nome: "Revogada", cor: "vemelho" }, { id: "ANULADA", codigo: 3, nome: "Anulada", cor: "vemelho" }, { id: "SUSPENSA", codigo: 4, nome: "Suspensa", cor: "vemelho" }],
    situacaoAquisicaoTemp: "",
    dataSituacaoAquisicaoTemp: "",
    mensagemDescricaoSituacao: "",
    urlVinculacaoPNCP: "",

    silcModalidadeDeContratacao: "",
    silcAmparoLegal: "",
    silcModoDeDisputa: "",
    silcRegistroDePreco: "",
    silcProcesso: "",
    silcObjeto: "",

    local: "",
    orgao: "",
    unidadeCompradora: "",
    amparoLegal: "",
    modalidadeDeContratacao: "",
    modoDeDisputa: "",
    registroDePreco: "",
    processo: "",
    objeto: "",

    //----------------------------------- IDs -----------------------------------

    _contextoComponente: (function () {
      return UtilID['default'].getSeccao(this.id, 'contexto');
    }).property('id'),

    _localComponente: (function () {
      return UtilID['default'].getSeccao(this.id, 'local');
    }).property('id'),

    _idBotaoAbrirModal: (function () {
      return UtilID['default'].constroi('botao', 'pncp-abrir-modal-ajustes', 'situacao-aquisicao', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    _idCampoTipo: (function () {
      return UtilID['default'].constroi('select', 'pncp-tipo', 'situacao-aquisicao', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    _idCampoData: (function () {
      return UtilID['default'].constroi('date-input', 'pncp-data', 'situacao-aquisicao', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    _idBotaoSalvarAjsutes: (function () {
      return UtilID['default'].constroi('botao', 'pncp-salvar-ajustes', 'situacao-aquisicao', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    _idBotaoCancelar: (function () {
      return UtilID['default'].constroi('botao', 'pncp-cancelar-ajustes', 'situacao-aquisicao', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    _idBotaoConfirmarVinculacao: (function () {
      return UtilID['default'].constroi('botao', 'confirmar-vinculacao', 'situacao-aquisicao', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    _idBotaoCancelarConfirmacaoVinculacao: (function () {
      return UtilID['default'].constroi('botao', 'cancelar-confirmacao-vinculacao', 'situacao-aquisicao', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    _idBotaoConsultarAquisicaoPNCP: (function () {
      return UtilID['default'].constroi('botao', 'consultar-aquisicao', 'situacao-aquisicao', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    _idBotaoCancelarVinculacao: (function () {
      return UtilID['default'].constroi('botao', 'cancelar-vinculacao', 'situacao-aquisicao', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    _idUrlPNCP: (function () {
      return UtilID['default'].constroi('tag-input', 'pncp-urlPNCP', 'situacao-aquisicao', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    _idUnidadesDeEnvioPendentesDeEnvioAoPNCP: (function () {
      return UtilID['default'].constroi('tabela-form-leve', 'unidades-envio-pendentes-de-envio-ao-PNCP', 'situacao-aquisicao', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    // ------ ID's campos do PNCP 
    _idPNCPLocal: (function () {
      return UtilID['default'].constroi('tag-input', 'pncp-local', 'situacao-aquisicao', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    _idPNCPOrgao: (function () {
      return UtilID['default'].constroi('tag-input', 'pncp-orgao', 'situacao-aquisicao', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    _idPNCPUnidadeCompradora: (function () {
      return UtilID['default'].constroi('tag-input', 'pncp-unidadeCompradora', 'situacao-aquisicao', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    _idPNCPModalidadeDeContratacao: (function () {
      return UtilID['default'].constroi('tag-input', 'pncp-modalidadeDeContratacao', 'situacao-aquisicao', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    _idPNCPAmparoLegal: (function () {
      return UtilID['default'].constroi('tag-input', 'pncp-amparoLegal', 'situacao-aquisicao', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    _idPNCPProcesso: (function () {
      return UtilID['default'].constroi('tag-input', 'pncp-processo', 'situacao-aquisicao', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    _idPNCPObjeto: (function () {
      return UtilID['default'].constroi('tag-input', 'pncp-objeto', 'situacao-aquisicao', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    _idPNCPModoDeDisputa: (function () {
      return UtilID['default'].constroi('tag-input', 'pncp-modo-de-disputa', 'situacao-aquisicao', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    _idPNCPRegistroDePreco: (function () {
      return UtilID['default'].constroi('tag-input', 'pncp-registro-de-preco', 'situacao-aquisicao', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    // ------ ID's campos do SILC 
    _idSILCModalidadeDeContratacao: (function () {
      return UtilID['default'].constroi('tag-input', 'silc-modalidadeDeContratacao', 'situacao-aquisicao', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    _idSILCAmparoLegal: (function () {
      return UtilID['default'].constroi('tag-input', 'silc-amparoLegal', 'situacao-aquisicao', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    _idSILCModoDeDisputa: (function () {
      return UtilID['default'].constroi('tag-input', 'silc-modo-de-disputa', 'situacao-aquisicao', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    _idSILCRegistroDePreco: (function () {
      return UtilID['default'].constroi('tag-input', 'silc-registro-de-preco', 'situacao-aquisicao', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    _idSILCProcesso: (function () {
      return UtilID['default'].constroi('tag-input', 'silc-processo', 'situacao-aquisicao', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    _idSILCObjeto: (function () {
      return UtilID['default'].constroi('tag-input', 'silc-objeto', 'situacao-aquisicao', this.get('_contextoComponente'), this.get('_localComponente'));
    }).property('_contextoComponente', '_localComponente'),

    //---------------------------------------------------------------------------

    desabilitarBotoes: (function () {
      return this.desabilitado || this.somenteLeitura;
    }).property('desabilitado', 'somenteLeitura'),

    descricaoSituacaoAquisicao: (function () {
      var _this = this;

      this.set('mensagemDescricaoSituacao', this.tiposSituacao.find(function (tipo) {
        return tipo.id === _this.aquisicao.situacaoAquisicao;
      }) ? this.tiposSituacao.find(function (tipo) {
        return tipo.id === _this.aquisicao.situacaoAquisicao;
      }).nome : "Ativa");

      if (this.aquisicao.enviaPNCP) {
        if (this.aquisicao.pendenteEnvioAoPNCP) {
          this.set('mensagemDescricaoSituacao', this.get('mensagemDescricaoSituacao') + " (Pendente de Envio ao PNCP)");
        }

        if (!this.aquisicao.pendenteEnvioAoPNCP) {
          this.set('mensagemDescricaoSituacao', this.get('mensagemDescricaoSituacao') + " (Divulgada no PNCP)");
        }
      } else {
        if (this.aquisicao.aquisicaoDivulgadaPorOutroSistema) {
          this.set('mensagemDescricaoSituacao', this.get('mensagemDescricaoSituacao') + " (Divulgada por outro sistema/serviço)");
        }
      }
      return this.get('mensagemDescricaoSituacao');
    }).property('aquisicao.situacaoAquisicao'),

    // VERIFICAR CONDIÇÕES PARA MOSTRAR BOTÃO AJUSTAR
    mostrarBotaoAjustar: (function () {
      if (this.aquisicao.situacaoAquisicao === 'ANULADA' || this.aquisicao.situacaoAquisicao === 'REVOGADA') {
        return false;
      }

      if (this.aquisicao.enviaPNCP) {
        if (!this.get('aquisicaoEnviadaAoPNCP')) {
          return false;
        }
      } else {
        return false;
      }

      return true;
    }).property('aquisicao.situacaoAquisicao', 'this.aquisicao.enviaPNCP'),

    aquisicaoEnviadaAoPNCP: (function () {
      return this.aquisicao.pncpano || this.aquisicao.pncpseq || this.aquisicao.urlPNCP;
    }).property('aquisicao.pncpano', 'aquisicao.pncpseq', 'aquisicao.urlPNCP'),

    corPainel: (function () {
      var _this2 = this;

      return this.tiposSituacao.find(function (tipo) {
        return tipo.id === _this2.aquisicao.situacaoAquisicao;
      }) ? this.tiposSituacao.find(function (tipo) {
        return tipo.id === _this2.aquisicao.situacaoAquisicao;
      }).cor : "verde";
    }).property('aquisicao.situacaoAquisicao'),

    mostrarLinkPNCP: (function () {
      return this.aquisicao.urlPNCP;
    }).property('aquisicao.urlPNCP'),

    mostrarBotaoEnviarAgora: (function () {
      return this.get('aquisicao').pncpenvioDTO && this.get('aquisicao').enviaPNCP;
    }).property('aquisicao.enviaPNCP', 'aquisicao.pncpenvioDTO'),

    mostrarBotaoVincularIdPNCP: (function () {
      return !this.aquisicao.enviaPNCP && !this.aquisicao.urlPNCP;
    }).property('aquisicao.enviaPNCP', 'aquisicao.urlPNCP'),

    buscarSituacaoAquisicao: function buscarSituacaoAquisicao() {
      this.get('comunicador').leParaPropriedade(this, 'situacaoContratacao/' + this.aquisicao.id, 'situacaoAquisicao', "Não foi possível obter a situação da aquisição.");
    },

    getDescricaoDaOperacao: function getDescricaoDaOperacao(envioPNCP) {
      // Tenta converter o tipo para uma das opções nas constantes
      var operacao = this.constantesOperacoes.find(function (operacao) {
        return operacao.id === envioPNCP.descricaoDaOperacao;
      });
      if (operacao) {
        return operacao.nome;
      }
      return envioPNCP.descricaoDaOperacao;
    },

    getDescricaoDaUnidade: function getDescricaoDaUnidade(envioPNCP) {
      // Tenta converter o tipo para uma das opções nas constantes
      var unidadeDeEnvio = this.constantesUnidades.find(function (unidade) {
        return unidade.id === envioPNCP.idUnidadeDeEnvio;
      });
      if (unidadeDeEnvio) {
        return unidadeDeEnvio.nome;
      }
      return envioPNCP.descricaoDaUnidadeDeEnvio;
    },

    actions: {

      abrirModalAjustes: function abrirModalAjustes() {
        this.buscarSituacaoAquisicao();
        this.set('situacaoAquisicaoTemp', this.aquisicao.situacaoAquisicao);
        this.set('dataSituacaoAquisicaoTemp', this.aquisicao.dataSituacaoAquisicao);
        this.set('mostrarModalAjusteSituacao', true);
      },

      abrirModalVinculacaoIdPNCP: function abrirModalVinculacaoIdPNCP() {
        this.set('urlVinculacaoPNCP', "");
        this.set('mostrarModalVinculacaoIdPNCP', true);
      },

      abrirModalPendenciasDeEnvioAoPNCP: function abrirModalPendenciasDeEnvioAoPNCP() {
        this.set('mostrarModalPendenciasDeEnvioAoPNCP', true);
      },

      atualizarSituacao: function atualizarSituacao() {
        var _this3 = this;

        //CHARAM O SERVIÇO PARA SALVAR ...
        //PEDIR PARA ATUALIZAR A AQUISIÇÃO...
        this.situacaoAquisicao.novaSituacaoContratacao = this.situacaoAquisicaoTemp;
        this.situacaoAquisicao.novaDataSituacaoAquisicao = this.dataSituacaoAquisicaoTemp;
        this.get('comunicador').servico('put', "aquisicao/" + this.aquisicao.id + "/alteraSituacaoAquisicao", this.situacaoAquisicao).then(function () {
          _this3.set('mostrarModalAjusteSituacao', false);
          _this3.set('situacaoAquisicaoTemp', "");
          _this3.set('dataSituacaoAquisicaoTemp', "");
          window.location.reload();
        });
      },

      cancelarAtualizacaoSituacao: function cancelarAtualizacaoSituacao() {
        this.set('mostrarModalAjusteSituacao', false);
        this.set('situacaoAquisicaoTemp', "");
        this.set('dataSituacaoAquisicaoTemp', "");
      },

      cancelar: function cancelar() {
        this.set('mostrarModalVinculacaoIdPNCP', true);
        this.set('mostrarModalConfirmacaoVinculacaoIdPNCP', false);
      },

      cancelarVinculoIdPNCP: function cancelarVinculoIdPNCP() {
        this.set('mostrarModalVinculacaoIdPNCP', false);
        this.set('mostrarModalConfirmacaoVinculacaoIdPNCP', false);
        this.set('urlVinculacaoPNCP', "");
      },

      enviarAgora: function enviarAgora() {
        this.get('comunicador').servico('post', 'aquisicao/' + this.aquisicao.id + '/enviaPNCP', null).then(function () {
          window.location.reload();
        });
      },

      consultarAquisicaoPNCP: function consultarAquisicaoPNCP() {
        var _this4 = this;

        var objDadosEnvio = {
          cnpj: this.aquisicao.cnpj,
          seq: this.aquisicao.seq,
          ano: this.aquisicao.ano,
          urlPNCP: this.urlVinculacaoPNCP
        };

        this.silcModalidadeDeContratacao = this.aquisicao.modalidade;
        if (this.aquisicao.fundLegalPrincipal.pncpAmparoLegal) {
          this.silcAmparoLegal = this.aquisicao.fundLegalPrincipal.pncpAmparoLegal.descricao;
        } else {
          var amparosLegais = [];
          this.aquisicao.fundamentosLegais.find(function (fl) {
            if (fl.pncpAmparoLegal != null) {
              amparosLegais.push(fl.pncpAmparoLegal.descricao);
            }
          });
          if (amparosLegais.length > 0) {
            this.silcAmparoLegal = amparosLegais.join(', ');
          } else {
            this.silcAmparoLegal = "";
          }
        }
        this.silcModoDeDisputa = this.aquisicao.modoDisputa;
        this.silcRegistroDePreco = this.aquisicao.registroPreco ? 'Sim' : 'Não';
        this.silcProcesso = this.aquisicao.numeroProcessoAdministrativo;
        this.silcObjeto = this.aquisicao.objeto;

        this.get('comunicador').servico('put', 'aquisicao/' + this.aquisicao.id + '/consultarAquisicaoPNCP', objDadosEnvio).then(function (resultado) {
          _this4.local = resultado.local;
          _this4.orgao = resultado.orgao;
          _this4.unidadeCompradora = resultado.unidadeCompradora;
          _this4.amparoLegal = resultado.amparoLegal;
          _this4.tipo = resultado.tipo;
          _this4.modalidadeDeContratacao = resultado.modalidadeDeContratacao;
          _this4.processo = resultado.processo;
          _this4.objeto = resultado.objeto;
          _this4.modoDeDisputa = resultado.modoDeDisputa;
          _this4.registroDePreco = resultado.registroDePreco ? 'Sim' : 'Não';

          _this4.set('mostrarModalVinculacaoIdPNCP', false);
          _this4.set('mostrarModalConfirmacaoVinculacaoIdPNCP', true);
        });
      },

      confirmarVinculacaoIdPNCP: function confirmarVinculacaoIdPNCP() {
        var _this5 = this;

        var objDadosEnvio = {
          cnpj: this.aquisicao.cnpj,
          seq: this.aquisicao.seq,
          ano: this.aquisicao.ano,
          urlPNCP: this.urlVinculacaoPNCP
        };
        this.get('comunicador').servico('put', 'aquisicao/' + this.aquisicao.id + '/vincularIdPNCP', objDadosEnvio).then(function () {
          _this5.set('urlVinculacaoPNCP', "");
          _this5.set('mostrarModalVinculacaoIdPNCP', false);
          _this5.set('mostrarModalConfirmacaoVinculacaoIdPNCP', false);
          window.location.reload();
        });
      }

    }

  });

});