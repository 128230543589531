define('webapp/pods/pncp/listagem/route', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    setupController: function setupController(controller) {
      this.get('comunicador').leParaPropriedade(controller, 'pncp/unidadesDeEnvioAoPNCP', 'todasAsUnidadesDeEnvioAoPNCP', 'Não foi possível obter a relação de unidades de envio ao PNCP');
      controller._gerenciarDadosPesquisaAnterior();
    }

  });

});