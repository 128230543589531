define('webapp/pods/pncp/listagem/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin', 'webapp/defs', 'webapp/utils/util', 'webapp/pods/pncp/listagem/constantes-envio-pncp'], function (exports, Ember, CrudMixin, Defs, Util, Constantes) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {
    queryParams: ['manterDadosPesquisaAnterior'],
    manterDadosPesquisaAnterior: false,

    todasAsUnidadesDeEnvioAoPNCP: [],

    breadCrumbConfig: {
      nomeApresentacao: 'Pendentes de Envio ao PNCP'
    },

    _gerenciarDadosPesquisaAnterior: function _gerenciarDadosPesquisaAnterior() {
      if (!this.manterDadosPesquisaAnterior) {
        this.limparDadosPesquisa();
      } else {
        this.send('pesquisar');
      }

      this.set('manterDadosPesquisaAnterior', false);
    },

    unidadesPrincipaisDeEnvioAoPNCP: (function () {
      return this.get('todasAsUnidadesDeEnvioAoPNCP').filter(function (unidade) {
        return unidade.nomeUnidadeDeEnvioAoPNCP === unidade.nomeUnidadeDeEnvioAoPNCPPrincipal;
      });
    }).property('todasAsUnidadesDeEnvioAoPNCP'),

    limparDadosPesquisa: function limparDadosPesquisa() {
      this.limparCamposPesquisa();
      this.limparTabelaResultados();
    },

    limparCamposPesquisa: function limparCamposPesquisa() {
      this.set('unidadeDeEnvioAoPNCPId', undefined);
    },

    ajustaDropdownDeModuloParaValorDefault: (function () {
      if (this.get('unidadeDeEnvioAoPNCPId') == undefined) if (this.get('todasAsUnidadesDeEnvioAoPNCP') && this.get('todasAsUnidadesDeEnvioAoPNCP').length > 0) this.set('unidadeDeEnvioAoPNCPId', this.get('unidadesPrincipaisDeEnvioAoPNCP')[0].id);
    }).observes('unidadeDeEnvioAoPNCPId', 'todasAsUnidadesDeEnvioAoPNCP'),

    limparTabelaResultados: function limparTabelaResultados() {
      //limpa tabela de resultados
      this.set('mostrarResultados', false);
      this.set('mostraTabelaResultadoPesquisa', false);
      this.set('mostraMensagemPesquisaSemResultado', false);
    },

    getNumeroApresentacaoDaAquisicao: function getNumeroApresentacaoDaAquisicao(registro) {
      return registro.numeroApresentacaoEntidadePrincipal; // + "->" + registro.entidadePrincipalNoSILCId + " / " + registro.entidadeSecundariaNoSILCId;
    },

    actions: {
      pesquisar: function pesquisar() {
        var _this = this;

        this.set('mostrarResultados', false);

        var criteriosPesquisa = this.getProperties('unidadeDeEnvioAoPNCPId');

        this.get('comunicador').servico('post', 'pncp/pesquisa', criteriosPesquisa, this).then(function (resultado) {
          _this.set('model', resultado);
          var retornouItems = _this.get('model').length > 0;
          _this.set('mostraTabelaResultadoPesquisa', retornouItems);
          _this.set('mostraMensagemPesquisaSemResultado', !retornouItems);
          _this.set('mostrarResultados', true);
        });
      },

      limparPesquisa: function limparPesquisa() {
        this.limparDadosPesquisa();
        this.set('model', []);
      },

      enviarAgora: function enviarAgora(enviarAoPNCP) {
        this.get('comunicador').servico('post', enviarAoPNCP.linkEnvioAoPNCP, null).then(function () {
          window.location.reload();
        });
      }
    },

    getLink: function getLink(registroDeEnvio) {
      if (registroDeEnvio.linkRotaEntidadePrincipalNoSILC == null || registroDeEnvio.linkRotaEntidadePrincipalNoSILC == "") {
        return "";
      }
      return (Defs['default'].enderecoCliente + ('#/' + registroDeEnvio.linkRotaEntidadePrincipalNoSILC)).toLowerCase();
    }

  });

});