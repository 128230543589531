define('webapp/pods/aquisicao/ata/documento/controller', ['exports', 'ember', 'webapp/defs', 'webapp/utils/util', 'webapp/pods/transparencia/documento/constantes-documento', 'webapp/mixins/crud-mixin'], function (exports, Ember, Defs, Util, ConstantesDocumento, CrudMixin) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {

    needs: ['aquisicao', 'aquisicao/ata'],

    breadCrumbConfig: {
      fnNomeApresentacao: 'fnNomeApresentacao'
    },

    opcoesItemCompra: [],
    tipos: ConstantesDocumento['default'].tipos,
    tiposPNCP: [],

    fnNomeApresentacao: function fnNomeApresentacao() {
      return 'Documento ' + (this.model.version ? this.model.numeroApresentacao : '(Novo)');
    },

    crudConfig: {
      criarComUpload: {
        preparaUploadFn: function preparaUploadFn() {
          return this.criarDoc();
        },
        okFn: function okFn(opcao) {
          this.get('controllers.aquisicao/ata').send('ler');
          if (opcao === 'CRIAR_E_PROXIMO') {
            this.set('model', []);
            this.get('controllers.aquisicao/ata').send('ler');
            this.send('refreshRota'); //ação "borbulhará" para a rota
            return;
          }
          this.get('controllers.aquisicao/ata').send('ler');
          this.transitionToRoute('aquisicao/ata');
        }
      },
      excluir: {
        urlFn: function urlFn(id) {
          return 'transparencia/documento/aquisicao/' + id;
        },
        okFn: function okFn() {
          this.get('controllers.aquisicao/ata').send('ler');
        }
      }
    },

    criarDoc: function criarDoc() {
      var formData = new FormData();
      formData.append("idAquisicao", this.get("controllers.aquisicao").model.id);
      formData.append("idAta", this.get("controllers.aquisicao/ata").model.id);
      formData.append("modulo", "AQUISICAO");
      if (this.model.tipo == null) {
        formData.append("tipo", "EDITAL");
      } else {
        formData.append("tipo", this.model.tipo);
      }
      if (this.model.tipoPNCP) {
        formData.append("tipoPNCP", this.model.tipoPNCP);
      }
      formData.append("ordem", "0");
      if (this.model.descricao) {
        formData.append("descricao", this.model.descricao);
      }
      var uploadInput = Ember['default'].$(".row-input-upload input[type=file]").get(0);

      if (uploadInput.files[0]) {
        if (!Util['default'].verificarTipoArquivoValido(uploadInput.files[0].name)) {
          Util['default'].alertaErro('Formato inválido para o arquivo.');
          return;
        }

        if (!Util['default'].verificarTamanhoArquivoValido(uploadInput.files[0].size)) {
          Util['default'].alertaErro('Arquivo com tamanho superior a 30MB.');
          return;
        }

        formData.append('documento', uploadInput.files[0]);
      }

      return formData;
    },

    carregaTiposDeDocumento: (function () {
      var _this = this;

      this.get('comunicador').servico('GET', 'tiposDeDocumentoPNCP/' + this.get('controllers.aquisicao/ata').model.id + '?tipoEntidade=ata').then(function (resultado) {
        _this.set('tiposPNCP', resultado);
      });
    }).observes('model.ata'),

    tipoDocumentoDoPNCP: (function () {
      return this.get("controllers.aquisicao/ata").model.enviaPNCP;
    }).property('model.ata'),

    getDescricaoTipoDocumento: function getDescricaoTipoDocumento(documento) {
      if (!!documento.tipoPNCPDescricao) {
        return documento.tipoPNCPDescricao;
      }

      var tipo = this.tipos.find(function (t) {
        return t.id === documento.tipo;
      });
      if (tipo) {
        return tipo.nome;
      }

      return documento.tipo;
    },

    getLink: function getLink(documento) {
      return Defs['default'].enderecoServer + documento.urlDownload;
    },

    getTextoAcessivel: function getTextoAcessivel(textoAcessivelDefaultDoComponente) {
      return textoAcessivelDefaultDoComponente + ", download";
    }

  });

});