define('webapp/app', ['exports', 'ember', 'ember/resolver', 'ember/load-initializers', 'webapp/config/environment', 'webapp/utils/util'], function (exports, Ember, Resolver, loadInitializers, config, Util) {

  'use strict';

  var App;

  Ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = Ember['default'].Application.extend({
    modulePrefix: config['default'].modulePrefix,
    podModulePrefix: config['default'].podModulePrefix,
    Resolver: Resolver['default']
  });

  loadInitializers['default'](App, config['default'].modulePrefix);

  // App.initializer({
  //   name: 'check-auth',
  //   initialize: (container, application) => {
  //     var comunicador = container.lookup('service:comunicador');
  //     var usuarioLogado = container.lookup('service:usuario-logado');
  //     var router = container.lookup('router:main');

  //     comunicador.servico(
  //       'get',
  //       'auth/verificaSeUsuarioEstaLogado'
  //     ).then(
  //         conteudo =>  {
  //           var logado = conteudo;
  //           Ember.Logger.error('AQUI1');
  //           if (logado != "TRUE") {
  //             if (usuarioLogado.estaLogado()) {  // Se está sinalizado como logado no front-end, está errado, então ...
  //               usuarioLogado.sair();           // ... sinaliza que não está mais logado
  //             }
  //             Ember.run.next(  // Redireciona assim que possível
  //               function() {
  //                 router.transitionTo('login');
  //               }
  //             );
  //           }
  //         },
  //         function(resultado) {
  //           Util.alertaErro("Não foi possível acessar o back-end: ", resultado.responseText);
  //         }
  //     );

  //   }

  // });

  exports['default'] = App;

});