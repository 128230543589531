define('webapp/pods/pncp/route', ['exports', 'ember', 'webapp/utils/util'], function (exports, Ember, Util) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    setupController: function setupController(controller, model) {
      var model;
      var modelRotaSuperior = controller.getModelRotaSuperior();
      if (modelRotaSuperior != null) {
        model = modelRotaSuperior.filter(function (obj) {
          return obj.idEntidadePrincipal == model.id;
        })[0];
        controller.set('model', model.unidadesDeEnvioESuasOperacoes);
        controller.set('idEntidadePrincipal', model.idEntidadePrincipal);
        controller.set('nomeDaEntidadePrincipal', model.nomeDaEntidadePrincipal);
        controller.set('desabilitaEnvio', false);
        controller.textoParaBreadCrumb = 'Pendentes de Envio ao PNCP (' + model.descricaoDaEntidadePrincipal + ' - ' + model.numeroApresentacaoEntidadePrincipal + ')';
      }
    },

    actions: {
      retorna: function retorna(manterDadosPesquisaAnterior) {
        this.transitionTo('pncp/listagem', { queryParams: { manterDadosPesquisaAnterior: 'true' } });
      }
    }

  });

});