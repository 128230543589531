define('webapp/pods/aquisicao/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin', 'webapp/pods/aquisicao/constantes-aquisicao', 'npm:big.js', 'webapp/defs', 'webapp/utils/util'], function (exports, Ember, CrudMixin, Constantes, Big, Defs, Util) {

  'use strict';

  /* global _ */

  exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {

    ehAdesao: false,
    ehLicitacaoPregao: false,
    ehOutraLicitacao: false,
    permiteRegistroDePrecos: false,
    fundamentosLegais: {},
    modosDeDisputaPossiveis: [],
    criteriosDeJulgamentoPossiveis: [],
    arrayDeModosDeDisputaPorModalidade: [],
    arrayDeCriteriosDeJulgamentoPorModalidade: [],
    ehAquisicaoNova: false,

    needs: ['aquisicao/listagem'],

    breadCrumbConfig: {
      fnNomeApresentacao: 'fnNomeApresentacao'
    },

    selecionaAbaIdentificacao: function selecionaAbaIdentificacao() {
      document.getElementById("tab-label||identificacao|aquisicao|apresentacao").click();
    },

    observaTiposFiscalGestor: (function () {
      var self = this;
      self.set('tiposFiscal', []);
      self.set('tiposGestor', []);
      this.get('tiposFiscalGestor').forEach(function (fiscalGestor) {
        if (fiscalGestor.tipo === 'F') {
          self.get('tiposFiscal').push(fiscalGestor);
        } else {
          self.get('tiposGestor').push(fiscalGestor);
        }
      });
    }).observes('tiposFiscalGestor'),

    fnNomeApresentacao: function fnNomeApresentacao() {
      var modalidadeAquisicao = _.findWhere(this.modalidades, { id: this.model.modalidade });
      var trechoModalidadeAquisicao = modalidadeAquisicao ? ' - ' + modalidadeAquisicao.nome : '';
      return 'Aquisição ' + (this.model.version ? this.model.numeroApresentacao : '(Nova)') + ' ' + trechoModalidadeAquisicao;
    },

    naoEnviarAoPNCPPorCausaDaModalidade: function naoEnviarAoPNCPPorCausaDaModalidade() {
      var x = ["ADESAO", "PARTICIPACAO_REGISTRO_PRECOS", "SUPRIMENTO_FUNDOS", "OUTROS"].indexOf(this.model.modalidade);
      return x > -1;
    },

    crudConfig: {
      criar: {
        okFn: function okFn(opcao) {
          if (opcao === 'CRIAR_E_PROXIMO') {
            //ação "borbulhará" para a rota
            this.send('refreshRota');
            return;
          }
          this.transitionToRoute('aquisicao', this.model.id);
        }
      },
      atualizar: {
        okFn: function okFn() {
          //Ao atualizar/ler uma aquisição, resetam-se o fiscal e o gestor,
          //provocando a sugestão de seus emails, quando deveria ser respeitado
          //o email que veio gravado do servidor.
          //Assim, aqui desfazem-se essas sugestões de email inapropriadas
          this.set('model.emailGestor', this.backupEmailGestor);
          this.set('model.emailFiscal', this.backupEmailFiscal);
        }
      },
      ler: {
        okFn: function okFn() {
          //Ao atualizar/ler uma aquisição, resetam-se o fiscal e o gestor,
          //provocando a sugestão de seus emails, quando deveria ser respeitado
          //o email que veio gravado do servidor.
          //Assim, aqui desfazem-se essas sugestões de email inapropriadas
          this.set('model.emailGestor', this.backupEmailGestor);
          this.set('model.emailFiscal', this.backupEmailFiscal);
        }
      },
      excluir: {
        okFn: function okFn() {
          this.get('controllers.aquisicao/listagem').send('pesquisar');
        }
      }
    },

    modalidades: Constantes['default'].modalidades,

    //-------------------------- PROPRIEDADES COMPUTADAS ---------------------------

    tituloTela: (function () {
      var modalidadeAquisicao = _.findWhere(this.modalidades, { id: this.model.modalidade });
      var trechoModalidadeAquisicao = modalidadeAquisicao ? ' - ' + modalidadeAquisicao.nome : '';
      return 'Aquisição' + trechoModalidadeAquisicao;
    }).property('model.modalidade'),

    ehAquisicaoExistente: (function () {
      return this.model.version;
    }).property('model.version'),

    obsSetEhAquisicaoNova: (function () {
      this.set('ehAquisicaoNova', !this.get('model').version);
    }).observes('model.version'),

    ehLicitacao: (function () {
      return this.ehQualquerLicitacao();
    }).property('ehLicitacaoPregao', 'ehOutraLicitacao'),

    proibidoAlterarOpcaoRegistroPrecos: (function () {
      return this.model.atas.length > 0 || this.model.compras.length > 0;
    }).property('model.version', 'ehParticipacao'),

    //Usado externamente (para as listagens de ata e compra)
    haLoteAdjudicado: (function () {
      return this.model.lotes.some(function (lote) {
        return lote.adjudicado && lote.adjudicado.id;
      });
    }).property('model.lotes.@each'),

    desabilitaEnvioPNCP: (function () {
      return this.get('ehAquisicaoExistente') || this.naoEnviarAoPNCPPorCausaDaModalidade();
    }).property('model.version', 'model.modalidade'),

    temModoDisputa: (function () {
      return this.get('modosDeDisputaPossiveis');
    }).property('modosDeDisputaPossiveis'),

    temCriterioJulgamento: (function () {
      return this.get('criteriosDeJulgamentoPossiveis');
    }).property('criteriosDeJulgamentoPossiveis'),

    modalidadesAtivas: (function () {
      if (!this.modalidades.filter) {
        return [];
      }

      return this.modalidades.filter(function (modalidade) {
        return modalidade.ativo;
      });
    }).property('modalidades'),

    descricaoModalidade: (function () {
      var _this = this;

      if (this.get('ehAquisicaoExistente')) return this.get('modalidades').find(function (modalidade) {
        return modalidade.id == _this.model.modalidade;
      }).nome;
    }).property('model.modalidade'),

    permiteJustificativaModalidadePresencial: (function () {
      var _this2 = this;

      if (this.model.modalidade != null && this.model.modalidade != "") {
        return this.get('modalidades').find(function (modalidade) {
          return modalidade.id == _this2.model.modalidade;
        }).presencial;
      };
      return false;
    }).property('model.modalidade'),

    //------------------------------------------------------------------------------

    ehQualquerLicitacao: function ehQualquerLicitacao() {
      return this.ehLicitacaoPregao || this.ehOutraLicitacao;
    },

    //-------------------------AJUSTES FUNDAMENTOS LEGAIS --------------------------

    fundamentosLegaisPossiveis: function fundamentosLegaisPossiveis() {
      if (!this.model.modalidade || !this.arrayDeFundamentosLegaisPorModalidade) {
        return null;
      }
      var fundamentosPossiveis = this.arrayDeFundamentosLegaisPorModalidade[this.model.modalidade];
      if (this.model.modalidade === 'LICITACAO_PREGAO_ELETRONICO' || this.model.modalidade === 'LICITACAO_PREGAO_PRESENCIAL' || this.model.modalidade === 'LICITACAO_CONCORRENCIA_ELETRONICA' || this.model.modalidade === 'LICITACAO_CONCORRENCIA') {
        fundamentosPossiveis.concat(this.arrayDeFundamentosLegaisPorModalidade['REGISTRO_PRECOS']);
      }
      return fundamentosPossiveis;
    },

    pptyFundamentosLegaisPossiveis: (function () {
      return this.fundamentosLegaisPossiveis();
    }).property('model.modalidade', 'model.registroPreco'),

    notFundamentosLegaisPossiveis: (function () {
      if (this.fundamentosLegaisPossiveis() == null) return true;else return false;
    }).property('model.modalidade', 'model.registroPreco'),

    pptyModosDeDisputaPossiveis: (function () {
      if (!this.model.modalidade || !this.get('modosDeDisputaPossiveis')) {
        return;
      }
      return this.get('modosDeDisputaPossiveis');
    }).property('modosDeDisputaPossiveis'),

    pptyCriteriosDeJulgamentoPossiveis: (function () {
      if (!this.model.modalidade || !this.get('criteriosDeJulgamentoPossiveis')) {
        return;
      }
      return this.get('criteriosDeJulgamentoPossiveis');
    }).property('criteriosDeJulgamentoPossiveis'),

    atribuiModosDeDisputa: (function () {
      this.set('modosDeDisputaPossiveis', this.arrayDeModosDeDisputaPorModalidade[this.model.modalidade]);
    }).observes('model.modalidade', 'arrayDeModosDeDisputaPorModalidade'),

    atribuiCriteriosDeJulgamento: (function () {
      this.set('criteriosDeJulgamentoPossiveis', this.arrayDeCriteriosDeJulgamentoPorModalidade[this.model.modalidade]);
    }).observes('model.modalidade', 'arrayDeCriteriosDeJulgamentoPorModalidade'),

    limpaFundamentos: (function () {
      if (this.get('ehAquisicaoExistente')) {
        return;
      }
      this.model.fundamentosLegais.length = 0; //esvaziando o array
    }).observes('model.modalidade'),

    atualizarFundamentosRegistro: (function () {
      if (!this.arrayDeFundamentosLegaisPorModalidade) {
        return;
      }

      var fundamentosRegistro = this.arrayDeFundamentosLegaisPorModalidade['REGISTRO_PRECOS'];
      if (this.model.registroPreco) {
        this._adicionarFundamentosLegais(fundamentosRegistro);
      } else {
        this._retirarFundamentosLegais(fundamentosRegistro);
      }
      //É preciso substituir a referência ao array para que o select2 múltiplo seja atualizado
      this.set('model.fundamentosLegais', this.model.fundamentosLegais.slice());
    }).observes('model.registroPreco', 'arrayDeFundamentosLegaisPorModalidade'),

    _adicionarFundamentosLegais: function _adicionarFundamentosLegais(fundamentos) {
      var _this3 = this;

      if (!fundamentos) {
        return;
      }
      fundamentos.forEach(function (fundamento) {
        _this3._adicionarFundamentoLegal(fundamento);
      });
    },

    _adicionarFundamentoLegal: function _adicionarFundamentoLegal(fundamento) {
      if (this._fundamentoEstaIncluido(fundamento)) {
        return;
      }
      this.model.fundamentosLegais.pushObject(fundamento);
    },

    // Retira os fundamentos legais que não são compatíveis com a modalidade
    // selecionada e com a opção de ter ou não registro de preços
    //TODO REFATORAR
    _retirarFundamentosLegais: function _retirarFundamentosLegais(fundamentosRetirar) {
      var fundamentosRemanescentes = this.model.fundamentosLegais.filter(function (fundamentoAtual) {
        return fundamentosRetirar.find(function (fundamentoRetirar) {
          return fundamentoRetirar.id !== fundamentoAtual.id;
        });
      });
      this.set('model.fundamentosLegais', fundamentosRemanescentes);
    },

    //Verifica se o fundamento está entre os selecionados para a aquisição
    _fundamentoEstaIncluido: function _fundamentoEstaIncluido(fundamento) {
      var posicaoFundamento = this._posicaoFundamentoNaLista(fundamento);
      return posicaoFundamento > -1;
    },

    _posicaoFundamentoNaLista: function _posicaoFundamentoNaLista(fundamento) {
      return this.model.fundamentosLegais.findIndex(function (fundamentoNaLista) {
        return fundamentoNaLista.id === fundamento.id;
      });
    },

    /*  Comentado pois o campo model.registroPreco passou a depender apenas do valor que o usuário colocar nele a menos que a modalidade seja "Participação em Registro de Preço"
      //TODO REFATORAR
      //Desmarca a checkbox 'registroPreco' se não houver ao menos um de seus fundamentos legais
      desmarcaRegistroPrecoSeRetiradoFundamento: function() {
        if(this.get('ehAquisicaoExistente')) { return; }
        if(!this.arrayDeFundamentosLegaisPorModalidade) { return; }
    
        var temFundamentoRegistroPreco = false;
        this.arrayDeFundamentosLegaisPorModalidade['REGISTRO_PRECOS'].forEach(
          fundamentoRegistroPrecos => {
            temFundamentoRegistroPreco =
              temFundamentoRegistroPreco || this._fundamentoEstaIncluido(fundamentoRegistroPrecos);
          }
        );
    
        this.set('model.registroPreco', temFundamentoRegistroPreco);
      }.observes('model.fundamentosLegais.@each', 'arrayDeFundamentosLegaisPorModalidade'),
    */

    //--------------------------------------------------------------------------------------------

    //TODO REFATORAR
    configuraConformeModalidadeEscolhida: (function () {
      this.set('ehAdesao', false);
      this.set('ehParticipacao', false);
      this.set('ehLicitacaoPregao', false);
      this.set('ehOutraLicitacao', false);
      this.set('permiteRegistroDePrecos', false);

      if (this.get('ehAquisicaoNova')) {
        this.set('model.modoDisputa', null);
        if (this.get('modosDeDisputaPossiveis')) {
          if (this.get('modosDeDisputaPossiveis').length == 1) {
            this.set('model.modoDisputa', this.get('modosDeDisputaPossiveis')[0].valor);
          }
        }
        this.set('model.criterioJulgamento', null);
        if (this.get('criteriosDeJulgamentoPossiveis')) {
          if (this.get('criteriosDeJulgamentoPossiveis').length == 1) {
            this.set('model.criterioJulgamento', this.get('criteriosDeJulgamentoPossiveis')[0].valor);
          }
        }
        if (this.naoEnviarAoPNCPPorCausaDaModalidade()) {
          this.set("model.enviaPNCP", false);
        } else {
          this.set("model.enviaPNCP", null);
        }
      }

      switch (this.model.modalidade) {
        case 'ADESAO':
          this.set('ehAdesao', true);
          this.set('model.registroPreco', false);
          break;
        case 'PARTICIPACAO_REGISTRO_PRECOS':
          this.set('ehParticipacao', true);
          this.set('permiteRegistroDePrecos', false);
          this.set('model.registroPreco', true);
          break;
        case 'INEXIGIBILIDADE':
          this.set('model.registroPreco', false);
          break;
        case 'DISPENSA':
          this.set('model.registroPreco', false);
          break;
        case 'SUPRIMENTO_FUNDOS':
          this.set('model.registroPreco', false);
          break;
        case 'OUTROS':
          this.set('model.registroPreco', false);
          break;
        case 'LICITACAO_PREGAO_ELETRONICO':
          this.set('ehLicitacaoPregao', true);
          this.set('permiteRegistroDePrecos', true);
          if (!this.get('ehAquisicaoExistente')) {
            this.set('model.registroPreco', null);
          }
          break;
        case 'LICITACAO_PREGAO_PRESENCIAL':
          this.set('ehLicitacaoPregao', true);
          this.set('permiteRegistroDePrecos', true);
          if (!this.get('ehAquisicaoExistente')) {
            this.set('model.registroPreco', null);
          }
          break;
        case 'LICITACAO_CONVITE':
          this.set('ehOutraLicitacao', true);
          this.set('model.registroPreco', false);
          break;
        case 'LICITACAO_TOMADA_PRECOS':
          this.set('ehOutraLicitacao', true);
          this.set('model.registroPreco', false);
          break;
        case 'LICITACAO_CONCORRENCIA':
          this.set('ehOutraLicitacao', true);
          this.set('permiteRegistroDePrecos', true);
          if (!this.get('ehAquisicaoExistente')) {
            this.set('model.registroPreco', null);
          }
          break;
        case 'LICITACAO_CONCORRENCIA_ELETRONICA':
          this.set('ehOutraLicitacao', true);
          this.set('permiteRegistroDePrecos', true);
          if (!this.get('ehAquisicaoExistente')) {
            this.set('model.registroPreco', null);
          }
          break;
      }

      /*  Comentado pois o campo model.registroPreco passou a depender apenas do valor que o usuário colocar nele a menos que a modalidade seja "Participação em Registro de Preço"
          if(!this.get('permiteRegistroDePrecos')) {
            this.set('model.registroPreco', false);
          }
      
          // TODO REFATORAR
          if(this.get('ehAquisicaoNova') && this.model.modalidade !== 'PARTICIPACAO_REGISTRO_PRECOS') {
            this.set('model.registroPreco', false);
          }
      */
    }).observes('model.modalidade', 'fundamentosLegais'),

    limpaTipoLicitacao: (function () {
      if (this.model.modalidade !== 'LICITACAO_CONVITE' && this.model.modalidade !== 'LICITACAO_TOMADA_PRECOS' && this.model.modalidade !== 'LICITACAO_CONCORRENCIA') {
        this.set('model.tipoLicitacao', undefined);
      }
    }).observes('model.modalidade'),

    sugerirTextoObjeto: (function () {
      if (this.get('ehAquisicaoExistente')) {
        return;
      }
      this.set('model.objeto', this._textoConcatenandoAtributoRequisicoes('objeto'));
    }).observes('model', 'model.requisicoes.@each', 'ehAquisicaoExistente'),

    sugerirNumeroProcessoAdministrativo: (function () {
      if (this.get('ehAquisicaoExistente')) {
        return;
      }
      if (!this.get('model.requisicoes') || this.get('model.requisicoes').length < 1) {
        this.set('model.numeroProcessoAdministrativo', '');
      } else {
        this.set('model.numeroProcessoAdministrativo', this.get('model.requisicoes')[0].numeroProcessoAdministrativo);
      }
    }).observes('model', 'model.requisicoes.@each', 'ehAquisicaoExistente'),

    sugerirTextoObservacoes: (function () {
      if (this.get('ehAquisicaoExistente')) {
        return;
      }
      this.set('model.observacoes', this._textoConcatenandoAtributoRequisicoes('observacao'));
    }).observes('model', 'model.requisicoes.@each', 'ehAquisicaoExistente'),

    _textoConcatenandoAtributoRequisicoes: function _textoConcatenandoAtributoRequisicoes(atributoAConcatenar) {
      var textoConcatenado = this.model.requisicoes.reduce(function (textoAcumulado, requisicaoAtual) {
        if (!requisicaoAtual[atributoAConcatenar]) {
          return textoAcumulado;
        }
        var textoRequisicao = requisicaoAtual[atributoAConcatenar].trim();
        if (textoRequisicao.endsWith('.')) {
          textoRequisicao = textoRequisicao.slice(0, textoRequisicao.length - 1);
        }
        return textoAcumulado ? textoAcumulado + '; ' + textoRequisicao : textoRequisicao;
      }, '');

      return textoConcatenado ? textoConcatenado + '.' : '';
    },

    sugerirValorEstimado: (function () {
      if (this.get('ehAquisicaoExistente')) {
        return;
      }

      // var valorEstimadoSugerido = this.model.requisicoes.reduce(
      //   (valorAcumulado, requisicaoAtual) => {
      //     return valorAcumulado +  parseFloat(Util.stringNumericaBRparaUSA(requisicaoAtual.valorTotal));
      //   },
      //   0
      // );

      var valorEstimadoSugerido = this.model.requisicoes.reduce(function (valorAcumulado, requisicaoAtual) {
        var valorTotalRequisicaoFloat = parseFloat(Util['default'].stringNumericaBRparaUSA(requisicaoAtual.valorTotal));
        return new Big['default'](valorAcumulado).plus(new Big['default'](valorTotalRequisicaoFloat)).toString();
      }, 0);

      this.set('model.valorEstimado', Util['default'].stringNumericaUSAparaBR(valorEstimadoSugerido.toString()));
    }).observes('model', 'model.requisicoes.@each', 'ehAquisicaoExistente'),

    sugerirProgramaTrabalho: (function () {
      if (this.get('ehAquisicaoExistente')) {
        return;
      }
      if (this.model.requisicoes.length) {
        this.set('model.programaDeTrabalho', this.model.requisicoes[0].programaDeTrabalho);
      }
    }).observes('model', 'model.requisicoes.@each', 'ehAquisicaoExistente'),

    sugerirUnidadeRequisitante: (function () {
      if (this.get('ehAquisicaoExistente')) {
        return;
      }
      if (this.model.requisicoes.length) {
        this.set('model.unidadeRequisitante', this.model.requisicoes[0].unidadeRequisitante);
      }
    }).observes('model', 'model.requisicoes.@each', 'ehAquisicaoExistente'),

    sugerirEmailFiscal: (function () {
      //Guarda-se o email anterior do fiscal para desfazer a sugestão quando inadequada
      this.set('backupEmailFiscal', this.model.emailFiscal);
      if (this.model.fiscal) {
        this.set('model.emailFiscal', this.model.fiscal.email);
      } else {
        this.set('model.emailFiscal', undefined);
      }
    }).observes('model.fiscal.codSof'),
    //Inútil observar apensas "model.fiscal": o componente que seleciona o servidor
    //não seta um novo objeto nessa propriedade, apenas altera seu conteúdo

    sugerirEmailGestor: (function () {
      //Guarda-se o email anterior do fiscal para desfazer a sugestão quando inadequada
      this.set('backupEmailGestor', this.model.emailGestor);
      if (this.model.gestor) {
        this.set('model.emailGestor', this.model.gestor.email);
      } else {
        this.set('model.emailGestor', undefined);
      }
    }).observes('model.gestor.codSof'),
    //Inútil observar apensas "model.gestor": o componente que seleciona o servidor
    //não seta um novo objeto nessa propriedade, apenas altera seu conteúdo

    getNomeLegivelModalidade: function getNomeLegivelModalidade(aquisicao) {
      var modalidadeDaAquisicao = this.modalidades.find(function (modalidade) {
        return aquisicao.modalidade === modalidade.id;
      });
      return modalidadeDaAquisicao ? modalidadeDaAquisicao.nome : "";
    },

    descricaoRelatorios: (function () {
      var itemsDeMenuARetornar = [{
        textoMenu: 'Quadro Comparativo de Preços',
        servico: 'relatorio/quadroComparativoDePrecos/' + this.get("model.id"),
        nomeArquivo: 'quadro_comparativo_de_precos',
        nomeParaID: 'quadro-comparativo-precos',
        controlePermissao: true
      }, {
        textoMenu: 'Relação de Adjudicações',
        servico: 'relatorio/relacaoDeAdjudicacoes/' + this.get("model.id"),
        nomeArquivo: 'relacao_de_adjudicacoes',
        nomeParaID: 'relacao-adjudicacoes',
        controlePermissao: true
      }, {
        textoMenu: 'Relação de Aquisições',
        servico: 'relatorio/relacaoDeAquisicoes/' + this.get("model.id"),
        nomeArquivo: 'aquisicao_relacao_de_aquisicoes',
        nomeParaID: 'relacao-aquisicoes',
        controlePermissao: true
      }];

      if (this.model.modalidade === 'DISPENSA') {

        itemsDeMenuARetornar.push({
          textoMenu: 'Termo de Dispensa de Licitacao',
          servico: 'relatorio/termoDispensaDeLicitacao/' + this.get("model.id"),
          nomeArquivo: 'termo_dispensa_de_licitacao',
          nomeParaID: 'termo-dispensa-licitacao',
          controlePermissao: true
        });
      }

      return itemsDeMenuARetornar;
    }).property("model.id"),

    actions: {
      excluirRequisicaoBase: function excluirRequisicaoBase(requisicao) {
        this.model.requisicoes.removeObject(requisicao);
      },
      acessarProcesso: function acessarProcesso() {
        var _this4 = this;

        this.get('comunicador').servico('get', 'parametroExibicao').then(function (resultado) {
          var acaoUrlProcessoEletronico = Util['default'].converteParametrosDeExibicao(resultado).ACAO_URL_PROCESSO_ELETRONICO;
          var saveData = (function () {
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            return function (data, fileName) {
              var blob = new Blob([data], { type: "application/pdf" }),
                  url = window.URL.createObjectURL(blob);
              a.href = url;
              a.download = fileName;
              a.click();
              window.URL.revokeObjectURL(url);
            };
          })();

          var xhttp = new XMLHttpRequest();
          xhttp.onloadend = function () {
            if (xhttp.readyState === 4) {
              if (xhttp.status === 200) {
                if (acaoUrlProcessoEletronico === "BAIXAR_ARQUIVO") {
                  saveData(xhttp.response, 'processo_' + _this4.model.numeroProcessoAdministrativo + '.pdf');
                } else {
                  var url = xhttp.response.includes("http://") || xhttp.response.includes("https://") ? xhttp.response : "http://" + xhttp.response;
                  window.open(url, '_blank');
                }
              } else {
                Util['default'].alertaErro("Erro: processo não encontrado");
              }
            }
          };
          xhttp.open('get', Defs['default'].enderecoServer + "pa/download/" + encodeURI(_this4.model.numeroProcessoAdministrativo), true);
          xhttp.setRequestHeader('authToken', _this4.get('comunicador').get('usuarioLogado').estaLogado() ? _this4.get('comunicador').get('usuarioLogado').info.authToken : '');
          xhttp.setRequestHeader('TimeZone', _this4.get('comunicador').get('usuarioLogado').estaLogado() ? JSON.stringify(new Date()).substr(24, 5) : '');
          xhttp.responseType = acaoUrlProcessoEletronico === "BAIXAR_ARQUIVO" ? "arraybuffer" : "text";
          xhttp.send();
        });
      }
    }
  });

});