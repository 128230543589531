define('webapp/pods/transparencia/documento/model', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,
		version: null,
		modulo: null,
		aquisicao: null,
		contrato: null,
		descricao: null,
		ordem: null,
		tipo: null,
		tipoPNCP: null,
		tipoPNCPDescricao: null,
		documento: null,

		findUrlFn: function findUrlFn(id) {
			return "transparencia/documento/" + id.toString();
		}

	});

});