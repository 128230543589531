define('webapp/pods/pncp/listagem/constantes-envio-pncp', ['exports'], function (exports) {

  'use strict';

  exports['default'] = {

    modulos: [{ id: 'AQUISICAO', nome: 'Aquisição' }, { id: 'EMPENHO', nome: 'Empenho' }, { id: 'CONTRATO', nome: 'Contrato' }]

  };

});