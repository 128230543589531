define('webapp/components/date-input', ['exports', 'ember', 'webapp/mixins/ajusta-id-interno-mixin', 'webapp/mixins/ajusta-tamanho-input-mixin', 'webapp/mixins/constroi-aria-label-mixin'], function (exports, Ember, AjustaIdInternoMixin, AjustaTamanhoInputMixin, ConstroiAriaLabelMixin) {

  'use strict';

  /*
  Exemplo de uso:
  {{date-input
  	 valor=<valor associado ao input>
     labelAcessivel=<texto para ser lido por leitores automáticos de interface
                  para acessibilidade por pessoas com deficiência visual.
                  Se não for definido, será lido o texto do label do campo>
     tamanho=<largura do componente em termos do bootstrap - um número X
              de 1 a 12 utilizado para montar a classe CSS "col-md-X".
              O default é 12>
  	 classeLabel=<classe do label, default é "col-md-2 control-label">
  	 classeElementoInterno=<classe do input, default é "col-md-10">
  	 somenteLeitura=<true ou false, sem aspas>
     obrigatorio=<true ou false, sem aspas, indicando se o campo deve apresentar um asterisco
                  ao lado do seu label>
  	 autoFoco=<true | false (default), indicando se o input deve receber o foco assim que for
  	  					renderizado. Caso mais de um input que apareça na mesma tela tenha essa opção
  							marcada, o último que for renderizado receberá o foco>
  }}

  Todo date-input com id    "tag_W_X_Y_Z"
  cria um componente de id  "campo_W_X_Y_Z"

  O funcionamento de "tamanho", "tamanhoLabel" e "tamanhoCampo" é dependente do ajusta-tamanho-input-mixin.
  */

  exports['default'] = Ember['default'].Component.extend(AjustaIdInternoMixin['default'], AjustaTamanhoInputMixin['default'], ConstroiAriaLabelMixin['default'], {
    disabled: false,
    somenteLeitura: false,
    valorInicial: null,
    valorFinal: null,

    tipoInterno: 'campo',

    insereAriaLabel: (function () {
      var campo = this.$('input')[0];
      campo.setAttribute('aria-label', this.get('_ariaLabel'));
    }).on('willInsertElement'),

    focalizar: (function () {
      if (this.autoFoco) {
        this.$('input')[0].focus();
      }
    }).on('didInsertElement'),

    //O componente "bootstrap-datepicker" tem um bug: se se apagar a data caracter
    //por caracter, usando o teclado, o valor não é efetivamente apagado, permanecendo
    //o valor que estava anteriormente no campo. Abaixo, uma solução de contorno para
    //isso.
    //Adicionalmente, isso obriga o usuário a informar a data no formato dd/mm/aaaa,
    //pois há problemas em informar, por exemplo, como dd/mm/aa (ex. 01/01/16 é, em
    //alguns casos interpretado como "01/01/0016", mas em outros como "01/01/2016").

    //O componente observa as alterações que forem realmente efetivadas no campo data (i.e. não
    //cada novo caractere que é digitado, mas o valor que efetivamente fica no
    //input ao clicar-se fora dele ou teclar-se Enter) e registra se está no formato
    //correto ou não. Isso aproveita algumas funcionalidades internas do
    //bootstrap-datepicker (e.g. ao digitar-se 01-01-2001 e teclar-se Enter, ele
    //transforma a data em 01/01/2001).
    dataValida: true, // É necessário que 'dataValida' inicie true
    dataVazia: false, //É necessário que 'dataVazia' inicie false,
    //para que não se precise clicar duas vezes no calendário
    //para selecionar a data pela primeira vez
    change: function change() {
      var campo = this.$('input')[0];

      var valorEfetivoCampo = campo.value;
      this.set('dataValida', this._ehDataNoFormatoCorreto(valorEfetivoCampo));
      this.set('dataVazia', !valorEfetivoCampo);

      this._atualizaValor(valorEfetivoCampo);
    },

    //Verifica se a data está exatamente no formato dd/mm/aaaa
    //Ou se é completamente em branco
    _ehDataNoFormatoCorreto: function _ehDataNoFormatoCorreto(data) {
      if (data === '' || data === undefined || data === null) {
        return true;
      }
      return data.match(/\d{2}\/\d{2}\/\d{4}$/gi);
    },

    //Se a data não for válida (não estiver exatamente no formato dd/mm/aaaa), apaga
    //o que estiver no componente
    focusOut: function focusOut() {
      if (!this.dataValida || this.dataVazia) {
        this.set('valor', '');
      }
    },

    _atualizaValor: function _atualizaValor(valorEfetivoCampo) {
      var dataSelecionada = this._geraDateAPartirStringDDMMAAAA(valorEfetivoCampo);

      if (this.valor && dataSelecionada && this.valor.valueOf() !== dataSelecionada.valueOf()) {
        this.set('valor', dataSelecionada);
      }
    },

    _geraDateAPartirStringDDMMAAAA: function _geraDateAPartirStringDDMMAAAA(stringData) {
      var ano = this._extraiNumeroCorrespondenteAnoDaData(stringData);
      var mes = this._extraiNumeroCorrespondenteMesDaData(stringData);
      var dia = this._extraiNumeroCorrespondenteDiaDaData(stringData);

      if (!ano || !mes || !dia) {
        return null;
      }

      return new Date(ano, mes, dia);
    },

    //Se a string não tiver um dia válido (por exemplo, um dia "0"), retorna null
    _extraiNumeroCorrespondenteDiaDaData: function _extraiNumeroCorrespondenteDiaDaData(stringData) {
      var stringDia = stringData.split('/')[0];
      var numeroDia = Number(stringDia);

      if (!numeroDia || numeroDia < 1 || numeroDia > 31) {
        return null;
      }

      return numeroDia;
    },

    //Se a string não tiver um mês válido (por exemplo, um mês > 12), retorna null
    //Janeiro = 0; Dezembro = 12
    _extraiNumeroCorrespondenteMesDaData: function _extraiNumeroCorrespondenteMesDaData(stringData) {
      var stringMes = stringData.split('/')[1];
      var numeroMesDigitado = Number(stringMes);

      if (!numeroMesDigitado || numeroMesDigitado < 1 || numeroMesDigitado > 12) {
        return null;
      }

      //Meses vão de Janeiro = 0 a Dezembro = 12
      return numeroMesDigitado - 1;
    },

    //Se a string não tiver um ano válido (por exemplo, um ano > 0), retorna null
    _extraiNumeroCorrespondenteAnoDaData: function _extraiNumeroCorrespondenteAnoDaData(stringData) {
      var stringAno = stringData.split('/')[2];
      var numeroAno = Number(stringAno);

      if (!numeroAno || numeroAno < 0) {
        return null;
      }

      return numeroAno;
    }

  });

});