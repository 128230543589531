define('webapp/pods/publico/compras/controller', ['exports', 'ember', 'webapp/utils/util', 'webapp/pods/publico/constantes-transparencia', 'webapp/pods/transparencia/documento/constantes-documento', 'webapp/defs'], function (exports, Ember, Util, Constantes, ConstantesDocumentos, Defs) {

    'use strict';

    exports['default'] = Ember['default'].ArrayController.extend({
        queryParams: ['ano', 'mes', 'descricao', 'modalidade'],

        modalidades: Constantes['default'].modalidadesDeCompras,
        meses: Constantes['default'].opcoesMeses,
        retroagir: 5,

        anos: (function () {
            return Constantes['default'].opcoesAnos(this.retroagir);
        }).property('retroagir'),

        getUrlDownload: function getUrlDownload(documento) {
            return Defs['default'].enderecoServer + documento.urlDownload;
        },

        formataValorDate: function formataValorDate(date) {
            if (!date) {
                return "";
            }
            var opcoesFormatoData = { day: 'numeric', month: 'numeric', year: 'numeric' };
            if (date.getHours() || date.getMinutes() || date.getSeconds()) {
                opcoesFormatoData['hour'] = '2-digit';
                opcoesFormatoData['minute'] = '2-digit';
            }
            return date.toLocaleString('pt-BR', opcoesFormatoData);
        },

        formataCPFCNPJ: function formataCPFCNPJ(item) {
            var docIdentificacao = item.fornecedorCPF ? Util['default'].incluiPontuacaoCpf(item.fornecedorCPF) : Util['default'].incluiPontuacaoCnpj(item.fornecedorCNPJ);
            return docIdentificacao;
        },

        formataValor: function formataValor(valor) {
            if (!valor) {
                return "";
            }
            var formatoAmericano = valor.replace(',', '.');
            var stringComSeparador = Number(formatoAmericano).toLocaleString('pt', { minimumFractionDigits: 2 });
            return stringComSeparador === 'NaN' ? valor : stringComSeparador;
        },

        mostrarObservacaoTipoDocumento: function mostrarObservacaoTipoDocumento(aquisicao) {
            // comparação feita para mostrar aviso quando aquisição não possui Estudo Técnico Preliminar
            var aquisicaoPossuiETP = aquisicao.documentos.find(function (documento) {
                if (documento.pncpcodigoTipoDoc == 7) {
                    return true;
                }
                if (documento.descricao == null) {
                    return false;
                }
                // remove acentos e espaços em branco e transforma em minúsculas
                var descricaoNormalizada = documento.descricao.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/\s+/g, '').toLowerCase();
                var regexETP = /\betp\b/; // \b garante que "etp" seja uma palavra isolada (bounded)

                // Verifica se a descrição contém "ETP" ou "Estudo Técnico Preliminar" ou "Estudos Técnicos Preliminares"
                if (regexETP.test(descricaoNormalizada) || descricaoNormalizada.includes('estudotecnicopreliminar') || descricaoNormalizada.includes('estudostecnicospreliminares')) {
                    return true;
                }
                return false;
            });
            return !aquisicaoPossuiETP;
        },

        getNomeLegivelModalidade: function getNomeLegivelModalidade(item) {
            var modalidadeDaAquisicao = this.modalidades.find(function (modalidade) {
                return item.modalidade === modalidade.id;
            });
            return modalidadeDaAquisicao.nome;
        },

        getDescricaoDocumento: function getDescricaoDocumento(item) {
            if (item.descricao) {
                return item.descricao;
            }

            for (var ind = 0; ind < ConstantesDocumentos['default'].tipos.length; ind++) {
                if (item.tipo == ConstantesDocumentos['default'].tipos[ind].id) return ConstantesDocumentos['default'].tipos[ind].nome;
            }

            return item.tipo;
        },

        actions: {
            listar: function listar() {
                var _this = this;

                var criteriosPesquisa = this.getProperties('ano', 'mes', 'descricao', 'modalidade');

                this.get('comunicador').servico('post', 'transparencia/compras', criteriosPesquisa, this).then(function (resultado) {
                    _this.set('model', resultado);
                    _this.set('mostrarResultados', true);
                });
            },

            limparPesquisa: function limparPesquisa() {
                this.limparDadosPesquisa();
                this.set('model', []);
            }

        }
    });

});