define('webapp/pods/aquisicao/ata/documento/model', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,
		version: null,
		descricao: null,
		tipo: null,
		tipoPNCP: null,
		documento: null,
		nome: null,
		aquisicao: null,
		itemAquisicao: null,
		ata: null,

		findUrlFn: function findUrlFn(id) {
			return "transparencia/documento/" + id.toString();
		}
	});

});