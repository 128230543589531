define('webapp/pods/aquisicao/contrato/identificacao/dados-basicos/template', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.12.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(fragment,1,1,contextualElement);
            inline(env, morph0, context, "tag-select", [], {"texto": "Categoria do Processo", "obrigatorio": true, "conteudo": get(env, context, "categoriasProcesso"), "opcaoLabel": "nome", "valor": get(env, context, "model.categoriaProcesso"), "textopadrao": "Selecione a categoria do processo", "permiteLimpar": true});
            return fragment;
          }
        };
      }());
      var child1 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.12.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(fragment,1,1,contextualElement);
            inline(env, morph0, context, "tag-select", [], {"texto": "Natureza", "obrigatorio": true, "conteudo": get(env, context, "modalidades"), "opcaoLabel": "nome", "valor": get(env, context, "model.contratoModalidade"), "textopadrao": "Selecione a modalidade", "permiteLimpar": true, "id": "select||modalidade|aquisicao/contrato|identificacao"});
            return fragment;
          }
        };
      }());
      return {
        isHTMLBars: true,
        revision: "Ember@1.12.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-md-12 text-left");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("fieldset");
          dom.setAttribute(el3,"style","font-weight: bold");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("legend");
          dom.setAttribute(el4,"style","margin-top: 10px; margin-bottom: 5px; font-size: 14px");
          var el5 = dom.createTextNode("Convênio*");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-md-12 text-left");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("fieldset");
          dom.setAttribute(el3,"style","font-weight: bold");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("legend");
          dom.setAttribute(el4,"style","margin-top: 10px; margin-bottom: 5px; font-size: 14px");
          var el5 = dom.createTextNode("Receita*");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-md-12 text-left");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("fieldset");
          dom.setAttribute(el3,"style","font-weight: bold");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("legend");
          dom.setAttribute(el4,"style","margin-top: 10px; margin-bottom: 5px; font-size: 14px");
          var el5 = dom.createTextNode("Envio ao PNCP*");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, inline = hooks.inline, block = hooks.block;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [7, 1]);
          var element2 = dom.childAt(element0, [9, 1]);
          var element3 = dom.childAt(element0, [11, 1]);
          var morph0 = dom.createMorphAt(element0,1,1);
          var morph1 = dom.createMorphAt(element0,3,3);
          var morph2 = dom.createMorphAt(element0,5,5);
          var morph3 = dom.createMorphAt(element1,3,3);
          var morph4 = dom.createMorphAt(element1,5,5);
          var morph5 = dom.createMorphAt(element2,3,3);
          var morph6 = dom.createMorphAt(element2,5,5);
          var morph7 = dom.createMorphAt(element3,3,3);
          var morph8 = dom.createMorphAt(element3,5,5);
          inline(env, morph0, context, "tag-input", [], {"texto": "Número", "valor": get(env, context, "this.model.numeroApresentacao"), "somenteLeitura": true, "id": "tag||numero|aquisicao/contrato|identificacao"});
          block(env, morph1, context, "if", [get(env, context, "mostrarCampoCategoriaDoProcesso")], {}, child0, null);
          block(env, morph2, context, "if", [get(env, context, "mostrarCampoNatureza")], {}, child1, null);
          inline(env, morph3, context, "tag-radio-button", [], {"texto": "Sim", "valor": true, "atributoDestino": get(env, context, "model.convenio"), "labelNegrito": true, "tamanho": 6, "desabilitado": get(env, context, "ehContratoExistente"), "id": "radio||eh-convenio|aquisicao/contrato|identificacao"});
          inline(env, morph4, context, "tag-radio-button", [], {"texto": "Não", "valor": false, "atributoDestino": get(env, context, "model.convenio"), "labelNegrito": true, "tamanho": 6, "desabilitado": get(env, context, "ehContratoExistente"), "id": "radio||nao-eh-convenio|aquisicao/contrato|identificacao"});
          inline(env, morph5, context, "tag-radio-button", [], {"texto": "Sim", "valor": true, "atributoDestino": get(env, context, "model.receita"), "labelNegrito": true, "tamanho": 6, "desabilitado": get(env, context, "ehContratoExistente"), "id": "radio||eh-receita|aquisicao/contrato|identificacao"});
          inline(env, morph6, context, "tag-radio-button", [], {"texto": "Não", "valor": false, "atributoDestino": get(env, context, "model.receita"), "labelNegrito": true, "tamanho": 6, "desabilitado": get(env, context, "ehContratoExistente"), "id": "radio||nao-eh-receita|aquisicao/contrato|identificacao"});
          inline(env, morph7, context, "tag-radio-button", [], {"texto": "Sim", "valor": true, "atributoDestino": get(env, context, "model.enviaPNCP"), "labelNegrito": true, "tamanho": 6, "desabilitado": get(env, context, "ehContratoExistente"), "id": "radio||envia-PNCP||aquisicao/contrato|identificacao"});
          inline(env, morph8, context, "tag-radio-button", [], {"texto": "Não", "valor": false, "atributoDestino": get(env, context, "model.enviaPNCP"), "labelNegrito": true, "tamanho": 6, "desabilitado": get(env, context, "ehContratoExistente"), "id": "radio||nao-envia-PNCP|aquisicao/contrato|identificacao"});
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, null);
        dom.insertBoundary(fragment, 0);
        block(env, morph0, context, "tag-bloco", [], {"tamanho": 12, "titulo": "Dados Básicos"}, child0, null);
        return fragment;
      }
    };
  }()));

});