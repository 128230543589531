define('webapp/pods/pncp/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin', 'webapp/pods/pncp/constantes-pncp-envio'], function (exports, Ember, CrudMixin, Constantes) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {
        needs: ['pncp/listagem'],

        constantesOperacoes: Constantes['default'].operacoes,
        constantesUnidades: Constantes['default'].descricoesUnidades,
        textoParaBreadCrumb: "",
        nomeDaEntidadePrincipal: "",
        idEntidadePrincipal: 0,
        desabilitaEnvio: false,

        pptyDesabilitaEnvio: (function () {
            return this.desabilitaEnvio;
        }).property('desabilitaEnvio'),

        getModelRotaSuperior: function getModelRotaSuperior() {
            return this.get('controllers.pncp/listagem').model;
        },

        getControllerRotaSuperior: function getControllerRotaSuperior() {
            return this.get('controllers.pncp/listagem');
        },

        getDescricaoDaOperacao: function getDescricaoDaOperacao(envioPNCP) {
            // Tenta converter o tipo para uma das opções nas constantes
            var operacao = this.constantesOperacoes.find(function (operacao) {
                return operacao.id === envioPNCP.descricaoDaOperacao;
            });
            if (operacao) {
                return operacao.nome;
            }
            return envioPNCP.descricaoDaOperacao;
        },

        getDescricaoDaUnidade: function getDescricaoDaUnidade(envioPNCP) {
            // Tenta converter o tipo para uma das opções nas constantes
            var unidadeDeEnvio = this.constantesUnidades.find(function (unidade) {
                return unidade.id === envioPNCP.idUnidadeDeEnvio;
            });
            if (unidadeDeEnvio) {
                return unidadeDeEnvio.nome;
            }
            return envioPNCP.descricaoDaUnidadeDeEnvio;
        },

        breadCrumbConfig: {
            fnNomeApresentacao: 'fnNomeApresentacao'
        },

        fnNomeApresentacao: function fnNomeApresentacao() {
            return '' + (this.textoParaBreadCrumb ? this.textoParaBreadCrumb : 'PNCP');
        },

        actions: {
            enviarAgora: function enviarAgora() {
                var _this = this;

                this.get('comunicador').servico('post', this.nomeDaEntidadePrincipal.toLowerCase() + '/' + this.idEntidadePrincipal + '/enviaPNCP', null).then(function () {
                    _this.send('retorna', 'manterDadosPesquisaAnterior');
                });
            },
            cancelar: function cancelar() {
                this.send('retorna', 'manterDadosPesquisaAnterior');
            }
        }
    });

});