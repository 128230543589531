define('webapp/pods/aquisicao/documento/controller', ['exports', 'ember', 'webapp/defs', 'webapp/utils/util', 'webapp/pods/transparencia/documento/constantes-documento', 'webapp/mixins/crud-mixin'], function (exports, Ember, Defs, Util, ConstantesDocumento, CrudMixin) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {

    needs: ['aquisicao'],

    breadCrumbConfig: {
      fnNomeApresentacao: 'fnNomeApresentacao'
    },

    opcoesItemCompra: [],
    tiposPNCP: ConstantesDocumento['default'].tipos,
    tipos: ConstantesDocumento['default'].tipos,

    fnNomeApresentacao: function fnNomeApresentacao() {
      return 'Documento ' + (this.model.version ? this.model.numeroApresentacao : '(Novo)');
    },

    crudConfig: {
      criarComUpload: {
        preparaUploadFn: function preparaUploadFn() {
          return this.criarDoc();
        },
        okFn: function okFn(opcao) {
          this.get('controllers.aquisicao').send('ler');
          if (opcao === 'CRIAR_E_PROXIMO') {
            this.set('model', []);
            this.get('controllers.aquisicao').send('ler');
            this.send('refreshRota'); //ação "borbulhará" para a rota
            return;
          }
          this.get('controllers.aquisicao').send('ler');
          this.transitionToRoute('aquisicao');
        }
      },
      excluir: {
        urlFn: function urlFn(id) {
          return 'transparencia/documento/aquisicao/' + id;
        },
        okFn: function okFn() {
          this.get('controllers.aquisicao').send('ler');
        }
      }
    },

    criarDoc: function criarDoc() {
      var formData = new FormData();
      formData.append("idAquisicao", this.get("controllers.aquisicao").model.id);
      formData.append("modulo", "AQUISICAO");
      if (this.model.tipo == null) {
        formData.append("tipo", "EDITAL");
      } else {
        formData.append("tipo", this.model.tipo);
      }
      if (this.model.idItemCompra) {
        formData.append("idItemCompra", this.model.idItemCompra);
      }
      if (this.model.tipoPNCP) {
        formData.append("tipoPNCP", this.model.tipoPNCP);
      }
      formData.append("ordem", "0");
      if (this.model.descricao) {
        formData.append("descricao", this.model.descricao);
      }
      var uploadInput = Ember['default'].$(".row-input-upload input[type=file]").get(0);

      if (uploadInput.files[0]) {
        if (!Util['default'].verificarTipoArquivoValido(uploadInput.files[0].name)) {
          Util['default'].alertaErro('Formato inválido para o arquivo.');
          return;
        }

        if (!Util['default'].verificarTamanhoArquivoValido(uploadInput.files[0].size)) {
          Util['default'].alertaErro('Arquivo com tamanho superior a 30MB.');
          return;
        }

        formData.append('documento', uploadInput.files[0]);
      }

      return formData;
    },

    carregaItensDeCompra: (function () {
      var _this = this;

      this.get('comunicador').servico('GET', 'aquisicao/' + this.get('controllers.aquisicao').model.id).then(function (resultado) {
        var itens = [];
        if (resultado.compras) {
          resultado.compras.forEach(function (compra) {
            compra.itens.forEach(function (itemCompra) {
              var itemArray = { "id": itemCompra.id,
                "descricao": 'Compra ' + compra.numeroApresentacao + ' - Item ' + itemCompra.numeroApresentacao + ' - ' + itemCompra.itemAquisicao.descricao };
              itens.push(itemArray);
            });
          });
        }
        _this.set('opcoesItemCompra', itens);
      });
    }).observes('model.aquisicao'),

    careggaTiposDeDocumento: (function () {
      var _this2 = this;

      this.get('comunicador').servico('GET', 'tiposDeDocumentoPNCP/' + this.get('controllers.aquisicao').model.id + '?tipoEntidade=aquisicao').then(function (resultado) {
        _this2.set('tiposPNCP', resultado);
      });
    }).observes('model.aquisicao'),

    tipoDocumentoDoPNCP: (function () {
      return this.get("controllers.aquisicao").model.enviaPNCP;
    }).property('model.aquisicao'),

    getItemLinhaTabela: function getItemLinhaTabela(documento) {
      if (documento.itemCompra) {
        return 'Item de compra ' + documento.itemCompra.numeroApresentacao + ' (' + documento.itemCompra.itemAquisicao.descricao + ')';
      }
      return;
    },

    getDescricaoTipoDocumento: function getDescricaoTipoDocumento(documento) {
      if (!!documento.tipoPNCPDescricao) {
        return documento.tipoPNCPDescricao;
      }

      var tipo = this.tipos.find(function (t) {
        return t.id === documento.tipo;
      });
      if (tipo) {
        return tipo.nome;
      }

      return documento.tipo;
    },

    getLink: function getLink(documento) {
      return Defs['default'].enderecoServer + documento.urlDownload;
    },

    getTextoAcessivel: function getTextoAcessivel(valor, tituloColuna, textoAcessivelDefaultDoComponente) {
      return textoAcessivelDefaultDoComponente + ", download";
    }

  });

});