define('webapp/pods/pesquisa-contratos/controller', ['exports', 'ember', 'webapp/pods/pesquisa-contratos/model', 'webapp/pods/pesquisa-contratos/constantes-pesquisa-contrato', 'webapp/utils/util', 'webapp/pods/aquisicao/contrato/model', 'webapp/utils/constantes', 'npm:numeral'], function (exports, Ember, CriteriosPesquisa, Constantes, Util, ModelContrato, C, numeral) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    queryParams: ['manterDadosPesquisaAnterior'],
    manterDadosPesquisaAnterior: false,
    limpandoCamposDeReajuste: false,
    limpandoCheckboxSemReajuste: false,
    criteriosPesquisaBackup: null, // Guarda os critérios da última pesquisa, para que, ao clicarmos nos relatórios, esses critérios possam ser enviados exatamente como estavam no momento da pesquisa.

    atribuiValoresDefault: function atribuiValoresDefault() {
      this.limpandoCamposDeReajuste = false;
      this.limpandoCheckboxSemReajuste = false;
    },

    inicializaCampos: function inicializaCampos(model) {

      this.set('model', model);

      this.get('comunicador').leParaPropriedade(this, 'indiceReajuste', 'indicesReajuste', 'Não foi possível obter os índices de reajuste.');

      this.get('comunicador').leParaPropriedade(this, 'unidadeOrcamentaria/selecionavel/unidadeOrganizacionalCorrespondente', 'unidadesOrganizacionais', 'Não foi possível obter as unidades organizacionais.');

      this.get('comunicador').leParaPropriedade(this, 'categoriaProcesso', 'categoriasProcesso', 'Não foi possível obter as categorias de processo de contrato.');

      //para período de divulgação índice reajuste
      this.set('meses', [{ id: 1, nome: 'Janeiro' }, { id: 2, nome: 'Fevereiro' }, { id: 3, nome: 'Março' }, { id: 4, nome: 'Abril' }, { id: 5, nome: 'Maio' }, { id: 6, nome: 'Junho' }, { id: 7, nome: 'Julho' }, { id: 8, nome: 'Agosto' }, { id: 9, nome: 'Setembro' }, { id: 10, nome: 'Outubro' }, { id: 11, nome: 'Novembro' }, { id: 12, nome: 'Dezembro' }]);
    },

    opcoesBooleanasComDescricao: [{ id: 1, valor: true, descricao: 'Sim' }, { id: 0, valor: false, descricao: 'Não' }],

    retornoMontaCSV: {
      csv: '',
      arrayMensagens: []
    },

    breadCrumbConfig: { nomeApresentacao: 'Contratos' },

    statusPossiveisGarantiaMinima: Constantes['default'].statusPossiveisGarantiaMinima,
    statusPossiveisVigenciaExecucao: Constantes['default'].statusPossiveisVigenciaExecucao,

    getIdsRota: function getIdsRota(contrato) {
      return [contrato.idAquisicao, contrato.id];
    },

    pesquisaNaoEnvolveVigenciaExecucao: (function () {
      return !this.model || !this.model.statusVigenciaExecucao;
    }).property('model.statusVigenciaExecucao'),

    pesquisaNaoEnvolvePublicacao: (function () {
      return !this.model.dataMinimaPublicacao && !this.model.dataMaximaPublicacao;
    }).property('model.dataMinimaPublicacao', 'model.dataMaximaPublicacao'),

    pesquisaNaoEnvolveAssinatura: (function () {
      return !this.model.dataMinimaAssinatura && !this.model.dataMaximaAssinatura;
    }).property('model.dataMinimaAssinatura', 'model.dataMaximaAssinatura'),

    _gerenciarDadosPesquisaAnterior: function _gerenciarDadosPesquisaAnterior() {
      if (!this.manterDadosPesquisaAnterior) {
        this.limparDadosPesquisa();
      }

      this.set('manterDadosPesquisaAnterior', false);
    },

    algumCampoDeReajustePreenchido: function algumCampoDeReajustePreenchido() {
      //    Ember.Logger.error('verificaSeTemReajuste: this.get("model.mesReajuste") = '+this.get("model.mesReajuste"));
      //    Ember.Logger.error('verificaSeTemReajuste: this.get("model.indiceReajuste") = '+this.get("model.indiceReajuste"));
      return this.mesReajustePreenchido() || this.indiceReajustePreenchido();
    },

    mesReajustePreenchido: function mesReajustePreenchido() {
      return this.get("model.mesReajuste") != null && this.get("model.mesReajuste") > 0;
    },

    indiceReajustePreenchido: function indiceReajustePreenchido() {
      return this.get("model.indiceReajuste") != null && this.get("model.indiceReajuste.id") != null;
    },

    limpaCamposDeReajuste: (function () {
      this.limpandoCamposDeReajuste = true;
      try {
        if (!this.limpandoCheckboxSemReajuste) {
          if (this.get('model.considerarApenasContratosSemReajuste')) {
            this.set('model.mesReajuste', null);
            this.set('model.indiceReajuste', null);
          }
        }
      } finally {
        this.limpandoCamposDeReajuste = false;
      }
    }).observes('model.considerarApenasContratosSemReajuste'),

    limpaCheckboxSemReajuste: (function () {
      this.limpandoCheckboxSemReajuste = true;
      try {
        if (!this.limpandoCamposDeReajuste) {
          if (this.algumCampoDeReajustePreenchido()) {
            this.set('model.considerarApenasContratosSemReajuste', false);
          }
        }
      } finally {
        this.limpandoCheckboxSemReajuste = false;
      }
    }).observes('model.mesReajuste', 'model.indiceReajuste', 'model.indiceReajuste.id'),

    limparDadosPesquisa: function limparDadosPesquisa() {
      this.limparCriteriosPesquisa();
      this.limparResultados();
    },

    limparCriteriosPesquisa: function limparCriteriosPesquisa() {
      this.set('model', CriteriosPesquisa['default'].create({}));
    },

    limparResultados: function limparResultados() {
      //limpa tabela de resultados
      this.set('mostraTabelaResultadoPesquisa', false);
      this.set('mostraMensagemPesquisaSemResultado', false);
      this.set('resultadoPesquisa', []);
    },

    _getCriteriosPesquisaComAtributosBooleanosAjustados: function _getCriteriosPesquisaComAtributosBooleanosAjustados(criteriosPesquisa) {
      var criterios = Util['default'].clona(criteriosPesquisa);

      if (this.get('pesquisaNaoEnvolveVigenciaExecucao')) {
        criterios.considerarAditivosSemAssinaturaParaCalcularVigenciaOuExecucao = null;
      }

      if (this.get('pesquisaNaoEnvolvePublicacao')) {
        criterios.considerarPublicacaoContrato = null;
        criterios.considerarPublicacaoAditivo = null;
      }

      if (this.get('pesquisaNaoEnvolveAssinatura')) {
        criterios.considerarAssinaturaContrato = null;
        criterios.considerarAssinaturaAditivo = null;
        criterios.considerarAssinaturaApostilamento = null;
      }
      return criterios;
    },

    funcAuxClassifContrato: function funcAuxClassifContrato(contrato) {
      var arr = contrato && contrato.split("/") || [];
      if (arr.length > 0) {
        return parseInt(arr[1] + numeral['default'](parseInt(arr[0])).format('000000'));
      };
    },

    classificar: function classificar(arrayAClassificar, colunas, classificarPor) {
      var arrColunas = colunas.mapBy("path");
      var arrFns = colunas.mapBy("fn"); // Por enquanto só aceita "fn" em apenas uma ocorrência de arrTitulosColsAExibir (aqui representado pelo parâmetro "colunas")
      var indiceFn = arrColunas.indexOf(classificarPor.campo);
      var fn = arrFns[indiceFn];
      arrayAClassificar.sort(function (a, b) {
        var valA = fn(a[classificarPor.campo]);
        var valB = fn(b[classificarPor.campo]);
        if (valA > valB) {
          return 1 * classificarPor.ordem;
        } else if (valA < valB) {
          return -1 * classificarPor.ordem;
        } else {
          return 0;
        };
      });
      return;
    },

    actions: {
      pesquisar: function pesquisar() {
        var _this2 = this;

        this.set('mostrarResultados', false);

        var criteriosPesquisa = this._getCriteriosPesquisaComAtributosBooleanosAjustados(this.model);

        this.criteriosPesquisaBackup = Util['default'].clona(criteriosPesquisa);

        this.get('comunicador').servico('post', 'aquisicao/contrato/pesquisa', criteriosPesquisa).then(function (contratos) {
          _this2.set('resultadoPesquisa', contratos);
          var retornouItems = _this2.get('resultadoPesquisa').length > 0;
          _this2.set('mostraTabelaResultadoPesquisa', retornouItems);
          _this2.set('mostraMensagemPesquisaSemResultado', !retornouItems);
          _this2.set('mostrarResultados', true);
        });
      },

      excluir: function excluir(contrato) {
        var _this3 = this;

        this.get('comunicador').servico('delete', 'aquisicao/contrato/' + contrato.id).then(function () {
          _this3.send('pesquisar');
        });
      },

      limparPesquisa: function limparPesquisa() {
        this.limparDadosPesquisa();
        this.limparResultados();
      },

      download: function download() {

        //    ATENÇÃO!!!!    Para funcionar é preciso que TODOS OS ATRIBUTOS DOS MODELS que serão lidos pela planilha tenham seus nomes idênticos aos nomes dos
        //                atributos recebidos via JSON a partir do servidor em Java. Portanto, o que se altera lá, deve OBRIGATORIAMENTE refletir no model passado
        //                como parâmetro para Util.montaCSV().
        //                   TODOS OS MODELS que serão lidos pela planilha devem ter seus objetos inicializados com <objeto>.create({}) e seus arrays de objetos
        //                deverão ser inicializados através da function objDoArrayDoCampo que, se ainda não foi criada no model em questão, deverá ser copiada do
        //                model do contrato para esse e adaptada para inicializar todos os arrays de objeto desse.
        //                   CAMPOS QUE NÃO SÃO OBJETOS mas que podem receber null, devem ser inicializados com null no MODEL que é usado pela planilha. Um exemplo são
        //                os campos de meses em que a tela apresenta como combobox. Embora sejam numéricos, de 1 a 12, devem receber null e não 0, caso queiramos deixá-lo
        //                vazio, para que o combobox não fique congelado na tela.
        //    O atributo path do objeto de cada item do array arrTitulosColsAExibir deve ser o nome de um campo (case-sensitive) do model passado como parâmetro
        // para Util.montaCSV(), porém se o campo do model for um objeto ou um array de objetos, devemos indicar o atributo desse objeto que queremos exibir
        // usando ":" como separador (com arrays de objetos funciona da mesma forma). Note que não há limite de profundidade no aninhamento de objetos.
        //    Caso esse atributo path não tenha um correspondente no model, nada impede que façamos o que foi feito mais abaixo com o atributo
        // numeroAquisicao que foi agregado dinamicamente ao model.
        //    O atributo titulo do objeto de cada item do array arrTitulosColsAExibir deve ser o Título que deverá aparecer na planilha.
        var arrTitulosColsAExibir = [];

        arrTitulosColsAExibir.push({ path: "numeroAquisicao", titulo: "Aquisicao" });
        arrTitulosColsAExibir.push({ path: "numeroApresentacao", titulo: "Contrato", fn: this.funcAuxClassifContrato });
        arrTitulosColsAExibir.push({ path: "fornecedoresAtuais:nome", titulo: "Contratado" });
        arrTitulosColsAExibir.push({ path: "fornecedoresAtuais:cpf", titulo: "CPF Contratado" });
        arrTitulosColsAExibir.push({ path: "fornecedoresAtuais:cnpj", titulo: "CNPJ Contratado" });
        arrTitulosColsAExibir.push({ path: "objeto", titulo: "Objeto" });
        arrTitulosColsAExibir.push({ path: "numeroProcessoAdministrativo", titulo: "Processo Administrativo" });
        arrTitulosColsAExibir.push({ path: "contratoModalidade:nome", titulo: "Natureza" });
        arrTitulosColsAExibir.push({ path: "dataFimVigenciaAtualizada", titulo: "Término Vigência" });
        arrTitulosColsAExibir.push({ path: "unidadeRequisitante:nome", titulo: "Área Requisitante" });
        arrTitulosColsAExibir.push({ path: "fiscaisAtuais:servidor:nome", titulo: "Fiscal" });
        arrTitulosColsAExibir.push({ path: "gestoresAtuais:servidor:nome", titulo: "Gestor" });
        arrTitulosColsAExibir.push({ path: "indiceReajuste:nome", titulo: "Índice Reajuste" });
        arrTitulosColsAExibir.push({ path: "mesReajuste", titulo: "Mês Referência Reajuste" });
        arrTitulosColsAExibir.push({ path: "valorTotalGarantido", titulo: "Valor Garantia Prestada" });
        arrTitulosColsAExibir.push({ path: "compras:destinatarioPagamento:nome", titulo: "Destinatário Pagamento (no caso das locações)" });
        arrTitulosColsAExibir.push({ path: "compras:destinatarioPagamento:cnpj", titulo: "CNPJ Destinatário Pagamento (no caso das locações)" });
        arrTitulosColsAExibir.push({ path: "compras:destinatarioPagamento:cpf", titulo: "CPF Destinatário Pagamento (no caso das locações)" });

        var classificarPor = { campo: "numeroApresentacao",
          ordem: C['default'].ORDEM.ASC };
        var arrContratos = [];
        var arrPromessasAquisicao = [];
        var arrPromessasContrato = [];
        var arrMensagensErro = [];
        var arrAlertas = [];
        var _this = this;

        this.get('resultadoPesquisa').forEach(function (registro) {
          var promessaContrato = _this.get('comunicador').servico('get', 'aquisicao/contrato/' + registro.id + '/semAlertas', null);
          promessaContrato.then(function (resultadoContrato) {
            var promessaAquisicao = _this.get('comunicador').servico('get', 'aquisicao/' + resultadoContrato.idAquisicao, null);
            promessaAquisicao.then(function (resultadoAquisicao) {
              resultadoContrato.numeroAquisicao = resultadoAquisicao.numeroApresentacao;
              arrContratos.push(resultadoContrato);
            }, function (erro) {
              arrMensagensErro.push("Não foi possível acessar a aquisição cujo id é " + resultadoContrato.idAquisicao + " por causa do erro: " + erro);
            });
            arrPromessasAquisicao.push(promessaAquisicao);
          }, function (erro) {
            arrMensagensErro.push("Não foi possível acessar o contrato cujo id é " + registro.id + " por causa do erro: " + erro);
          });
          arrPromessasContrato.push(promessaContrato);
        });

        Promise.all(arrPromessasContrato).then(function (values) {
          Promise.all(arrPromessasAquisicao).then(function (values) {
            _this.classificar(arrContratos, arrTitulosColsAExibir, classificarPor);
            _this.set('retornoMontaCSV', Util['default'].montaCSV(ModelContrato['default'].create({}), arrContratos, arrTitulosColsAExibir));
            var colunasNaoEncontradas = _this.get('retornoMontaCSV').colunasNaoEncontradas;
            if (colunasNaoEncontradas.length > 0) {
              colunasNaoEncontradas.unshift("As seguintes colunas não foram encontradas:<BR />");
              arrAlertas.push(colunasNaoEncontradas.join("<BR />"));
            };
            if (arrMensagensErro.length > 0) {
              arrAlertas.push(arrMensagensErro.join("<BR />"));
            }
            Util['default'].alertas(arrAlertas);
            Util['default'].downloadCSV(_this.get('retornoMontaCSV').csv, 'PlanilhaContratos');
          });
        });

        /*
        var Model_Classe = Ember.Object.extend({
            nome : '',
            a : [],
            sobrenome : '',
          
            inicializar: function() {
              this.set('nome', '');
              this.set('a', []);
              this.set('sobrenome', '');
              }.on('init'),
            
            nomeDeCampoAmigavel: function (campo) {
              if      (campo === 'a') {return 'A[]';}
              else {return campo;}
              },
            
              objDoArrayDoCampo: function (metadado) {
              if (metadado.key === 'a') {return Classe_de_A.create({});}
              else {return metadado.valor;}
              },
                
        });
        
          var Classe_de_A = Ember.Object.extend({
            nome : "",
            b : [],
            cor: "",
        
            inicializar: function() {
              this.set('nome', '');
              this.set('b', []);
              this.set('cor', '');
              }.on('init'),
              
            nomeDeCampoAmigavel: function (campo) {
              if (campo === 'b') {return 'B[]';}
              else {return campo;}
              },
            objDoArrayDoCampo: function (metadado) {
              if 		(metadado.key === 'b') {return Classe_de_B.create({});}
              else {return metadado.valor;}
              },
          
          });
          
          var Classe_de_B = Ember.Object.extend({
            nome : "",
            estado: "",
        
            inicializar: function() {
            }.on('init'),
              
          });
        
          var arrObj = [];
        
          var ClasseDoObj = Ember.Object.extend({
            nome : '',
            a : [],
            sobrenome : '',
                  });	
        
          var constroiArrayA_Obj0 = function() {
            var arrObjA = [];
            var objA;
        
            objA = Classe_de_A.create({});
            objA.nome = 'BMW';
            objA.b = constroiArrayB_A0_Obj0();
            objA.cor = 'Preta';
        
            arrObjA.push(objA);
        
            objA = Classe_de_A.create({});
            objA.nome = 'Ferrari';
            objA.b = constroiArrayB_A1_Obj0();
            objA.cor = 'Vermelha';
        
            arrObjA.push(objA);
        
            return arrObjA;
          };
        
          var constroiArrayB_A0_Obj0 = function() {
        
            var arrObjB = [];
            var objB;
        
            objB = Classe_de_B.create({});
            objB.nome = 'Roda';
            objB.estado = 'Perfeito';
            
            arrObjB.push(objB);
            
            objB = Classe_de_B.create({});
            objB.nome = 'Câmbio';
            objB.estado = 'Usado';
            
            arrObjB.push(objB);
            
            objB = Classe_de_B.create({});
            objB.nome = 'Banco';
            objB.estado = 'Rasgado';
            
            arrObjB.push(objB);
            
            return arrObjB;
          
          };
        
          var constroiArrayB_A1_Obj0 = function() {
        
            var arrObjB = [];
            var objB;
        
            objB = Classe_de_B.create({});
            objB.nome = 'Carcaça';
            objB.estado = 'Suja';
            
            arrObjB.push(objB);
            
            objB = Classe_de_B.create({});
            objB.nome = 'Câmbio';
            objB.estado = 'Novinho';
            
            arrObjB.push(objB);
            
            return arrObjB;
          
          };
          
          var constroiArrayA_Obj1 = function() {
            var arrObjA = [];
            var objA;
        
            objA = Classe_de_A.create({});
            objA.nome = 'Fusca';
            objA.b = constroiArrayB_A0_Obj1();
            objA.cor = 'Amarelo';
        
            arrObjA.push(objA);
        
            objA = Classe_de_A.create({});
            objA.nome = 'Fusca';
            objA.b = constroiArrayB_A1_Obj1();
            objA.cor = 'Amarelo';
        
            arrObjA.push(objA);
        
            return arrObjA;
          };
        
          var constroiArrayB_A0_Obj1 = function() {
        
            var arrObjB = [];
            var objB;
        
        
            objB = Classe_de_B.create({});
            objB.nome = 'Pedais';
            objB.estado = 'Detonados';
            
            arrObjB.push(objB);
            
            return arrObjB;
          
          };
        
          var constroiArrayB_A1_Obj1 = function() {
        
            var arrObjB = [];
            var objB;
        
            objB = Classe_de_B.create({});
            objB.nome = 'Rádio';
            objB.estado = 'Só FM';
            
            arrObjB.push(objB);
            
            return arrObjB;
          
          };
        
          var constroiArrayA_Obj2 = function() {
            var arrObjA = [];
            var objA;
        
            objA = Classe_de_A.create({});
            objA.nome = 'Fox';
            objA.b = constroiArrayB_A0_Obj2();
            objA.cor = 'Rosa';
        
            arrObjA.push(objA);
        
            objA = Classe_de_A.create({});
            objA.nome = 'Ka';
            objA.b = constroiArrayB_A1_Obj2();
            objA.cor = 'Vermelho';
        
            arrObjA.push(objA);
        
            return arrObjA;
          };
        
          var constroiArrayB_A0_Obj2 = function() {
        
            return null;
          
          };
        
          var constroiArrayB_A1_Obj2 = function() {
        
            var arrObjB = [];
            var objB;
        
            objB = Classe_de_B.create({});
            objB.nome = 'Retrovisores';
            objB.estado = 'Rachados';
            
            arrObjB.push(objB);
            
            return arrObjB;
          
          };
        
          var constroiArrayA_Obj3 = function() {
            return null;
          };
        
          var constroiArrayA_Obj4 = function() {
            var arrObjA = [];
            var objA;
        
            objA = Classe_de_A.create({});
            objA.nome = 'Chevette';
            objA.b = constroiArrayB_A0_Obj4();
            objA.cor = 'Verde';
        
            arrObjA.push(objA);
        
            objA = Classe_de_A.create({});
            objA.nome = 'Uno';
            objA.b = constroiArrayB_A1_Obj4();
            objA.cor = 'Roxo';
        
            arrObjA.push(objA);
        
            objA = Classe_de_A.create({});
            objA.nome = 'Gol';
            objA.b = constroiArrayB_A2_Obj4();
            objA.cor = 'Bege';
        
            arrObjA.push(objA);
        
            return arrObjA;
          };
        
          var constroiArrayB_A0_Obj4 = function() {
        
            var arrObjB = [];
            var objB;
        
        
            objB = Classe_de_B.create({});
            objB.nome = 'Portas';
            objB.estado = 'Soltas';
            
            arrObjB.push(objB);
            
            return arrObjB;
          
          };
        
          var constroiArrayB_A1_Obj4 = function() {
        
            var arrObjB = [];
            var objB;
        
            objB = Classe_de_B.create({});
            objB.nome = 'Radiador';
            objB.estado = 'Furado';
            
            arrObjB.push(objB);
            
            return arrObjB;
          
          };
        
          var constroiArrayB_A2_Obj4 = function() {
        
            var arrObjB = [];
            var objB;
        
            objB = Classe_de_B.create({});
            objB.nome = 'Motor';
            objB.estado = 'Queimado';
            
            arrObjB.push(objB);
            
            return arrObjB;
          
          };
        
        
          arrObj.push(ClasseDoObj.create({}));
          arrObj[0].nome = 'Renato';
          arrObj[0].a = constroiArrayA_Obj0();
          arrObj[0].sobrenome = 'Batista';
        
          arrObj.push(ClasseDoObj.create({}));
          arrObj[1].nome = 'Juruna';
          arrObj[1].a = constroiArrayA_Obj1();
          arrObj[1].sobrenome = 'Ianomami';
        
          arrObj.push(ClasseDoObj.create({}));
          arrObj[2].nome = 'Guacira';
          arrObj[2].a = constroiArrayA_Obj2();
          arrObj[2].sobrenome = 'Salazar';
        
          arrObj.push(ClasseDoObj.create({}));
          arrObj[3].nome = 'Jerico';
          arrObj[3].a = constroiArrayA_Obj3();
          arrObj[3].sobrenome = 'Nunes';
        
          arrObj.push(ClasseDoObj.create({}));
          arrObj[4].nome = 'Lilica';
          arrObj[4].a = constroiArrayA_Obj4();
          arrObj[4].sobrenome = 'Vieira';
        
          var arrTitulosColsAExibir = [];
        
          arrTitulosColsAExibir.push({path: "nome", titulo: "Nome"});
          arrTitulosColsAExibir.push({path: "a:nome", titulo: "Nome do A"});
          arrTitulosColsAExibir.push({path: "a:b:nome", titulo: "Nome do B"});
          arrTitulosColsAExibir.push({path: "a:b:estado", titulo: "Estado do B"});
          arrTitulosColsAExibir.push({path: "a:cor", titulo: "Cor do A"});
          arrTitulosColsAExibir.push({path: "sobrenome", titulo: "Sobrenome"});
        
          var retornado = Util.montaCSV(Model_Classe.create({}), arrObj, arrTitulosColsAExibir);
        
          console.log(retornado.csv);
        */
      }

    },

    idsDosContratos: function idsDosContratos() {
      return this.get('resultadoPesquisa') ? this.get('resultadoPesquisa').map(function (contrato) {
        return contrato.id;
      }).toString() : [];
    },

    getCategoriaProcessoEConvenio: function getCategoriaProcessoEConvenio(contrato) {
      if (contrato.categoriaProcesso == null) {
        return contrato.convenio ? '(Convênio)' : '';
      }
      return contrato.convenio ? contrato.categoriaProcesso.nome + ' (Convênio)' : contrato.categoriaProcesso.nome;
    },

    descricaoRelatorios: (function () {
      var _this4 = this;

      return [{
        textoMenu: 'Relação de Contratos',
        servico: 'relatorio/relacaoDeContratos',
        nomeArquivo: 'contrato_relacao_de_contratos',
        nomeParaID: 'relacao-contratos',
        metodo: 'post',
        dados: function dados() {
          return _this4.criteriosPesquisaBackup;
        },
        testaHabilitado: function testaHabilitado() {
          return !_this4.get('mostraMensagemPesquisaSemResultado') && _this4.get('mostraTabelaResultadoPesquisa');
        },
        controlePermissao: true
      }, {
        textoMenu: 'Relação de Reajustes de Contratos',
        servico: 'relatorio/relacaoDeReajustesDeContratos/' + this.idsDosContratos(),
        nomeArquivo: 'contrato_reajuste_de_contratos',
        nomeParaID: 'relacao-reajustes-contratos',
        testaHabilitado: function testaHabilitado() {
          return !_this4.get('mostraMensagemPesquisaSemResultado') && _this4.get('mostraTabelaResultadoPesquisa');
        },
        controlePermissao: true
      }];
    }).property('resultadoPesquisa', 'mostraMensagemPesquisaSemResultado')

  });

});