define('webapp/pods/aquisicao/compra/item/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin', 'webapp/pods/aquisicao/item/model', 'webapp/pods/aquisicao/constantes-aquisicao', 'webapp/utils/util'], function (exports, Ember, CrudMixin, ItemAquisicao, Constantes, Util) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {

    queryParams: ['tipoItem'],
    tipoItem: null,
    mostraCamposItemAquisicao: false,

    needs: ['aquisicao', 'aquisicao/compra'],

    breadCrumbConfig: {
      fnNomeApresentacao: 'fnNomeApresentacao'
    },

    fnNomeApresentacao: function fnNomeApresentacao() {
      var descricaoItem = 'Item Compra ' + (this.model.version ? 'Nº ' + this.model.numeroApresentacao : '(Novo)');
      var tipoItem = '';
      switch (this.model.motivoCriacao) {
        case 'ORIGINAL':
          tipoItem = 'Original';break;
        case 'ACRESCIMO_QUANTITATIVO':
          tipoItem = 'Acréscimo Quantitativo';break;
        case 'ACRESCIMO_QUALITATIVO':
          tipoItem = 'Acréscimo Qualitativo';break;
        case 'SUPRESSAO':
          tipoItem = 'Supressão';break;
      }
      return descricaoItem + ' - ' + tipoItem;
    },

    crudConfig: {
      criar: {
        urlFn: function urlFn() {
          return 'compra/' + this.get("controllers.aquisicao/compra").model.id + '/itemCompra';
        },
        okFn: function okFn(opcao) {

          this.get('controllers.aquisicao').send('ler');
          this.get('controllers.aquisicao/compra').send('ler');
          if (opcao === 'CRIAR_E_PROXIMO') {
            //ação "borbulhará" para a rota
            this.send('refreshRota');
            return;
          }
          this.transitionToRoute('aquisicao/compra');
        }
      },
      atualizar: {
        okFn: function okFn() {
          this.get('controllers.aquisicao').send('ler');
          this.get('controllers.aquisicao/compra').send('ler');
          this.transitionToRoute('aquisicao/compra');
        }
      },
      excluir: {
        okFn: function okFn() {
          this.get('controllers.aquisicao').send('ler');
          this.get('controllers.aquisicao/compra').send('ler');
        }
      }
    },

    ehItemNovo: (function () {
      return !this.model.version;
    }).property('model.version'),

    //=========================================================================================================
    //================================= APENAS PARA APRESENTAR NO TEMPLATE ====================================

    configuraConformeMotivoCriacao: (function () {
      if (this.get('ehItemNovo') && this.model.motivoCriacao === 'ACRESCIMO_QUALITATIVO') {
        this.set('mostraCamposItemAquisicao', true);
      } else {
        this.set('mostraCamposItemAquisicao', false);
      }
    }).observes('model.motivoCriacao'),

    tituloTela: (function () {
      switch (this.model.motivoCriacao) {
        case 'ORIGINAL':
          return 'Item Compra';
        case 'ACRESCIMO_QUANTITATIVO':
          return 'Item Compra (ACRÉSCIMO QUANTITATIVO)';
        case 'ACRESCIMO_QUALITATIVO':
          return 'Item Compra (ACRÉSCIMO QUALITATIVO)';
        case 'SUPRESSAO':
          return 'Item Compra (SUPRESSÃO)';
      }
    }).property('model.motivoCriacao'),

    permitidoEscolherItemAquisicao: (function () {
      return !this.get('proibidoEscolherItemAquisicao');
    }).property('proibidoEscolherItemAquisicao'),

    proibidoEscolherItemAquisicao: (function () {
      return !this.get('ehItemNovo') || this.model.motivoCriacao === 'ACRESCIMO_QUALITATIVO';
    }).property('ehItemNovo', 'model.motivoCriacao'),

    proibidoAlterarDadosItemAquisicao: (function () {
      return !this.get('ehItemNovo') || this.model.motivoCriacao !== 'ACRESCIMO_QUALITATIVO';
    }).property('ehItemNovo', 'model.motivoCriacao'),

    proibidoAlterarValorUnitarioItemCompra: (function () {
      return this.model.motivoCriacao === 'ACRESCIMO_QUANTITATIVO';
    }).property('model.motivoCriacao'),

    getNomeLegivelMotivoCriacaoItem: function getNomeLegivelMotivoCriacaoItem(item) {
      var motivoCriacao = this.motivosCriacaoItens.find(function (motivo) {
        return item.motivoCriacao === motivo.id;
      });
      return motivoCriacao.nome;
    },

    motivosCriacaoItens: Constantes['default'].motivosCriacaoItens,

    textoItemComprar: (function () {
      if (this.get('ehOriginal')) {
        return "Item a Comprar";
      } else if (this.get('ehAcrescimo')) {
        return "Item a Acrescer";
      } else if (this.get('ehSupressao')) {
        return "Item a Suprimir";
      } else {
        return "";
      }
    }).property('ehOriginal', 'ehAcrescimo', 'ehSupressao'),

    ehOriginal: (function () {
      return this.model.motivoCriacao === 'ORIGINAL';
    }).property('model.motivoCriacao'),

    ehAcrescimo: (function () {
      return this.model.motivoCriacao === 'ACRESCIMO_QUANTITATIVO' || this.model.motivoCriacao === 'ACRESCIMO_QUALITATIVO';
    }).property('model.motivoCriacao'),

    ehSupressao: (function () {
      return this.model.motivoCriacao === 'SUPRESSAO';
    }).property('model.motivoCriacao'),

    tiposMarcoInicial: [{ id: 'ACEITE_EMPENHO', nome: 'Aceite de Empenho' }, { id: 'RECEBIMENTO_PEDIDO_OU_OS', nome: 'Recebimento de Pedido/OS' }, { id: 'DATA_ESPECIFICA', nome: 'Data Específica' }, { id: 'EVENTO_ESPECIFICO', nome: 'Evento Específico' }],

    marcoInicialEventoEspecifico: (function () {
      return this.model.execucao.marcoInicioPrazo === 'EVENTO_ESPECIFICO';
    }).property('model.execucao.marcoInicioPrazo'),

    //=============================== FIM - APENAS PARA APRESENTAR NO TEMPLATE ================================
    //=========================================================================================================

    //=========================================================================================================
    //====================================== OPERAÇÕES SOBRE O MODELO =========================================

    //Durante a criação do item de compra (ehItemNovo), sempre que mudar o itemAquisicao,
    //sugerir seu valor unitário como valor unitário da compra.
    sugerirValorUnitario: (function () {
      if (!this.get('ehItemNovo')) {
        return;
      }
      this.set('model.valorUnitario', this.get('model.itemAquisicao.valorUnitario'));
    }).observes('model.itemAquisicao'),

    sugerirQuantidade: (function () {
      if (!this.get('ehItemNovo')) {
        return;
      }
      this.set('model.quantidade', this.get('model.itemAquisicao.quantidadeDisponivelCompra'));
    }).observes('model.itemAquisicao'),

    sugerirDataAtualParaItemCompra: (function () {
      if (this.get('ehItemNovo')) {
        this.set('model.data', new Date());
      }
    }).observes('model'),

    aquisicaoDivulgadaNoPncp: (function () {
      if (this.get('controllers.aquisicao.model.pncpano') && this.get('controllers.aquisicao.model.pncpseq')) {
        return true;
      }
      return false;
    }).property('controllers.aquisicao.model'),

    configurarItemNovoConformeTipoItem: (function () {
      if (!this.model || !this.get('ehItemNovo')) {
        return;
      }

      switch (this.tipoItem) {
        case 'ORIGINAL':
          this.set('model.motivoCriacao', 'ORIGINAL');break;
        case 'ACRESCIMO':
          this.set('model.motivoCriacao', 'ACRESCIMO_QUANTITATIVO');break;
        case 'SUPRESSAO':
          this.set('model.motivoCriacao', 'SUPRESSAO');break;
      }
    }).observes('tipoItem', 'model'),

    //limpa o item aquisição caso o valor anteriormente selecionado não faça
    //mais parte da lista dos elegíveis
    limpaItemAquisicao: (function () {
      var _this = this;

      if (!this.get('ehItemNovo')) {
        return;
      }

      //Se não há elegíveis, limpa o item atual
      if (!this.get('itensAquisicaoElegiveis') || !this.get('itensAquisicaoElegiveis').length) {
        this.set('model.itemAquisicao', ItemAquisicao['default'].create({}));
        return;
      }

      var itemAquisicaoEstaEntreElegiveis = this.get('itensAquisicaoElegiveis').find(function (item) {
        return item.id === _this.model.itemAquisicao.id;
      });

      if (!itemAquisicaoEstaEntreElegiveis) {
        this.set('model.itemAquisicao', ItemAquisicao['default'].create({}));
      }
    }).observes('itensAquisicaoElegiveis'),

    limpaDataOuDescricaoEventoInicioPrazo: (function () {
      if (this.model.execucao.marcoInicioPrazo === 'EVENTO_ESPECIFICO') {
        this.set('model.execucao.dataInicioPrazo', undefined);
      } else {
        this.set('model.execucao.eventoInicioPrazo', undefined);
      }
    }).observes('model.execucao.marcoInicioPrazo'),

    //=================================== FIM - OPERAÇÕES SOBRE O MODELO ======================================
    //=========================================================================================================

    //=========================================================================================================
    //=================================== ITENS ELEGÍVEIS PARA A COMPRA ========================================

    /*ORIGINAL: se for referenciada alguma ata na compra, apenas os itens de aquisição da ata são elegíveis;
                caso contrário, são elegíveis todos os itens originais adjudicados na aquisição para o
                fornecedor indicado na compra;
      SUPRESSAO, ACRESCIMO_QUANTITATIVO: elegíveis os itens de aquisição utilizados em algum itemCompra
                                         original ou de acréscimo qualitativo;
      ACRESCIMO_QUALITATIVO: nenhum item elegível;*/
    itensAquisicaoElegiveis: (function () {
      if (!this.get('controllers.aquisicao.model.itens')) {
        return [];
      }

      if (this.model.motivoCriacao === 'ACRESCIMO_QUALITATIVO') {
        return [];
      }

      if (this.model.motivoCriacao === 'SUPRESSAO' || this.model.motivoCriacao === 'ACRESCIMO_QUANTITATIVO') {
        return this._itensAquisicaoDeItensCompra();
      }

      //caso motivoCriacao === 'ORIGINAL'
      //caso haja ata referenciada
      if (this.get('controllers.aquisicao/compra').model.idAta) {
        return this._itensAquisicaoDeItensAta();
      }

      //caso não haja ata referenciada
      return this.itensOriginaisAdjudicadosFornecedorCompra();
    }).property('model.motivoCriacao', 'controllers.aquisicao.model', '@controllers.aquisicao.model.itens'),

    _itensAquisicaoDeItensCompra: function _itensAquisicaoDeItensCompra() {
      var itensCompra = this.get('controllers.aquisicao/compra').model.itens;
      var itensAquisicao = itensCompra.map(function (itemCompra) {
        return itemCompra.itemAquisicao;
      });
      var itensAquisicaoSemDuplicatas = this._removeItensAquisicaoDuplicados(itensAquisicao);
      return Util['default'].ordenaItensPorID(itensAquisicaoSemDuplicatas);
    },

    _removeItensAquisicaoDuplicados: function _removeItensAquisicaoDuplicados(listaItens) {
      function localizaPorID(itemALocalizar, lista) {
        return lista.find(function (itemLista) {
          return itemALocalizar.id === itemLista.id;
        });
      }

      return listaItens.reduce(function (listaUnicos, item) {
        if (!localizaPorID(item, listaUnicos)) {
          listaUnicos.pushObject(item);
        }
        return listaUnicos;
      }, []);
    },

    //busca itens de aquisicao da ata referenciada pela compra
    _itensAquisicaoDeItensAta: function _itensAquisicaoDeItensAta() {
      var _this2 = this;

      var ataDaCompra = this.get('controllers.aquisicao').model.atas.filter(function (ata) {
        return ata.id === _this2.get('controllers.aquisicao/compra').model.idAta;
      }, this)[0];

      var itensAquisicaoAta = ataDaCompra.itens.map(function (itemAta) {
        return itemAta.itemAquisicao;
      });

      return Util['default'].ordenaItensPorID(itensAquisicaoAta);
    },

    itensOriginaisAdjudicadosFornecedorCompra: function itensOriginaisAdjudicadosFornecedorCompra() {
      var fornecedorCompra = this.get('controllers.aquisicao/compra').model.fornecedor;
      var itensAquisicao = this.get('controllers.aquisicao').model.itens;

      var itensOriginaisAdjudicadosDoFornecedor = itensAquisicao.filter(function (item) {
        return item.adjudicado && item.adjudicado.id === fornecedorCompra.id && (item.motivoCriacao === 'ORIGINAL' || item.motivoCriacao === 'COTA' || item.motivoCriacao === 'PARCELA_PRINCIPAL_COTA');
      });

      return Util['default'].ordenaItensPorID(itensOriginaisAdjudicadosDoFornecedor);
    }

  });
  //=============================== FIM - ITENS ELEGÍVEIS PARA A COMPRA  ====================================
  //=========================================================================================================

});