define('webapp/pods/publico/licitacoes/controller', ['exports', 'ember', 'webapp/pods/publico/constantes-transparencia', 'webapp/pods/transparencia/documento/constantes-documento', 'webapp/defs'], function (exports, Ember, Constantes, ConstantesDocumentos, Defs) {

    'use strict';

    /* global _ */

    exports['default'] = Ember['default'].ArrayController.extend({
        queryParams: ['ano', 'modalidade', 'status', 'objeto'],

        modalidades: Constantes['default'].modalidadesDeLicitacao,
        opcoesStatus: Constantes['default'].statusLicitacao,
        retroagir: 5,

        anos: (function () {
            return Constantes['default'].opcoesAnos(this.retroagir);
        }).property('retroagir'),

        getUrlDownload: function getUrlDownload(documento) {
            return Defs['default'].enderecoServer + documento.urlDownload;
        },

        tiposDocumentos: function tiposDocumentos(item) {
            var tipos = ConstantesDocumentos['default'].tipos;
            var retorno = [];
            tipos.forEach(function (element) {
                if (item.documentos && item.documentos.filter(function (doc) {
                    return doc.tipo == element.id;
                }).length > 0) {
                    retorno.push(element);
                }
            });

            return retorno;
        },

        formataValorDate: function formataValorDate(date) {
            if (!date) {
                return "";
            }
            var opcoesFormatoData = { day: 'numeric', month: 'numeric', year: 'numeric' };
            if (date.getHours() || date.getMinutes() || date.getSeconds()) {
                opcoesFormatoData['hour'] = '2-digit';
                opcoesFormatoData['minute'] = '2-digit';
            }
            return date.toLocaleString('pt-BR', opcoesFormatoData);
        },

        getNomeLegivelModalidade: function getNomeLegivelModalidade(item) {
            var modalidadeDaAquisicao = this.modalidades.find(function (modalidade) {
                return item.modalidade === modalidade.id;
            });
            return modalidadeDaAquisicao ? modalidadeDaAquisicao.nome : item.modalidade;
        },

        getDescricaoDocumento: function getDescricaoDocumento(item) {
            if (item.descricao) {
                return item.descricao;
            }

            for (var ind = 0; ind < ConstantesDocumentos['default'].tipos.length; ind++) {
                if (item.tipo == ConstantesDocumentos['default'].tipos[ind].id) return ConstantesDocumentos['default'].tipos[ind].nome;
            }

            return item.tipo;
        },

        converteStatus: function converteStatus(status) {
            for (var ind = 0; ind < this.opcoesStatus.length; ind++) {
                if (status == this.opcoesStatus[ind].id) return this.opcoesStatus[ind].nome;
            }
        },

        converteFornecedores: function converteFornecedores(fornecedores) {
            if (fornecedores != null) {
                var arrFornec = fornecedores.split(", ");
                if (arrFornec.length == 1 && _.isEmpty(arrFornec[0].trim())) {
                    arrFornec = ["-"];
                }
                return arrFornec;
            }
            return ["-"];
        },

        mostrarObservacaoTipoDocumento: function mostrarObservacaoTipoDocumento(aquisicao) {
            // comparação feita para mostrar aviso quando aquisição não possui Estudo Técnico Preliminar
            var aquisicaoPossuiETP = aquisicao.documentos.find(function (documento) {
                if (documento.pncpcodigoTipoDoc == 7) {
                    return true;
                }
                if (documento.descricao == null) {
                    return false;
                }
                // remove acentos e espaços em branco e transforma em minúsculas
                var descricaoNormalizada = documento.descricao.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/\s+/g, '').toLowerCase();
                var regexETP = /\betp\b/; // \b garante que "etp" seja uma palavra isolada (bounded)

                // Verifica se a descrição contém "ETP" ou "Estudo Técnico Preliminar" ou "Estudos Técnicos Preliminares"
                if (regexETP.test(descricaoNormalizada) || descricaoNormalizada.includes('estudotecnicopreliminar') || descricaoNormalizada.includes('estudostecnicospreliminares')) {
                    return true;
                }
                return false;
            });
            return !aquisicaoPossuiETP;
        },

        actions: {
            listar: function listar() {
                var _this = this;

                var criteriosPesquisa = this.getProperties('ano', 'modalidade', 'status', 'objeto');

                this.get('comunicador').servico('post', 'transparencia/licitacoes', criteriosPesquisa, this).then(function (resultado) {
                    _this.set('model', resultado);
                });
            },

            limparPesquisa: function limparPesquisa() {
                this.limparDadosPesquisa();
                this.set('model', []);
            }

        }
    });

});