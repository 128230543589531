define('webapp/pods/aquisicao/compra/empenho/model', ['exports', 'ember', 'webapp/models/categoria-processo'], function (exports, Ember, CategoriaProcesso) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,
		numeroFisico: null,
		numeroApresentacao: null,
		prazoEfetivo: '',
		prazoLimite: '',
		aceite: '', //data
		entregaObjeto: '', //data
		diferenca: null, //entre prazoLimite e (prazoEfetivo ou entregaObjeto ou aceite ??),
		receita: '',
		informacaoComplementar: '',
		valorInicial: '',
		numeroParcelas: '',
		valorParcela: '',
		valorGlobal: '',
		valorAcumulado: '',
		dataAssinatura: '',
		dataVigenciaInicio: '',
		dataVigenciaFim: '',
		documentos: [],
		pncpseq: null,
		pncpano: null,
		urlPNCP: '',
		categoriaProcesso: CategoriaProcesso['default'].create({}),
		pendenteEnvioAoPNCP: null,

		findUrlFn: function findUrlFn(id) {
			return 'compra/empenho/' + id.toString();
		}
	});

});