define('webapp/pods/pesquisa-contratos/model', ['exports', 'ember', 'webapp/pods/aquisicao/contrato/identificacao/descricao/indice-reajuste/model', 'webapp/models/categoria-processo'], function (exports, Ember, IndiceReajuste, categoriaProcesso) {

  'use strict';

  exports['default'] = Ember['default'].Object.extend({

    //Descrição contrato
    numero: null,
    ano: null,
    objeto: '',
    contratoModalidade: null,
    unidadeRequisitante: null,
    fornecedor: null,
    servidorFiscal: null,
    servidorGestor: null,
    categoriaProcesso: null,
    convenio: null,

    //Assinados no período
    dataMinimaAssinatura: null,
    dataMaximaAssinatura: null,
    considerarAssinaturaAditivo: true, //true ou false
    considerarAssinaturaApostilamento: true, //true ou false
    considerarAssinaturaContrato: true, //true ou false

    //Publicados no período
    dataMinimaPublicacao: null,
    dataMaximaPublicacao: null,
    considerarPublicacaoContrato: true, //true ou false
    considerarPublicacaoAditivo: true, //true ou false

    //Vigência/Execução
    statusVigenciaExecucao: null, //'CONTRATO_VENCIDO', 'CONTRATO_VIGENTE' ou 'CONTRATO_EM_EXECUCAO'
    considerarAditivosSemAssinaturaParaCalcularVigenciaOuExecucao: false,

    //Garantia em contratos vigentes
    statusGarantiaMinimaExigida: null, //'GARANTIA_MINIMA_EXIGIDA_CADASTRADA' ou
    //'GARANTIA_MINIMA_EXIGIDA_SATISFEITA' ou
    //'GARANTIA_MINIMA_EXIGIDA_NAO_SATISFEITA'

    //Encerrados no período
    dataMinimaEncerramento: null,
    dataMaximaEncerramento: null,

    //Reajuste
    indiceReajuste: IndiceReajuste['default'].create({}),
    mesReajuste: null,
    considerarApenasContratosSemReajuste: false,

    inicializar: (function () {
      this.considerarAssinaturaAditivo = true; //true ou false
      this.considerarAssinaturaApostilamento = true; //true ou false
      this.considerarAssinaturaContrato = true; //true ou false

      this.considerarPublicacaoContrato = true; //true ou false
      this.considerarPublicacaoAditivo = true; //true ou false

      this.considerarAditivosSemAssinaturaParaCalcularVigenciaOuExecucao = false;

      this.considerarApenasContratosSemReajuste = false;
    }).on('init')

  });

});