define('webapp/pods/aquisicao/compra/item/model', ['exports', 'ember', 'webapp/pods/aquisicao/item/model', 'webapp/models/execucao'], function (exports, Ember, ItemAquisicao, Execucao) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,
		numero: null,
		motivoCriacao: '', //ORIGINAL, ACRESCIMO, SUPRESSAO
		data: '', //IMPORTANTE PARA OS ACRÉSCIMOS - NOS OUTROS CASOS DEVE SER A MESMA DA COMPRA
		quantidade: null,
		valorUnitario: null,
		valorTotal: null,
		itemAquisicao: ItemAquisicao['default'].create({}),
		especificacao: '',
		naturezaItem: null,
		orcamentoSigiloso: null,

		execucao: Execucao['default'].create({}),

		inicializar: (function () {
			this.set('id', null);
			this.set('numero', null);
			this.set('motivoCriacao', '');
			this.set('quantidade', null);
			this.set('valorUnitario', null);
			this.set('valorTotal', null);
			this.set('itemAquisicao', ItemAquisicao['default'].create({}));
			this.set('especificacao', '');
			this.set('execucao', Execucao['default'].create({}));
			this.set('naturezaItem', null);
			this.set('orcamentoSigiloso', null);
		}).on('init'),

		findUrlFn: function findUrlFn(id) {
			return "compra/itemCompra/" + id.toString();
		}
	});

});