define('webapp/components/link-nova-janela', ['exports', 'ember', 'webapp/mixins/ajusta-id-interno-mixin'], function (exports, Ember, AjustaIdInternoMixin) {

  'use strict';

  // ATENÇÃO!!! Componente feito para ser usado dentro do componente tabela-form-leve. Estão fortemente acoplados.
  // O texto do link é definido pelo atributo "metodoApresentacao" da coluna do tabela-form-leve que
  // contiver este componente e a url a qual o clique neste link chamará é definida na ação "cliqueURL"
  // que está definida no módulo tabela-form-leve.js.

  //Exemplo de uso:
  /*    {{link-nova-janela
          id=<id do botão>
          desabilitar=<true | false (default) - opção para manter o botão desativado>
          registro=objeto que representa uma linha da tabela-form-leve
          coluna=objeto que representa uma coluna da tabela-form-leve
          controllerReferencia=controllerDoContexto da tabela-form-leve
          labelAcessivel=<texto para ser lido por leitores automáticos de interface
                          para acessibilidade por pessoas com deficiência visual.
                          Se não for definido, será lido o texto apresentado pelo botão>
          parteVariavelLabelAcessivel=<texto a ser concatenado ao final daquele
                                       definido no parâmetro labelAcessivel (útil
                                       para botões usados dentro de algum {{#each}})>
          classe=<classe CSS a ser aplicada ao botão - sobrescreve
                  as definições-padrão (como tipo principal ou secundário)>
          classesCSSAdicionais=<classes CSS a serem adicionadas às definições-padrão
                                ou ao que for definido no parâmetro "classe">
          estiloCSS=<Estilo CSS para ser aplicado inline pelo atributo "style">
        }}
  */
  exports['default'] = Ember['default'].Component.extend(AjustaIdInternoMixin['default'], {
    exibirTexto: true,
    tagName: 'span',
    tipoInterno: 'link-interno',
    acao: "cliqueURL",
    classe: '',
    classesCSSAdicionais: '',
    estiloCSS: '',
    ativarComEnter: false,
    desabilitar: false,
    labelAcessivel: '',
    registro: null,
    coluna: null,
    controllerReferencia: null,
    funcaoEscolhaCorCelula: null,

    _defineCSS: (function () {
      var classesAAplicar = '';

      if (this.classe) {
        classesAAplicar = this.classe;
      }

      classesAAplicar += ' ' + this.classesCSSAdicionais;

      this.set('classe', classesAAplicar);
    }).on('willInsertElement'),

    textoAcessivel: (function () {
      var textoAcessivelFixo = this.labelAcessivel ? this.labelAcessivel : this.get('texto');
      return textoAcessivelFixo + ' ' + (this.parteVariavelLabelAcessivel ? this.parteVariavelLabelAcessivel : '') + ' link que abre em nova janela';
    }).property('texto', 'labelAcessivel'),

    _verificaPermissao: (function () {
      var botaoPermitido = this._super();
      var projetadoParaAtivarComEnter = this.get('ativarComEnter');
      this.set('ativarComEnter', botaoPermitido && projetadoParaAtivarComEnter);
    }).on('willInsertElement'),

    estiloCSSaplicado: (function () {
      return this.estiloCSS;
    }).property('estiloCSS'),

    texto: (function () {
      return this.controllerReferencia[this.coluna.metodoApresentacao](this.registro);
    }).property(''),

    temLink: (function () {
      return this.controllerReferencia[this.coluna.metodoLink](this.registro) != "";
    }).property(''),

    actions: {
      acao: function acao(coluna, parametroClique) {
        if (this.acao) {
          this.sendAction('acao', coluna, parametroClique);
        }
      }
    }
  });

});