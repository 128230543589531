define('webapp/pods/pesquisa-contratos/template', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.12.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col-md-5");
            var el2 = dom.createTextNode("\n\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","row");
            var el3 = dom.createTextNode("\n\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col-md-4");
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col-md-4");
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col-md-4");
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","row");
            dom.setAttribute(el2,"style","margin-bottom: 1em");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col-md-4");
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col-md-8");
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","row");
            dom.setAttribute(el2,"style","margin-bottom: 1em");
            var el3 = dom.createTextNode("\n\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col-md-5");
            var el4 = dom.createTextNode("\n\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("legend");
            dom.setAttribute(el4,"style","font-weight: bold");
            var el5 = dom.createTextNode("Publicação no período");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","col-md-6");
            var el5 = dom.createTextNode("\n                ");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n        		  ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","col-md-6");
            var el5 = dom.createTextNode("\n                ");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n        		  ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n\n          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col-md-7");
            var el4 = dom.createTextNode("\n\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("legend");
            dom.setAttribute(el4,"style","font-weight: bold");
            var el5 = dom.createTextNode("Assinatura no período");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","col-md-4");
            var el5 = dom.createTextNode("\n                ");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n        		  ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","col-md-4");
            var el5 = dom.createTextNode("\n                ");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n        		  ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","col-md-4");
            var el5 = dom.createTextNode("\n                ");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n        		  ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n\n          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","row col-md-5");
            dom.setAttribute(el2,"style","margin-bottom: 1em");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("legend");
            dom.setAttribute(el3,"style","font-weight: bold");
            var el4 = dom.createTextNode("Encerrados/a encerrar no período");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col-md-7");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","row");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col-md-12");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","row");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col-md-12");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","row");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col-md-12");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","row");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col-md-12");
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("legend");
            var el5 = dom.createTextNode("Reajuste");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","col-md-4");
            var el5 = dom.createTextNode("\n                ");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n        		  ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n\n          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","centerButtons");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(element0, [1]);
            var element2 = dom.childAt(element1, [1]);
            var element3 = dom.childAt(element0, [5]);
            var element4 = dom.childAt(element3, [1]);
            var element5 = dom.childAt(element3, [3]);
            var element6 = dom.childAt(element0, [7]);
            var element7 = dom.childAt(fragment, [3]);
            var element8 = dom.childAt(element7, [1, 1]);
            var element9 = dom.childAt(element7, [3, 1]);
            var element10 = dom.childAt(element7, [5, 1]);
            var element11 = dom.childAt(element7, [7, 1]);
            var element12 = dom.childAt(fragment, [5]);
            var morph0 = dom.createMorphAt(element2,1,1);
            var morph1 = dom.createMorphAt(element2,3,3);
            var morph2 = dom.createMorphAt(dom.childAt(element1, [3]),1,1);
            var morph3 = dom.createMorphAt(dom.childAt(element1, [5]),1,1);
            var morph4 = dom.createMorphAt(dom.childAt(element0, [3, 3]),1,1);
            var morph5 = dom.createMorphAt(element4,3,3);
            var morph6 = dom.createMorphAt(element4,5,5);
            var morph7 = dom.createMorphAt(dom.childAt(element4, [7]),1,1);
            var morph8 = dom.createMorphAt(dom.childAt(element4, [9]),1,1);
            var morph9 = dom.createMorphAt(element5,3,3);
            var morph10 = dom.createMorphAt(element5,5,5);
            var morph11 = dom.createMorphAt(dom.childAt(element5, [7]),1,1);
            var morph12 = dom.createMorphAt(dom.childAt(element5, [9]),1,1);
            var morph13 = dom.createMorphAt(dom.childAt(element5, [11]),1,1);
            var morph14 = dom.createMorphAt(element6,3,3);
            var morph15 = dom.createMorphAt(element6,5,5);
            var morph16 = dom.createMorphAt(element8,1,1);
            var morph17 = dom.createMorphAt(element8,3,3);
            var morph18 = dom.createMorphAt(element8,5,5);
            var morph19 = dom.createMorphAt(element9,1,1);
            var morph20 = dom.createMorphAt(element9,3,3);
            var morph21 = dom.createMorphAt(element10,1,1);
            var morph22 = dom.createMorphAt(element10,3,3);
            var morph23 = dom.createMorphAt(element11,3,3);
            var morph24 = dom.createMorphAt(element11,5,5);
            var morph25 = dom.createMorphAt(dom.childAt(element11, [7]),1,1);
            var morph26 = dom.createMorphAt(element12,1,1);
            var morph27 = dom.createMorphAt(element12,3,3);
            inline(env, morph0, context, "tag-input", [], {"texto": "Número", "valor": get(env, context, "model.numero"), "mascara": "numeros", "dica": "Número anual do contrato", "tamanho": 6, "id": "tag||numero|aquisicao/contrato|pesquisa"});
            inline(env, morph1, context, "tag-input", [], {"texto": "Ano", "valor": get(env, context, "model.ano"), "mascara": "9999", "dica": "Ano do contrato", "tamanho": 6, "id": "tag||ano|aquisicao/contrato|pesquisa-contratos"});
            inline(env, morph2, context, "tag-select", [], {"texto": "Vigência/Execução", "obrigatorio": false, "conteudo": get(env, context, "statusPossiveisVigenciaExecucao"), "opcaoLabel": "nome", "opcaoValor": "id", "valor": get(env, context, "model.statusVigenciaExecucao"), "textopadrao": "Selecione", "permiteLimpar": true, "dica": "Situação do contrato em relação à vigência/execução, considerando a data atual.\n                      ENCERRADO: contratos que já tenham encerrado a vigência ou que tenham sido rescindidos no curso de sua vigência;\n                      VIGENTE: contratos que ainda não tenham vencido nem tenham sido rescindidos;\n                      EM EXECUÇÃO: contratos vigentes que estejam em execução.", "id": "select||statusVigenciaExecucao|aquisicao/contrato|pesquisa-contratos", "tamanho": 12});
            inline(env, morph3, context, "tag-select", [], {"texto": "Garantia Mínima", "obrigatorio": false, "conteudo": get(env, context, "statusPossiveisGarantiaMinima"), "opcaoLabel": "nome", "opcaoValor": "id", "valor": get(env, context, "model.statusGarantiaMinimaExigida"), "textopadrao": "Selecione", "permiteLimpar": true, "id": "select||statusGarantiaMinimaExigida|aquisicao/contrato|pesquisa-contratos", "dica": "Situação do contrato em relação às garantias exigidas.\n                        CADASTRADA: contratos para os quais tenha sido definido um valor de garantia mínimo exigido (satisfeito ou não);\n                        SATISFEITA: contratos para os quais haja garantias vigentes suficientes para satisfazer o valor mínimo exigido (ou não haja valor de garantia mínimo cadastrado);\n                        NÃO-SATISFEITA: contratos para os quais há valor de garantia mínimo exigido cadastrado, mas as garantias oferecidas não satisfaçam esse valor.", "tamanho": 12});
            inline(env, morph4, context, "tag-checkbox", [], {"texto": "Considerar Aditivos Pendentes", "nome": "considerarAditivosSemAssinaturaParaCalcularVigenciaOuExecucao", "atributoDestino": get(env, context, "model.considerarAditivosSemAssinaturaParaCalcularVigenciaOuExecucao"), "labelNegrito": false, "desabilitado": get(env, context, "pesquisaNaoEnvolveVigenciaExecucao"), "dica": "Considerar aditivos que tenham sido lançados, mas ainda não assinados (ou seja, sem data de assinatura cadastrada), na verificação da situação de vigência/execução.", "id": "checkbox||considerarAditivosSemAssinaturaParaCalcularVigenciaOuExecucao|aquisicao/contrato|pesquisa-contratos"});
            inline(env, morph5, context, "date-input", [], {"texto": "Início", "valor": get(env, context, "model.dataMinimaPublicacao"), "dica": "Busca por contratos com algum documento publicado A PARTIR desta data (inclusive), seja o contrato em si ou algum aditivo (conforme selecionado abaixo). Não considera contratos ou aditivos sem data de publicação preenchida.", "tamanho": 6, "id": "tag||dataMinimaPublicacao|aquisicao/contrato|pesquisa-contratos"});
            inline(env, morph6, context, "date-input", [], {"texto": "Fim", "valor": get(env, context, "model.dataMaximaPublicacao"), "dica": "Busca por contratos com algum documento publicado ATÉ esta data (inclusive), seja o contrato em si ou algum aditivo (conforme selecionado abaixo). Não considera contratos ou aditivos sem data de publicação preenchida.", "tamanho": 6, "id": "tag||dataMaximaPublicacao|aquisicao/contrato|pesquisa-contratos"});
            inline(env, morph7, context, "tag-checkbox", [], {"texto": "Contratos", "nome": "considerarPublicacaoContrato", "atributoDestino": get(env, context, "model.considerarPublicacaoContrato"), "labelNegrito": false, "desabilitado": get(env, context, "pesquisaNaoEnvolvePublicacao"), "dica": "Considerar publicação do contrato", "id": "checkbox||considerarPublicacaoContrato|aquisicao/contrato|pesquisa-contratos"});
            inline(env, morph8, context, "tag-checkbox", [], {"texto": "Aditivos", "nome": "considerarPublicacaoContrato", "atributoDestino": get(env, context, "model.considerarPublicacaoAditivo"), "labelNegrito": false, "desabilitado": get(env, context, "pesquisaNaoEnvolvePublicacao"), "dica": "Considerar publicação de aditivos", "id": "checkbox||considerarPublicacaoAditivo|aquisicao/contrato|pesquisa-contratos"});
            inline(env, morph9, context, "date-input", [], {"texto": "Início", "valor": get(env, context, "model.dataMinimaAssinatura"), "dica": "Busca por contratos com algum documento assinado A PARTIR desta data (inclusive), seja o contrato em si, algum aditivo ou apostilamento (conforme selecionado abaixo). Não considera contratos, aditivos ou apostilas sem data de assinatura preenchida.", "tamanho": 6, "id": "tag||dataMinimaAssinatura|aquisicao/contrato|pesquisa-contratos"});
            inline(env, morph10, context, "date-input", [], {"texto": "Fim", "valor": get(env, context, "model.dataMaximaAssinatura"), "dica": "Busca por contratos com algum documento assinado ATÉ esta data (inclusive), seja o contrato em si, algum aditivo ou apostilamento (conforme selecionado abaixo). Não considera contratos, aditivos ou apostilas sem data de assinatura preenchida.", "tamanho": 6, "id": "tag||dataMaximaAssinatura|aquisicao/contrato|pesquisa-contratos"});
            inline(env, morph11, context, "tag-checkbox", [], {"texto": "Contratos", "nome": "considerarAssinaturaContrato", "atributoDestino": get(env, context, "model.considerarAssinaturaContrato"), "labelNegrito": false, "desabilitado": get(env, context, "pesquisaNaoEnvolveAssinatura"), "dica": "Considerar assinatura do contrato", "id": "checkbox||considerarAssinaturaContrato|aquisicao/contrato|pesquisa-contratos"});
            inline(env, morph12, context, "tag-checkbox", [], {"texto": "Aditivos", "nome": "considerarAssinaturaAditivo", "atributoDestino": get(env, context, "model.considerarAssinaturaAditivo"), "labelNegrito": false, "desabilitado": get(env, context, "pesquisaNaoEnvolveAssinatura"), "dica": "Considerar assinatura de aditivos", "id": "checkbox||considerarAssinaturaAditivo|aquisicao/contrato|pesquisa-contratos"});
            inline(env, morph13, context, "tag-checkbox", [], {"texto": "Apostilas", "nome": "considerarAssinaturaApostilamento", "atributoDestino": get(env, context, "model.considerarAssinaturaApostilamento"), "labelNegrito": false, "desabilitado": get(env, context, "pesquisaNaoEnvolveAssinatura"), "dica": "Considerar assinatura de apostilas", "id": "checkbox||considerarAssinaturaApostilamento|aquisicao/contrato|pesquisa-contratos"});
            inline(env, morph14, context, "date-input", [], {"texto": "Início", "valor": get(env, context, "model.dataMinimaEncerramento"), "dica": "Busca por contratos cujo encerramento (fim da vigência ou rescisão) tenha ocorrido A PARTIR desta data (inclusive). Não considera aditivos não assinados.", "tamanho": 6, "id": "tag||dataMinimaEncerramento|aquisicao/contrato|pesquisa-contratos"});
            inline(env, morph15, context, "date-input", [], {"texto": "Fim", "valor": get(env, context, "model.dataMaximaEncerramento"), "dica": "Busca por contratos cujo encerramento (fim da vigência ou rescisão) tenha ocorrido ATÉ esta data (inclusive). Não considera aditivos não assinados.", "tamanho": 6, "id": "tag||dataMaximaEncerramento|aquisicao/contrato|pesquisa-contratos"});
            inline(env, morph16, context, "tag-select", [], {"texto": "Categoria do Processo", "obrigatorio": false, "conteudo": get(env, context, "categoriasProcesso"), "opcaoLabel": "nome", "valor": get(env, context, "model.categoriaProcesso"), "textopadrao": "Selecione a Categoria do Processo", "permiteLimpar": true, "dica": "Busca por contratos com uma categoria do processo específica", "id": "select||categoriaProcesso|aquisicao/contrato|pesquisa-contratos", "tamanho": 3});
            inline(env, morph17, context, "tag-select", [], {"texto": "Convênio", "obrigatorio": false, "conteudo": get(env, context, "opcoesBooleanasComDescricao"), "opcaoLabel": "descricao", "opcaoValor": "id", "valor": get(env, context, "model.convenio"), "textopadrao": "Selecione uma opção", "permiteLimpar": true, "dica": "Busca por contratos do tipo Convẽnio", "id": "select||convenio|aquisicao/contrato|pesquisa-contratos", "tamanho": 3});
            inline(env, morph18, context, "busca-fornecedor", [], {"texto": "Contratado", "atributoDestino": get(env, context, "model.fornecedor"), "labelAcessivel": "fornecedor contratado", "tamanho": 6, "dica": "Busca por contratos com algum fornecedor específico entre os contratados", "id": "tag||fornecedor|aquisicao/contrato|pesquisa-contratos"});
            inline(env, morph19, context, "tag-input", [], {"texto": "Objeto", "valor": get(env, context, "model.objeto"), "dica": "Busca por contratos que contenham um texto específico na descrição do seu objeto", "tamanho": 6, "id": "tag||objeto|aquisicao/contrato|pesquisa-contratos"});
            inline(env, morph20, context, "busca-servidor", [], {"texto": "Fiscal", "atributoDestino": get(env, context, "model.servidorFiscal"), "labelAcessivel": "servidor fiscal", "tamanho": 6, "dica": "Busca por contratos com algum servidor específico como fiscal", "id": "tag||fiscal|aquisicao/contrato|pesquisa-contratos"});
            inline(env, morph21, context, "tag-select", [], {"texto": "Unidade Requisitante", "tamanho": 6, "obrigatorio": false, "conteudo": get(env, context, "unidadesOrganizacionais"), "opcaoLabel": "nome", "valor": get(env, context, "model.unidadeRequisitante"), "textopadrao": "Selecione a unidade", "permiteLimpar": true, "dica": "Busca por contratos referentes a uma unidade requisitante específica", "id": "select||unidadeRequisitante|aquisicao/contrato|pesquisa-contratos"});
            inline(env, morph22, context, "busca-servidor", [], {"texto": "Gestor", "atributoDestino": get(env, context, "model.servidorGestor"), "labelAcessivel": "servidor gestor", "tamanho": 6, "dica": "Busca por contratos com algum servidor específico como gestor", "id": "tag||gestor|aquisicao/contrato|pesquisa-contratos"});
            inline(env, morph23, context, "tag-select", [], {"texto": "Índice", "obrigatorio": false, "conteudo": get(env, context, "indicesReajuste"), "opcaoLabel": "nome", "valor": get(env, context, "model.indiceReajuste"), "textopadrao": "Selecione o índice", "permiteLimpar": true, "id": "select||indice-reajuste|aquisicao/contrato|pesquisa-contratos", "tamanho": 6});
            inline(env, morph24, context, "tag-select", [], {"texto": "Mês Referência", "obrigatorio": false, "conteudo": get(env, context, "meses"), "opcaoLabel": "nome", "opcaoValor": "id", "valor": get(env, context, "model.mesReajuste"), "textopadrao": "Selecione o mês", "permiteLimpar": true, "id": "select||periodo-de-divulgacao|aquisicao/contrato|pesquisa-contratos", "tamanho": 6});
            inline(env, morph25, context, "tag-checkbox", [], {"texto": "Sem Reajuste", "nome": "considerarApenasContratosSemReajuste", "atributoDestino": get(env, context, "model.considerarApenasContratosSemReajuste"), "labelNegrito": false, "desabilitado": false, "dica": "Busca por contratos sem reajuste", "id": "checkbox||considerarApenasContratosSemReajuste|aquisicao/contrato|pesquisa-contratos"});
            inline(env, morph26, context, "botao-geral", [], {"tipo": "primario", "texto": "Pesquisar", "iconeUiIcon": "search", "acao": "pesquisar", "ativarComEnter": true, "controlePermissao": true, "id": "botao|pesquisar||aquisicao/contrato|pesquisa-contratos"});
            inline(env, morph27, context, "botao-geral", [], {"tipo": "primario", "texto": "Limpar", "iconeUiIcon": "eraser", "acao": "limparPesquisa", "id": "botao|limparPesquisa||aquisicao/contrato|pesquisa-contratos"});
            return fragment;
          }
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              isHTMLBars: true,
              revision: "Ember@1.12.1",
              blockParams: 0,
              cachedFragment: null,
              hasRendered: false,
              build: function build(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("\n        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","centerButtons");
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("        \n        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n\n        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              render: function render(context, env, contextualElement) {
                var dom = env.dom;
                var hooks = env.hooks, inline = hooks.inline, get = hooks.get;
                dom.detectNamespace(contextualElement);
                var fragment;
                if (env.useFragmentCache && dom.canClone) {
                  if (this.cachedFragment === null) {
                    fragment = this.build(dom);
                    if (this.hasRendered) {
                      this.cachedFragment = fragment;
                    } else {
                      this.hasRendered = true;
                    }
                  }
                  if (this.cachedFragment) {
                    fragment = dom.cloneNode(this.cachedFragment, true);
                  }
                } else {
                  fragment = this.build(dom);
                }
                var morph0 = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                var morph1 = dom.createMorphAt(fragment,3,3,contextualElement);
                inline(env, morph0, context, "botao-geral", [], {"tipo": "primario", "texto": "Download em Formato CSV", "iconeUiIcon": "download", "acao": "download", "id": "botao|download||aquisicao/contrato|pesquisa-contratos"});
                inline(env, morph1, context, "tabela-form-leve", [], {"colunas": "[{\"titulo\": \"Nº\", \"atributo\": \"numeroApresentacao\", \"tipo\": \"numero/ano\", \"largura\": \"5%\" },\n                    {\"titulo\": \"Objeto\", \"atributo\": \"objeto\", \"largura\": \"35%\" },\n                    {\"titulo\": \"Categoria do Processo\", \"metodoApresentacao\": \"getCategoriaProcessoEConvenio\", \"largura\": \"15%\" },\n                    {\"titulo\": \"Processo Administrativo\", \"atributo\": \"numeroProcessoAdministrativo\", \"largura\": \"20%\" },\n                    {\"titulo\": \"Final Vigência\", \"atributo\": \"dataFimVigenciaAtualizada\", \"largura\": \"15%\" }]", "fonte": get(env, context, "resultadoPesquisa"), "permiteAcessar": true, "permiteExcluir": true, "exibePaginacaoSuperior": true, "contexto": get(env, context, "this"), "rota": "aquisicao/contrato", "funcaoIdsRota": "getIdsRota", "controlePermissao": true, "controlePermissaoAcessar": true, "controlePermissaoExcluir": true, "id": "tabela|listar||aquisicao/contrato|pesquisa-contratos"});
                return fragment;
              }
            };
          }());
          return {
            isHTMLBars: true,
            revision: "Ember@1.12.1",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks, block = hooks.block;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, null);
              dom.insertBoundary(fragment, 0);
              block(env, morph0, context, "tag-bloco", [], {"titulo": "Contratos Existentes"}, child0, null);
              return fragment;
            }
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              isHTMLBars: true,
              revision: "Ember@1.12.1",
              blockParams: 0,
              cachedFragment: null,
              hasRendered: false,
              build: function build(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode(" Não há contratos a listar. ");
                dom.appendChild(el0, el1);
                return el0;
              },
              render: function render(context, env, contextualElement) {
                var dom = env.dom;
                dom.detectNamespace(contextualElement);
                var fragment;
                if (env.useFragmentCache && dom.canClone) {
                  if (this.cachedFragment === null) {
                    fragment = this.build(dom);
                    if (this.hasRendered) {
                      this.cachedFragment = fragment;
                    } else {
                      this.hasRendered = true;
                    }
                  }
                  if (this.cachedFragment) {
                    fragment = dom.cloneNode(this.cachedFragment, true);
                  }
                } else {
                  fragment = this.build(dom);
                }
                return fragment;
              }
            };
          }());
          return {
            isHTMLBars: true,
            revision: "Ember@1.12.1",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks, block = hooks.block;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var morph0 = dom.createMorphAt(fragment,1,1,contextualElement);
              block(env, morph0, context, "painel-msg", [], {}, child0, null);
              return fragment;
            }
          };
        }());
        return {
          isHTMLBars: true,
          revision: "Ember@1.12.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, block = hooks.block;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
            var morph1 = dom.createMorphAt(fragment,2,2,contextualElement);
            dom.insertBoundary(fragment, null);
            dom.insertBoundary(fragment, 0);
            block(env, morph0, context, "if", [get(env, context, "mostraTabelaResultadoPesquisa")], {}, child0, null);
            block(env, morph1, context, "if", [get(env, context, "mostraMensagemPesquisaSemResultado")], {}, child1, null);
            return fragment;
          }
        };
      }());
      return {
        isHTMLBars: true,
        revision: "Ember@1.12.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, inline = hooks.inline, block = hooks.block;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var morph0 = dom.createMorphAt(fragment,1,1,contextualElement);
          var morph1 = dom.createMorphAt(fragment,3,3,contextualElement);
          var morph2 = dom.createMorphAt(fragment,5,5,contextualElement);
          dom.insertBoundary(fragment, null);
          inline(env, morph0, context, "tag-relatorio", [], {"descricaoRelatorios": get(env, context, "descricaoRelatorios"), "controlePermissao": true, "id": "componente|gerar|relatorios|aquisicao/contrato|pesquisa-contrato"});
          block(env, morph1, context, "tag-bloco", [], {"titulo": "Pesquisa de Contratos", "formulario": true, "textoAlternativo": "Você não tem permissão para acessar a pesquisa de contratos", "destaqueTextoAlternativo": true, "controlePermissao": true, "id": "bloco|listar||aquisicao/contrato|pesquisa-contratos"}, child0, null);
          block(env, morph2, context, "if", [get(env, context, "mostrarResultados")], {}, child1, null);
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, block = hooks.block, content = hooks.content;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
        var morph1 = dom.createMorphAt(fragment,2,2,contextualElement);
        dom.insertBoundary(fragment, 0);
        block(env, morph0, context, "if", [get(env, context, "mostrarTemplate")], {}, child0, null);
        content(env, morph1, context, "outlet");
        return fragment;
      }
    };
  }()));

});