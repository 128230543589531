define('webapp/pods/aquisicao/documento/criar/route', ['exports', 'ember', 'webapp/pods/aquisicao/documento/model'], function (exports, Ember, Documento) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    controllerName: 'aquisicao/documento',
    setupController: function setupController(controller) {
      controller.set('model', Documento['default'].create({}));
    }

  });

});