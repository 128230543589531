define('webapp/components/tag-situacao-ata', ['exports', 'ember', 'webapp/pods/pncp/constantes-pncp-envio'], function (exports, Ember, Constantes) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({

    comunicador: Ember['default'].inject.service(),

    constantesOperacoes: Constantes['default'].operacoes,
    constantesUnidades: Constantes['default'].descricoesUnidades,

    actions: {
      enviarAgora: function enviarAgora() {
        this.get('comunicador').servico('post', 'aquisicao/ata/' + this.ata.id + '/enviaPNCP', null).then(function () {
          window.location.reload();
        });
      },
      abrirModalPendenciasDeEnvioAoPNCP: function abrirModalPendenciasDeEnvioAoPNCP() {
        this.set('mostrarModalPendenciasDeEnvioAoPNCP', true);
      }
    },

    tiposSituacao: [{ id: "VIGENTE", nome: "Vigente", codigo: 1, cor: "verde" }, { id: "NAO_VIGENTE", nome: "Não Vigente", codigo: 2, cor: "vermelho" }],
    situacaoAta: null,
    mostrarModalPendenciasDeEnvioAoPNCP: false,

    descricaoSituacao: (function () {
      var _this = this;

      if (this.tiposSituacao.find(function (tipo) {
        return tipo.id === _this.situacaoAta;
      })) {
        var situacao = this.tiposSituacao.find(function (tipo) {
          return tipo.id === _this.situacaoAta;
        }).nome;

        return situacao + this.obtemSituacaoPNCP();
      }
    }).property('ata.dataInicioVigencia', 'ata.dataVigencia', 'ata.pendenteEnvioAoPNCP'),

    obtemSituacaoPNCP: function obtemSituacaoPNCP() {
      if (this.ata.enviaPNCP) {
        if (this.ata.pendenteEnvioAoPNCP) {
          return " (Pendente de Envio ao PNCP)";
        }
        return !!this.ata.pncpseq ? " (Divulgado no PNCP)" : " (Pendente de Envio ao PNCP)";
      }

      return "";
    },

    aquisicaoDivulgadaNoPNCP: (function () {
      return this.aquisicao.pncpseq && this.aquisicao.pncpano;
    }).property('aquisicao.pncpseq', 'aquisicao.pncpano'),

    corPainel: (function () {
      var _this2 = this;

      return this.tiposSituacao.find(function (tipo) {
        return tipo.id === _this2.situacaoAta;
      }) ? this.tiposSituacao.find(function (tipo) {
        return tipo.id === _this2.situacaoAta;
      }).cor : 'verde';
    }).property('ata.dataInicioVigencia', 'ata.dataVigencia'),

    mostrarLinkPNCP: (function () {
      return this.ata.urlPNCP;
    }).property('ata.urlPNCP'),

    possuiPendenciasDeEnvio: (function () {
      return this.ata.pncpEnvioDTO;
    }).property('ata.pncpEnvioDTO'),

    calculaSituacao: (function () {
      var dataAtual = new Date();
      dataAtual.setHours(0, 0, 0, 0);
      if (this.ata.dataInicioVigencia && this.ata.dataVigencia) {
        if (this.ata.dataVigencia.getTime() < dataAtual.getTime() || this.ata.dataInicioVigencia.getTime() > dataAtual.getTime()) {
          this.situacaoAta = 'NAO_VIGENTE';
        } else {
          this.situacaoAta = 'VIGENTE';
        }
      } else {
        this.situacaoAta = 'NAO_VIGENTE';
      }
    }).on('init').observes('descricaoSituacao'),

    getDescricaoDaOperacao: function getDescricaoDaOperacao(envioPNCP) {
      // Tenta converter o tipo para uma das opções nas constantes
      var operacao = this.constantesOperacoes.find(function (operacao) {
        return operacao.id === envioPNCP.descricaoDaOperacao;
      });
      if (operacao) {
        return operacao.nome;
      }
      return envioPNCP.descricaoDaOperacao;
    },

    getDescricaoDaUnidade: function getDescricaoDaUnidade(envioPNCP) {
      // Tenta converter o tipo para uma das opções nas constantes
      var unidadeDeEnvio = this.constantesUnidades.find(function (unidade) {
        return unidade.id === envioPNCP.idUnidadeDeEnvio;
      });
      if (unidadeDeEnvio) {
        return unidadeDeEnvio.nome;
      }
      return envioPNCP.descricaoDaUnidadeDeEnvio;
    }
  });

});