define('webapp/pods/pncp/listagem/model', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		idEntidadePrincipal: null,
		nomeDaEntidadePrincipal: null,
		numeroApresentacaoEntidadePrincipal: null,
		linkRotaEntidadePrincipalNoSILC: null,
		linkEnvioAoPNCP: null,
		descricaoDaEntidadePrincipal: null,
		unidadesDeEnvioESuasOperacoes: [],
		errosEnvio: null
	});

});