define('webapp/services/comunicador', ['exports', 'ember', 'webapp/defs', 'webapp/utils/util'], function (exports, Ember, Defs, Util) {

  'use strict';

  /* global $ */

  exports['default'] = Ember['default'].Service.extend({
    usuarioLogado: Ember['default'].inject.service('usuario-logado'),

    // Cache
    cacheServicos: {
      conf: {
        tempoVidaCache: 1200000 // em milisegundos
      },

      servicos: {
        unidadeOrganizacional: {
          ultimaAtualizacao: null,
          dados: null
        },

        programaTrabalho: {
          ultimaAtualizacao: null,
          dados: null
        },

        classificacaoItem: {
          ultimaAtualizacao: null,
          dados: null
        }
      },

      _ehServicoComCache: function _ehServicoComCache(servico) {
        return Object.keys(this.servicos).contains(servico);
      },

      _podeUsarDadosCache: function _podeUsarDadosCache(servico) {
        return this._ehServicoComCache(servico) && this.servicos[servico].dados && !this._expirouTempoCache(servico);
      },

      _expirouTempoCache: function _expirouTempoCache(servico) {
        return new Date() - this.servicos[servico].ultimaAtualizacao > this.conf.tempoVidaCache;
      },

      buscaDadosCache: function buscaDadosCache(servico) {
        if (!this._podeUsarDadosCache(servico)) {
          return null;
        }

        return this.servicos[servico].dados;
      },

      substituiDadosCache: function substituiDadosCache(servico, dados) {
        if (!Object.keys(this.servicos).contains(servico)) {
          return;
        }

        this.servicos[servico].ultimaAtualizacao = new Date();
        this.servicos[servico].dados = dados;
      },

      limparDadosCache: function limparDadosCache(servico) {
        this.servicos[servico].dados = null;
      }

    },

    leParaPropriedade: function leParaPropriedade(ObjAlvo, url, propriedade, msgErro) {
      this.servico('get', url, null).then(function (resultado) {
        ObjAlvo.set(propriedade, resultado);
      }, function (resultado, status, xhr) {
        if (xhr && xhr.status !== Defs['default'].HTTP_CODIGO_SESSAO_INVALIDA) {
          Util['default'].alertaErro(msgErro ? msgErro : "Erro de leitura");
        }
      });
    },

    //NOTA: RETORNA PROMISE
    login: function login(usuario, senha) {
      return this.servico('post', 'auth/login', { 'usuario': usuario, 'senha': senha });
    },

    //NOTA: RETORNA PROMISE
    logout: function logout() {
      //this.servico('get','auth/logout', this.get('usuarioLogado').info);
      return this.servico('get', 'auth/logout');
    },

    //NOTA: RETORNA PROMISE
    //"dados" deve ser um objeto
    servico: function servico(metodo, _servico, dados) {
      var _this = this;

      var sync = arguments.length <= 3 || arguments[3] === undefined ? false : arguments[3];

      var self = this;

      var dadosEmCache = this.cacheServicos.buscaDadosCache(_servico);
      if (metodo === "get" && dadosEmCache) {
        return Ember['default'].RSVP.Promise.resolve().then(function () {
          return dadosEmCache;
        });
      }

      return Ember['default'].RSVP.Promise.resolve($.ajax({
        url: Defs['default'].enderecoServer + _servico,
        type: metodo,
        headers: { 'authToken': this.get('usuarioLogado').estaLogado() ? this.get('usuarioLogado').info.authToken : '',
          'TimeZone': this.get('usuarioLogado').estaLogado() ? JSON.stringify(new Date()).substr(24, 5) : ''
        },
        contentType: 'application/json',
        data: JSON.stringify(Util['default'].compactaObjeto(Util['default'].serializaObjeto(dados))),
        async: !sync,
        cache: false,
        dataType: "text",
        //self é o comunicador e this é a promessa do $.ajax.
        //Para isso funcionar a declaração da função error precisa estar no formato tradicional -function() {...}.
        //O outro formato preserva o this léxico, que é o comunicador. Nesse caso perde-se o acesso à promessa.
        error: function error(xhr) {
          var promessaRetornoDoAjax = this;
          self.trataErro(xhr.status, xhr.responseText, metodo, promessaRetornoDoAjax.url);
        },
        success: function success(resultado, status, xhr) {
          if (xhr.getResponseHeader('AlertaTZsDiferentes')) {
            if (_this.get('usuarioLogado').estaLogado()) {
              _this.get('usuarioLogado').acrescentaInfoTZsDifs(true);
            }
          } else {
            if (_this.get('usuarioLogado').estaLogado()) {
              _this.get('usuarioLogado').acrescentaInfoTZsDifs(false);
            }
          }
          if (xhr.getResponseHeader('mensagemAlerta')) {
            Util['default'].alertaServidor(xhr.getResponseHeader('mensagemAlerta'));
          }
        }
      })).then(function (result) {

        var resultado = result ? JSON.parse(result) : result;

        var nomeServicoGet = _servico.split('/')[0];
        if (!self.cacheServicos._ehServicoComCache(nomeServicoGet)) {
          return resultado;
        }

        if (metodo === "get") {
          self.cacheServicos.substituiDadosCache(_servico, resultado);
        } else {
          self.cacheServicos.limparDadosCache(nomeServicoGet);
          self.servico('get', nomeServicoGet);
        }

        return resultado;
      });
    },

    /////---------------------------------------------------------------------------------------------------------------
    /////---------------------------------------------------------------------------------------------------------------
    /////---------------------------------------------------------------------------------------------------------------
    /////---------------------------------------------------------------------------------------------------------------

    servicoMultipart: function servicoMultipart(servico, formData) {
      var _this2 = this;

      var self = this;

      return Ember['default'].RSVP.Promise.resolve($.ajax({
        url: Defs['default'].enderecoServer + servico,
        type: "POST",
        //enctype: 'multipart/form-data',
        enctype: 'multipart/form-data',
        headers: { 'authToken': this.get('usuarioLogado').estaLogado() ? this.get('usuarioLogado').info.authToken : '',
          'TimeZone': this.get('usuarioLogado').estaLogado() ? JSON.stringify(new Date()).substr(24, 5) : ''
        },
        processData: false,
        contentType: false,
        data: formData,
        async: true,
        cache: false,
        //self é o comunicador e this é a promessa do $.ajax.
        //Para isso funcionar a declaração da função error precisa estar no formato tradicional -function() {...}.
        //O outro formato preserva o this léxico, que é o comunicador. Nesse caso perde-se o acesso à promessa.
        error: function error(xhr) {
          var promessaRetornoDoAjax = this;
          self.trataErro(xhr.status, xhr.responseText, "POST", promessaRetornoDoAjax.url);
        },
        success: function success(resultado, status, xhr) {
          if (xhr.getResponseHeader('AlertaTZsDiferentes')) {
            if (_this2.get('usuarioLogado').estaLogado()) {
              _this2.get('usuarioLogado').acrescentaInfoTZsDifs(true);
            }
          } else {
            if (_this2.get('usuarioLogado').estaLogado()) {
              _this2.get('usuarioLogado').acrescentaInfoTZsDifs(false);
            }
          }
          if (xhr.getResponseHeader('mensagemAlerta')) {
            Util['default'].alertaServidor(xhr.getResponseHeader('mensagemAlerta'));
          }
        }
      })).then(function (result) {
        try {
          var resultado = result ? JSON.parse(result) : result;
          return resultado;
        } catch (e) {
          return result;
        }
      });
    },

    servicoBlob: function servicoBlob(metodo, servico, dados, tipo) {
      var self = this;

      return new Ember['default'].RSVP.Promise(function (resolve) {
        var xhttp = new XMLHttpRequest();
        var url = Defs['default'].enderecoServer + servico;
        xhttp.onloadend = function () {
          if (xhttp.readyState === 4) {
            if (xhttp.status === 200) {
              resolve(xhttp.response, tipo);
            } else {
              var msg = self.converteArrayBufferEmString(xhttp.response);
              self.trataErro(xhttp.status, msg, metodo, url);
            }
          }
        };

        xhttp.open(metodo, url, true);
        if (metodo !== "get") {
          xhttp.setRequestHeader("Content-Type", "application/json");
        }
        xhttp.setRequestHeader('authToken', self.get('usuarioLogado').estaLogado() ? self.get('usuarioLogado').info.authToken : '');
        xhttp.setRequestHeader('TimeZone', self.get('usuarioLogado').estaLogado() ? JSON.stringify(new Date()).substr(24, 5) : '');
        xhttp.responseType = tipo === "BAIXAR_ARQUIVO" ? "arraybuffer" : "text";
        if (metodo === "get") {
          xhttp.send();
        } else {
          xhttp.send(JSON.stringify(Util['default'].compactaObjeto(Util['default'].serializaObjeto(dados))));
        }
      }).then(function (result) {
        if (tipo === "BAIXAR_ARQUIVO") {
          return result;
        } else {
          var url = result.includes("http://") || result.includes("https://") ? result : "http://" + result;
          window.open(url, '_blank');
        }
      });
    },

    converteArrayBufferEmString: function converteArrayBufferEmString(arrayBuffer) {
      var msg = String.fromCharCode.apply(null, new Uint8Array(arrayBuffer));
      return decodeURIComponent(escape(msg));
    },

    //Provê ações genéricas para os erros de comunicação com o servidor.
    trataErro: function trataErro(codErro, msgServidor, metodo, url) {
      var applicationController = this.container.lookup('controller:application');
      var rotaAtual = this.container.lookup('route:' + applicationController.get('currentPath'));
      switch (codErro) {
        case Defs['default'].HTTP_CODIGO_ITEM_PESQUISADO_INEXISTENTE:
          Util['default'].alertaErro("Informações inexistentes.");
          break;
        case Defs['default'].HTTP_CODIGO_SERVICO_NAO_ENCONTRADO:
          Util['default'].alertaErro('<details>\n            <summary>Detalhes</summary>\n            <textarea rows="4" cols="110">\n              -URL do Serviço: \n                 ' + url + '\n              -Detalhes do erro:\n                 ' + msgServidor + '\n            </textarea>\n          </details>');
          break;
        case Defs['default'].HTTP_CODIGO_SESSAO_INVALIDA:
          Util['default'].alertaErro("Sessão inválida");
          this.get('usuarioLogado').sair();
          applicationController.transitionToRoute('login');
          break;
        case Defs['default'].HTTP_CODIGO_NAO_AUTORIZADO:
          Util['default'].alertaErro("Usuário não tem autorização para realizar esta ação");
          applicationController.transitionToRoute('application');
          break;
        case Defs['default'].HTTP_CODIGO_REQUISICAO_FORA_DO_HORARIO_PERMITIDO:
          Util['default'].alertaAviso("O acesso a este módulo só pode ser realizado entre as 6:00 e as 23:59 horas.");
          applicationController.transitionToRoute('application');
          break;
        case Defs['default'].HTTP_CODIGO_FALHA_ENVIO_PNCP:
          //let arrayMensagens = Util.separaMensAdicional(msgServidor);
          //let msg = arrayMensagens[0];
          //let msgAdic = arrayMensagens.length > 1 ? arrayMensagens[1] : "";

          var jsonMsgServidor = JSON.parse(msgServidor); // requestBody
          var jsonErroDeRetorno = JSON.parse(jsonMsgServidor.erroDeRetorno); // status, message e path
          Util['default'].alertaPNCP(jsonErroDeRetorno.message, jsonMsgServidor.requestBody);
          //        Util.alertaErro("O sistema do PNCP rejeitou os dados enviados! <br/> Mensagem: ", msg, msgAdic);
          break;
        case Defs['default'].HTTP_CODIGO_ERRO_DE_VALIDACAO_PNCP:
          Util['default'].alertaValidacoesPNCP("Erro(s) na validação dos dados para envio ao PNCP: ", msgServidor);
          break;
        case Defs['default'].HTTP_CODIGO_REQUISICAO_INVALIDA:
          Util['default'].alertaErro("", msgServidor);
          break;
        case Defs['default'].HTTP_CODIGO_ERRO_DE_CONFIGURACAO:
          Util['default'].alertaErro("", msgServidor);
          break;
        case Defs['default'].HTTP_CODIGO_ERRO_CONCORRENCIA:
          if (metodo.toLowerCase() === 'delete') {
            return;
          }
          Util['default'].alertaErro("Informações modificadas por outro usuários. Tente novamente.");
          break;
        case Defs['default'].HTTP_CODIGO_ERRO_TAMANHO_REQUISICAO:
          Util['default'].alertaErro("Envio de dados muito grande. Se está fazendo upload de um arquivo, verifique o tamanho permitido.");
          break;
        case Defs['default'].HTTP_CODIGO_ERRO_TENTATIVA_DE_GRAV_NO_BCO_COM_DIFS_TIMEZONES:
          Util['default'].alertaErro("Não foi possível realizar a operação devido a diferença de fusos horários entre o cliente e o servidor. Contate Administrador.", msgServidor);
          break;
        case Defs['default'].HTTP_CODIGO_SISTEMA_EM_MANUTENCAO:
          Util['default'].alertaErro("Sistema em manutenção. Tente acessar novamente mais tarde.");
          applicationController.transitionToRoute('manutencao');
          break;
        case Defs['default'].HTTP_CODIGO_ERRO_INTERNO_SERVIDOR:
        case Defs['default'].HTTP_CODIGO_ERRO_DADOS_SERVIDOR:
          Util['default'].alertaErro('<details>\n            <summary>Detalhes</summary>\n            <textarea rows="4" cols="48">\n              -Rota:\n              ' + applicationController.get('currentPath') + '\n              -Usuário logado:\n              ' + applicationController.get('nomeUsuarioLogado') + '\n              ' + (rotaAtual && rotaAtual.controller && rotaAtual.controller.model ? '-Modelo: \n ' + JSON.stringify(rotaAtual.controller.model) : "") + '\n              -Serviço:\n              ' + metodo + ' ' + url + '\n              -Detalhes do erro:\n              ' + msgServidor + '\n            </textarea>\n          </details>');
          break;
        default:
          Util['default'].alertaErro("Ocorreu um erro de comunicação com o servidor");
          applicationController.transitionToRoute('login');
      }
    }
  });

});