define('webapp/utils/util', ['exports', 'webapp/defs'], function (exports, Defs) {

	'use strict';

	/* global _, $ */

	exports['default'] = {

		getJSON: function getJSON(url, callback) {
			var xhttp = new XMLHttpRequest();

			xhttp.onloadend = function () {
				if (xhttp.readyState === 4) {
					if (xhttp.status === 200) {
						callback(null, xhttp.response);
					} else {
						callback(xhttp.status, xhttp.response);
					}
				}
			};
			xhttp.open("GET", url);
			xhttp.responseType = "json";
			xhttp.setRequestHeader("Accept", "application/json");
			xhttp.send();
		},

		alerta: function alerta(msg) {
			$.gritter.add({ title: '', time: 7000, text: msg });
		},

		alertaServidor: function alertaServidor(msgCliente, msgServidor) {
			this.alertaAviso(msgCliente, msgServidor);
		},

		alertaAviso: function alertaAviso(msgCliente, msgServidor) {
			this.alertaSimples(msgCliente, msgServidor, "Aviso", "warning");
		},

		alertaValidacoesPNCP: function alertaValidacoesPNCP(msgCliente, msgServidor) {
			var msg = msgCliente + " \n\t\t\t\t\t\t\t <textarea rows=\"8\" cols=\"48\" style=\"resize: none;\">" + msgServidor + "\n   \t\t\t\t\t\t </textarea>";

			var msgSemTagsHTML = msg.replace(/<[^<>]+>/g, '');

			var previousWindowKeyDown = window.onkeydown;
			swal({
				title: "Erros de Validação!",
				html: msg,
				type: "error",
				confirmButtonAriaLabel: msgSemTagsHTML + ". Pressione este botão para fechar a mensagem."
			});
			window.onkeydown = previousWindowKeyDown;
		},

		alertaPNCP: function alertaPNCP(msgPNCP, requestBody) {

			var requestBodyComTagsBR = this.trocaQuebrasDeLinhaPorTagsBR(requestBody);

			var msg = "<br/><b>O PNCP rejeitou os dados enviados!</b> <br/> <br/> \n\t\t\t\t\t\t\t <p style=\"text-align: left;\"><b>Mensagem: </b> " + msgPNCP + "<br/> <br/> \n\t\t\t\t\t\t\t <p style=\"text-align: center;\"><b>Dados Rejeitados</b></p>\n\t\t\t\t\t\t\t <br/> \n\t\t\t\t\t\t\t <textarea rows=\"8\" cols=\"48\" style=\"resize: none; width: 100%;\">" + requestBodyComTagsBR + "\n   \t\t\t\t\t\t </textarea>\n\t\t\t\t\t\t\t </p>";

			var previousWindowKeyDown = window.onkeydown;
			swal({
				title: "Erro Retornado Pelo Sistema do PNCP!",
				html: msg,
				type: "error",
				width: '1000px',
				confirmButtonAriaLabel: msg + ". Pressione este botão para fechar a mensagem."
			});
			window.onkeydown = previousWindowKeyDown;
		},

		alertaErro: function alertaErro(msgCliente, msgServidor, msgAdicional) {
			if (msgAdicional) this.alertaSimples(msgCliente, msgServidor, "Erro!", "error", msgAdicional);else this.alertaSimples(msgCliente, msgServidor, "Erro!", "error");
		},

		alertaSimples: function alertaSimples(msgCliente, msgServidor, titulo, tipo, msgAdicional) {
			var msg;
			if (msgCliente && msgServidor) {
				if (msgAdicional) {
					msg = msgCliente + " (" + msgServidor + ") " + Defs['default'].SEPARADOR_DE_MENS_ADICIONAL + " " + msgAdicional;
				} else {
					msg = msgCliente + " (" + msgServidor + ")";
				}
			} else if (msgCliente) {
				msg = "" + msgCliente;
			} else {
				msg = "" + msgServidor;
			}

			var msgSemTagsHTML = msg.replace(/<[^<>]+>/g, '');

			msgSemTagsHTML = msgSemTagsHTML.replace(Defs['default'].SEPARADOR_DE_MENS_ADICIONAL, "<br>");

			msg = msg.replace(Defs['default'].SEPARADOR_DE_MENS_ADICIONAL, "<br>");

			/*
	  	- Solução para o problema: ao exibir dois ou mais swal em sequência,
	  	não funciona mais o TAB para navegar entre os campos da tela.
	  	- Fonte: https://github.com/t4t5/sweetalert/issues/127
	  */
			var previousWindowKeyDown = window.onkeydown;
			swal({
				title: titulo,
				html: msg,
				type: tipo,
				width: '1000px',
				confirmButtonAriaLabel: msgSemTagsHTML + ". Pressione este botão para fechar a mensagem."
			});
			window.onkeydown = previousWindowKeyDown;
		},

		promessaThen: function promessaThen(arrAlertas, indArrAlertas, arrPromessasSwal) {
			var _this = this;

			var promessaSwal = arrPromessasSwal[arrPromessasSwal.length - 1];
			promessaSwal.then(function (value) {
				indArrAlertas++;
				if (indArrAlertas < arrAlertas.length) {
					var promessaSwal = _this.criaPromessaSwal(arrAlertas[indArrAlertas]);
					arrPromessasSwal.push(promessaSwal);
					_this.promessaThen(arrAlertas, indArrAlertas, arrPromessasSwal);
				}
			});
		},

		criaPromessaSwal: function criaPromessaSwal(mens) {
			return swal({
				title: "Atenção!",
				html: mens,
				type: 'warning',
				closeOnClickOutside: false
			});
		},

		alertas: function alertas(arrAlertas) {
			if (arrAlertas.length > 0) {
				var arrPromessasSwal = [];
				var promessaSwal = null;
				var indArrAlertas = -1;

				var previousWindowKeyDown = window.onkeydown;

				indArrAlertas++;
				promessaSwal = this.criaPromessaSwal(arrAlertas[indArrAlertas]);
				arrPromessasSwal.push(promessaSwal);
				this.promessaThen(arrAlertas, indArrAlertas, arrPromessasSwal);

				Promise.all(arrPromessasSwal);

				window.onkeydown = previousWindowKeyDown;
			}
		},

		//Clona construindo um novo objeto.
		//Copia as funções. Não funciona bem com arrays
		clona: function clona(obj) {
			return $.extend(true, {}, obj);
		},

		//Clona transformando o objeto em string e depois no JSON correspondente.
		//Não copia funções.
		//Funciona bem com arrays.
		clonaStringfyParse: function clonaStringfyParse(obj) {
			return JSON.parse(JSON.stringify(obj));
		},

		//remove todas as propriedades "falsy" (exceto 0 e false)
		//recursivamente, removendo objetos vazios.
		//As propriedades "id" e "version", também são removidas.
		//Se o objeto ficar vazio, retorn null.
		compactaObjeto: function compactaObjeto(o) {
			if (!o) {
				return null;
			}

			var self = this;
			_.each(o, function (v, k) {
				if (_.isArray(v) || _.isFunction(v)) {
					return;
				}

				if (!_.isObject(v)) {
					if (v === 0 || v === false) {
						return;
					} //exceções
					if (!v) {
						delete o[k];
					}
					return;
				}

				//trata objeto interno
				if (!_.isDate(v) && _.isEmpty(self.compactaObjeto(v))) {
					delete o[k];
				}
			});

			if (_.isDate(o)) {
				return o;
			}

			//Se um objeto possui apenas os atributos "id" e "version", também é considerado vazio
			if (_.keys(o).removeObjects(["id", "version"]).length < 1) {
				return null;
			}
			return this.objetoVazio(o) ? null : o;
		},

		objetoVazio: function objetoVazio(objeto) {
			return _.isEmpty(objeto);
		},

		//Copia os atributos 'importantes' de um Ember.Object para um objeto nativo do Javascript
		//Necessário para evitar o seguinte problema: considerando o seguinte modelo:
		//
		// Pessoa = Ember.Object.extend({
		//   nome: null,
		//   email: 'teste@teste.com',
		// })
		//
		//E a seguinte instância criada a partir desse modelo
		//
		// var p = Pessoa.create({
		//   nome: 'Adoniran'
		// })
		//
		//Quando se fizer um JSON.stringify dessa instância, o atributo email não aparecerá, mesmo
		//que tenha sido declarado um valor default. Isso porque, usando o Ember, enquanto não for setado
		//após a criação da instância, o atributo permanece no prototype, não na instância em si - então
		//o stringify não pega. Isso ocorre para atributos com valores de algum tipo pré-definido assim
		//como atributos que armazenam referências a objetos, de forma que, ainda que tenha sido definido
		//um valor default para esses atributos, ele não sobreviverá ao stringify, mesmo que se mudem os
		//atributos internos do objeto referido. A solução abaixo foi inspirada em
		//<http://byronsalau.com/blog/convert-ember-objects-to-json/>
		serializaObjeto: function serializaObjeto(objetoASerializar) {
			var objetoSerializado = {};
			if (!objetoASerializar) {
				return null;
			}
			// if(typeof objetoASerializar === "string") {
			// 	return objetoASerializar;
			// }
			for (var nomeAtributo in $.extend(true, {}, objetoASerializar, Object.getPrototypeOf(objetoASerializar))) {
				if (nomeAtributo === 'isInstance' || nomeAtributo === 'isDestroyed' || nomeAtributo === 'isDestroying' || nomeAtributo === 'concatenatedProperties' ||
				//typeof this[nomeAtributo] === 'function' || nomeAtributo.substring(0, 7) === '__ember') {
				typeof objetoASerializar[nomeAtributo] === 'function' || nomeAtributo.substring(0, 7) === '__ember') {

					continue;
				}

				//Se o atributo for um objeto (exceto arrays e datas), cada um de seus atributos
				//deve ser (recurscivamente) serializado.
				if (_.isObject(objetoASerializar[nomeAtributo]) && !_.isArray(objetoASerializar[nomeAtributo]) && !_.isDate(objetoASerializar[nomeAtributo])) {
					objetoSerializado[nomeAtributo] = this.serializaObjeto(objetoASerializar[nomeAtributo]);
				} else {
					objetoSerializado[nomeAtributo] = objetoASerializar[nomeAtributo];
				}
			}

			return objetoSerializado;
		},

		//Converte uma string do formato EN-US para PT-BR. Então, se receber uma string
		//tal como "1.211", vai considerá-la como "um inteiro e duzentos e onze milésimos"
		//em vez de "mil duzentos e onze" e, então, vai convertê-la para "1,211"
		stringNumericaUSAparaBR: function stringNumericaUSAparaBR(numeroUSA) {
			//TODO SE A STRING ESTIVER MAL FORMATADA, RETORNAR INTACTA
			//...
			return this._substituiPontosPorVirgulasEVirgulasPorPontos(numeroUSA);
		},

		//Converte uma string do formato PT-BR para EN-US. Então, se receber uma string
		//tal como "1,211", vai considerá-la como "um inteiro e duzentos e onze milésimos"
		//em vez de "mil duzentos e onze" e, então, vai convertê-la para "1.211"
		stringNumericaBRparaUSA: function stringNumericaBRparaUSA(numeroBR) {
			//TODO SE A STRING ESTIVER MAL FORMATADA, RETORNAR INTACTA
			//...
			return this._substituiPontosPorVirgulasEVirgulasPorPontos(numeroBR);
		},

		ehStringNumericaBR: function ehStringNumericaBR(numero) {
			var semSeparadorMilhar = /^[0-9]+(,[0-9]+){0,1}$/.test(numero);
			var comSeparadorMilhar = /^[0-9]{1,3}(\.[0-9]{3})*(,[0-9]+){0,1}$/.test(numero);
			return semSeparadorMilhar || comSeparadorMilhar;
		},

		ehStringNumericaUSA: function ehStringNumericaUSA(numero) {
			var semSeparadorMilhar = /^[0-9]+(\.[0-9]+){0,1}$/.test(numero);
			var comSeparadorMilhar = /^[0-9]{1,3}(,[0-9]{3})*(\.[0-9]+){0,1}$/.test(numero);
			return semSeparadorMilhar || comSeparadorMilhar;
		},

		parseFloatStringPtBR: function parseFloatStringPtBR(numeroPtBr) {
			if (typeof numeroPtBr !== 'string') {
				return numeroPtBr;
			}
			var numeroApenasSeparadorDecimal = numeroPtBr.replace('.', '');
			return parseFloat(this.stringNumericaBRparaUSA(numeroApenasSeparadorDecimal));
		},

		_substituiPontosPorVirgulasEVirgulasPorPontos: function _substituiPontosPorVirgulasEVirgulasPorPontos(texto) {
			return texto.replace(/,/g, '#').replace(/\./g, ',').replace(/#/g, '.');
		},

		/* Recebe um array [{chave: "FUNDAMENTO_ADESAO", valor: "xxxx"}, {chave: ...}...]
	 	 e retorna um objeto do tipo {FUNDAMENTO_ADESAO: "xxx", ...}
	 */
		converteParametrosDeExibicao: function converteParametrosDeExibicao(parametros) {
			var obj = {};
			parametros.forEach(function (p) {
				obj[p.chave] = p.valor;
			});
			return obj;
		},

		// Só processa o valor recebido (em string) para colocar pontos separadores de milhar se ele tiver apenas 1 ponto ou apenas 1 virgula ou nenhum dos dois. Neste último caso, complementa com a string ",00".
		// No caso de ter apenas um ponto e nenhuma vírgula, troca o ponto pela vírgula para então processar o valor recebido.
		// Em todos os outros casos possíveis, retorna a string como ela veio.
		colocaPontosSeparadoresDeMilhar: function colocaPontosSeparadoresDeMilhar(valorDeMoedaEmStringApenasComSeparadorDeCentavos) {
			var valorBackupeado = valorDeMoedaEmStringApenasComSeparadorDeCentavos;
			valorDeMoedaEmStringApenasComSeparadorDeCentavos = valorDeMoedaEmStringApenasComSeparadorDeCentavos.replace(/\./g, ',');
			var nroDeVirgulas = (valorDeMoedaEmStringApenasComSeparadorDeCentavos.match(/,/g) || []).length;
			if (nroDeVirgulas == 0) {
				return valorDeMoedaEmStringApenasComSeparadorDeCentavos + ",00";
			} else if (nroDeVirgulas == 1) {
				var valorInteiroString = valorDeMoedaEmStringApenasComSeparadorDeCentavos.substr(0, valorDeMoedaEmStringApenasComSeparadorDeCentavos.indexOf(','));
				var valorInteiroNumerico = parseInt(valorInteiroString);
				var valorInteiroStringApenasComSeparadorDeMilhar = valorInteiroNumerico.toLocaleString('pt-BR');
				var valorDosCentavosStringComAVirgulaAntes = valorDeMoedaEmStringApenasComSeparadorDeCentavos.substr(valorDeMoedaEmStringApenasComSeparadorDeCentavos.indexOf(','), valorDeMoedaEmStringApenasComSeparadorDeCentavos.length - 1);
				return valorInteiroStringApenasComSeparadorDeMilhar + valorDosCentavosStringComAVirgulaAntes;
			} else {
				return valorBackupeado;
			}
		},

		primeiraLetraEmMaiuscula: function primeiraLetraEmMaiuscula(string) {
			//Exemplo: ASDF_FDSA -> Asdf Fdsa
			return string && string.replace(/_/g, ' ').split(" ").map(function (string) {
				var string1 = string.toLowerCase();
				return string1.charAt(0).toUpperCase() + string1.slice(1);
			}).join(" ");
		},

		//USUAL: umaStringEmCamelCase -> Uma String Em Camel Case - OK
		//CASO ESPECIAL: stringComIDFuncionaTambem -> String Com ID Funciona Tambem - AINDA NÃO
		separaECapitalizaPalavrasCamelCase: function separaECapitalizaPalavrasCamelCase(stringCamelCase) {
			//PRIMEIRO CASO
			var stringCamelCaseSeparado = stringCamelCase.replace(/([a-z])([A-Z])/g, "$1 $2");
			//SEGUNDO CASO
			var stringCasoEspecialTratado = stringCamelCaseSeparado.replace(/([A-Z][A-Z]*)([A-Z][a-z])/g, "$1 $2");
			//CAPITALIZA PRIMEIRA LETRA
			return stringCasoEspecialTratado.replace(/^.|".|'.|\/./g, function (str) {
				return str.toUpperCase();
			});
		},

		incluiPontuacaoCpf: function incluiPontuacaoCpf(cpf) {
			if (!cpf) {
				return cpf;
			}

			var strCPF = cpf.toString();

			if (strCPF.length !== 11) {
				return cpf;
			}

			return strCPF.slice(0, 3) + "." + strCPF.slice(3, 6) + "." + strCPF.slice(6, 9) + "-" + strCPF.slice(9);
		},

		incluiPontuacaoCnpj: function incluiPontuacaoCnpj(cnpj) {
			if (!cnpj) {
				return cnpj;
			}

			var strCNPJ = cnpj.toString();

			if (strCNPJ.length !== 14) {
				return cnpj;
			}

			return strCNPJ.slice(0, 2) + "." + strCNPJ.slice(2, 5) + "." + strCNPJ.slice(5, 8) + "/" + strCNPJ.slice(8, 12) + "-" + strCNPJ.slice(12);
		},

		//Ordena o array recebido como parâmetro "in place", usando o Array.sort() do javascript
		ordenaItensPorID: function ordenaItensPorID(itens) {
			return itens.sort(function (itemA, itemB) {
				if (itemA.id < itemB.id) {
					return -1;
				}
				if (itemA.id > itemB.id) {
					return 1;
				}
				return 0;
			});
		},

		//Se a < b, retorna -1
		//Se a === b, retorna 0
		//Se a > b, retorna 1
		//Valores não-numéricos (strings, boolean, null, undefined) são considerados
		//menores que qualquer número. Se ambos forem-não numéricos, retorna -1;
		comparaNumeros: function comparaNumeros(a, b) {
			if (typeof a !== 'number') {
				return -1;
			}
			if (typeof b !== 'number') {
				return 1;
			}

			if (a < b) {
				return -1;
			}
			if (a === b) {
				return 0;
			}
			if (a > b) {
				return 1;
			}
		},

		tipoDaVariavel: function tipoDaVariavel(variavel) {
			return ({}).toString.call(variavel).match(/\s([a-zA-Z]+)/)[1];
		},

		formataData: function formataData(date) {
			var opcoesFormatoData = { day: 'numeric', month: 'numeric', year: 'numeric' };
			if (date.getHours() || date.getMinutes() || date.getSeconds()) {
				opcoesFormatoData['hour'] = '2-digit';
				opcoesFormatoData['minute'] = '2-digit';
			}
			return date.toLocaleString('pt-BR', opcoesFormatoData);
		},

		ehArray: function ehArray(obj) {
			return Object.prototype.toString.call(obj) === '[object Array]';
		},

		ehObjeto: function ehObjeto(obj) {
			return Object.prototype.toString.call(obj) === '[object Object]';
		},

		ehFuncao: function ehFuncao(obj) {
			return Object.prototype.toString.call(obj) === '[object Function]';
		},

		stripLast: function stripLast(str) {
			// Tira o último caracter da string
			return str.substring(0, str.length - 1);
		},

		metodosParaObterAtributosDeUmObjeto: function metodosParaObterAtributosDeUmObjeto(args) {

			/*
	  	ATENÇÃO!!! O método que melhor funciona é o getPrototypeEnumerables(). Porém ele ainda assim devolve,
	  			   na versão do Ember em uso, três atributos que não nos interessa, que são:
	  					   concatenatedProperties, isDestroyed e isDestroying
	  			   Portanto, alterei o método _getPropertyNames() para que retorne todos os atributos menos 
	  			   esses três.
	  */

			return {
				getOwnEnumerables: function getOwnEnumerables(obj) {
					return this._getPropertyNames(obj, true, false, this._enumerable);
					// Or could use for..in filtered with hasOwnProperty or just this: return Object.keys(obj);
				},
				getOwnNonenumerables: function getOwnNonenumerables(obj) {
					return this._getPropertyNames(obj, true, false, this._notEnumerable);
				},
				getOwnEnumerablesAndNonenumerables: function getOwnEnumerablesAndNonenumerables(obj) {
					return this._getPropertyNames(obj, true, false, this._enumerableAndNotEnumerable);
					// Or just use: return Object.getOwnPropertyNames(obj);
				},
				getPrototypeEnumerables: function getPrototypeEnumerables(obj) {
					return this._getPropertyNames(obj, false, true, this._enumerable);
				},
				getPrototypeNonenumerables: function getPrototypeNonenumerables(obj) {
					return this._getPropertyNames(obj, false, true, this._notEnumerable);
				},
				getPrototypeEnumerablesAndNonenumerables: function getPrototypeEnumerablesAndNonenumerables(obj) {
					return this._getPropertyNames(obj, false, true, this._enumerableAndNotEnumerable);
				},
				getOwnAndPrototypeEnumerables: function getOwnAndPrototypeEnumerables(obj) {
					return this._getPropertyNames(obj, true, true, this._enumerable);
					// Or could use unfiltered for..in
				},
				getOwnAndPrototypeNonenumerables: function getOwnAndPrototypeNonenumerables(obj) {
					return this._getPropertyNames(obj, true, true, this._notEnumerable);
				},
				getOwnAndPrototypeEnumerablesAndNonenumerables: function getOwnAndPrototypeEnumerablesAndNonenumerables(obj) {
					return this._getPropertyNames(obj, true, true, this._enumerableAndNotEnumerable);
				},
				// Private static property checker callbacks
				_enumerable: function _enumerable(obj, prop) {
					return obj.propertyIsEnumerable(prop);
				},
				_notEnumerable: function _notEnumerable(obj, prop) {
					return !obj.propertyIsEnumerable(prop);
				},
				_enumerableAndNotEnumerable: function _enumerableAndNotEnumerable(obj, prop) {
					return true;
				},
				// Inspired by http://stackoverflow.com/a/8024294/271577
				_getPropertyNames: function getAllPropertyNames(obj, iterateSelfBool, iteratePrototypeBool, includePropCb) {
					var props = [];

					do {
						if (iterateSelfBool) {
							Object.getOwnPropertyNames(obj).forEach(function (prop) {
								if (props.indexOf(prop) === -1 && includePropCb(obj, prop)) {
									if (['concatenatedProperties', 'isDestroyed', 'isDestroying'].indexOf(prop) < 0) {
										props.push(prop);
									}
								}
							});
						}
						if (!iteratePrototypeBool) {
							break;
						}
						iterateSelfBool = true;
					} while (obj = Object.getPrototypeOf(obj));

					return props;
				}
			};
		},

		converteArrayDeObjetosParaCSV: function converteArrayDeObjetosParaCSV(args) {

			var delimitadorNomeObj = ':';
			var delimitadorCampos = "|";
			var self = this;
			var arrPathsColsAExibir = args.arrPathsColsAExibir;
			var colunasNaoEncontradas = [];

			var obtemArrayCabecalho = function obtemArrayCabecalho(args) {
				var arrayCabec = [];
				var model = args.model;
				var arrTitulosColsAExibir = args.arrTitulosColsAExibir;

				var metodosParaObterAtributos = self.metodosParaObterAtributosDeUmObjeto();

				var processaObjeto = function processaObjeto(model1, prefixoKeyColuna1) {

					var atributosDoModel = metodosParaObterAtributos.getPrototypeEnumerables(model1);

					atributosDoModel.forEach(function (atributoDoModel) {
						if (self.ehFuncao(model1[atributoDoModel])) {
							return;
						}
						var nomeDoCampo = atributoDoModel;
						var cabec = {
							key: '',
							titulo: '',
							objetoDoModel: null }; // objetoDoModel
						var metadado = {
							key: nomeDoCampo,
							valor: model1[nomeDoCampo] };
						if (model1.objDoArrayDoCampo) {
							metadado.valor = model1.objDoArrayDoCampo(metadado);
						}
						if (!self.ehObjeto(metadado.valor)) {
							cabec.key = prefixoKeyColuna1 + atributoDoModel;
							if (arrPathsColsAExibir.indexOf(cabec.key) > -1) {
								cabec.objetoDoModel = metadado.valor;
								cabec.titulo = arrTitulosColsAExibir[arrPathsColsAExibir.indexOf(cabec.key)];
								arrayCabec[cabec.key] = cabec;
							}
						} else {
							processaObjeto(metadado.valor, prefixoKeyColuna1 + nomeDoCampo + delimitadorNomeObj);
						}
					});
				};

				processaObjeto(model, '');

				return arrayCabec;
			};

			var eliminaDuplicadosEmArray = function eliminaDuplicadosEmArray(arr) {
				var uniques = [];
				var itemsFound = {};
				for (var i = 0, l = arr.length; i < l; i++) {
					var stringified = JSON.stringify(arr[i]);
					if (itemsFound[stringified]) {
						continue;
					}
					uniques.push(arr[i]);
					itemsFound[stringified] = true;
				}
				return uniques;
			};

			var ehExatamenteEstaAColASerExibida = function ehExatamenteEstaAColASerExibida(arrPathsColsAExibir, pathCabec) {
				return arrPathsColsAExibir.indexOf(pathCabec) > -1;
			};

			var existemColsAExibirAbaixoDestaNaHierarquia = function existemColsAExibirAbaixoDestaNaHierarquia(arrPathsColsAExibir, pathCabec, arrPathsColsAExibirAbaixoDestaNaHierarquia) {
				arrPathsColsAExibirAbaixoDestaNaHierarquia = arrPathsColsAExibir.filter(function (str) {
					return str.startsWith(pathCabec + delimitadorNomeObj);
				});
				return arrPathsColsAExibirAbaixoDestaNaHierarquia;
			};

			//
			// *** Estamos supondo que todos os arrays são de objetos ***
			//
			var obtemArrayDados = function obtemArrayDados(args) {
				var arrayDados = [];
				var dados = args.dados;

				dados.forEach(function (registro) {
					registro = registro || null;
					if (registro == null) {
						return;
					}

					var impr = false;

					var processaObjetoDoRegObtidoDaAPI = function processaObjetoDoRegObtidoDaAPI(objeto, prefixoKeyColuna1) {
						var retornar = {};
						var nomesDosCamposDoObjDoRegObtidoDaAPI = Object.getOwnPropertyNames(objeto);

						if (!impr) {
							impr = true;
						}

						nomesDosCamposDoObjDoRegObtidoDaAPI.forEach(function (nomeDoCampoDoObjDoRegObtidoDaAPI) {
							if (nomeDoCampoDoObjDoRegObtidoDaAPI === 'version' || nomeDoCampoDoObjDoRegObtidoDaAPI.startsWith('_') || self.ehFuncao(objeto[nomeDoCampoDoObjDoRegObtidoDaAPI])) {
								return;
							}

							var retornado = [];
							var pathBaseadoNoNomeDoCampoDoObjDoRegObtidoDaAPI = prefixoKeyColuna1 + nomeDoCampoDoObjDoRegObtidoDaAPI;
							var valorDoCampoNoRegObtidoDaAPI = objeto[nomeDoCampoDoObjDoRegObtidoDaAPI];

							if (ehExatamenteEstaAColASerExibida(arrPathsColsAExibir, pathBaseadoNoNomeDoCampoDoObjDoRegObtidoDaAPI)) {
								if (valorDoCampoNoRegObtidoDaAPI != null) {
									var auxValorDoCampoNoRegObtidoDaAPI = valorDoCampoNoRegObtidoDaAPI;
									if (self.tipoDaVariavel(auxValorDoCampoNoRegObtidoDaAPI) === 'Date') {
										// Outros valores podem ser: Number, Null, String, entre outros...
										auxValorDoCampoNoRegObtidoDaAPI = self.formataData(auxValorDoCampoNoRegObtidoDaAPI);
									}
									retornar[pathBaseadoNoNomeDoCampoDoObjDoRegObtidoDaAPI] = auxValorDoCampoNoRegObtidoDaAPI;
								} else {
									retornar[pathBaseadoNoNomeDoCampoDoObjDoRegObtidoDaAPI] = "";
								}
							} else {
								var arrPathsColsAExibirAbaixoDestaNaHierarquia = [];
								if (existemColsAExibirAbaixoDestaNaHierarquia(arrPathsColsAExibir, pathBaseadoNoNomeDoCampoDoObjDoRegObtidoDaAPI, arrPathsColsAExibirAbaixoDestaNaHierarquia).length > 0) {
									if (valorDoCampoNoRegObtidoDaAPI == null) {
										arrPathsColsAExibirAbaixoDestaNaHierarquia.forEach(function (colunaAExibir) {
											retornar[colunaAExibir] = "";
										});
									} else if (self.ehArray(valorDoCampoNoRegObtidoDaAPI)) {
										for (var i = 0; i < valorDoCampoNoRegObtidoDaAPI.length; i++) {
											retornado.push(processaObjetoDoRegObtidoDaAPI(valorDoCampoNoRegObtidoDaAPI[i], pathBaseadoNoNomeDoCampoDoObjDoRegObtidoDaAPI + delimitadorNomeObj));
										}
										retornado = eliminaDuplicadosEmArray(retornado);
										retornar[pathBaseadoNoNomeDoCampoDoObjDoRegObtidoDaAPI] = retornado;
									} else if (self.ehObjeto(valorDoCampoNoRegObtidoDaAPI)) {
										retornado = processaObjetoDoRegObtidoDaAPI(valorDoCampoNoRegObtidoDaAPI, pathBaseadoNoNomeDoCampoDoObjDoRegObtidoDaAPI + delimitadorNomeObj);
										retornar[pathBaseadoNoNomeDoCampoDoObjDoRegObtidoDaAPI] = retornado;
									};
								} else {
									// Ignora, pois o campo não é correspondente a nenhuma uma coluna
								}
							}
						});
						return retornar;
					};

					arrayDados.push(processaObjetoDoRegObtidoDaAPI(registro, ''));
				});
				/*
	   		if (colunasNaoEncontradas.length > 0) {
	   			console.error('As colunas a seguir não foram encontradas, contate a TI: ');
	   			colunasNaoEncontradas.forEach(function(nomeDaColuna) {
	   				console.error("      "+nomeDaColuna);
	   			});
	   		}
	   */
				return arrayDados;
			};

			var geraArrLinhasEColunas = function geraArrLinhasEColunas(arrDados1) {
				// arrDados1 = [ {obj1}, {obj2}, ..., {objN} ] ---> Cada item do array (objX) corresponde a um registro já processado que corresponde a um registro obtido pela consulta REST à API.
				var arrLinhasEColunas = [];
				var linhaRealDaPlanilha = 0;
				arrDados1.forEach(function (objeto) {
					// objeto corresponde a um registro já processado que corresponde a um registro obtido pela consulta REST à API.
					var processaObjeto = function processaObjeto(objeto1, linha1) {
						// objeto1 = { {atr1}, {atr2}, ... {atrN} }  ---> Cada atributo de objeto1 (atrX) corresponde a um objeto que será processado para se transformar em uma ou mais colunas da planilha, dependendo do seu tipo.
						var atribsDoObj = Object.getOwnPropertyNames(objeto1);
						var maxIncrementoLinha = 0;
						atribsDoObj.forEach(function (atrDoObj) {
							// propDoObj corresponde a uma coluna ou mais colunas da planilha, dependendo do seu tipo
							if (atrDoObj === 'version' || atrDoObj.startsWith('_') || self.ehFuncao(objeto1[atrDoObj])) {
								return;
							}
							var incrementoLinha = 0;
							if (self.ehObjeto(objeto1[atrDoObj])) {
								incrementoLinha += processaObjeto(objeto1[atrDoObj], linha1);
							} else if (self.ehArray(objeto1[atrDoObj])) {
								var arrAux = objeto1[atrDoObj];
								for (var indArrAux = 0; indArrAux < arrAux.length; indArrAux++) {
									incrementoLinha += processaObjeto(arrAux[indArrAux], linha1 + incrementoLinha);
								}
							} else {
								if (!arrLinhasEColunas[linha1]) {
									arrLinhasEColunas[linha1] = [];
								}
								arrLinhasEColunas[linha1][atrDoObj] = objeto1[atrDoObj];
								incrementoLinha++;
							}
							maxIncrementoLinha = incrementoLinha > maxIncrementoLinha ? incrementoLinha : maxIncrementoLinha;
						});
						return maxIncrementoLinha;
					};
					var incrementoLinha = processaObjeto(objeto, linhaRealDaPlanilha);
					linhaRealDaPlanilha += incrementoLinha;
				});
				return arrLinhasEColunas;
			};

			var colunasEmOrdemEmArrayUnidimensional = function colunasEmOrdemEmArrayUnidimensional(arrayAClassificar, ordem) {
				var retorno = [];
				for (var x = 0; x < ordem.length; x++) {
					retorno.push(arrayAClassificar[ordem[x]]);
				}
				return retorno;
			};

			var colunasEmOrdemEmArrayBidimensional = function colunasEmOrdemEmArrayBidimensional(arrayAClassificar, ordem) {
				var retorno = [[], []];
				for (var y = 0; y < arrayAClassificar.length; y++) {
					if (!retorno[y]) {
						// Truque para poder incrementar arrays multidimensionais
						retorno[y] = [];
					}
					for (var x = 0; x < ordem.length; x++) {
						try {
							retorno[y][x] = arrayAClassificar[y][ordem[x]];
						} catch (err) {
							retorno[y][x] = "";
						};
					}
				}
				return retorno;
			};

			var arrayCabec1 = obtemArrayCabecalho(args);
			var arrayDados1 = obtemArrayDados(args);
			var arrLinhasEColunas = geraArrLinhasEColunas(arrayDados1);

			var arrayCabecClassif = colunasEmOrdemEmArrayUnidimensional(arrayCabec1, arrPathsColsAExibir);
			var arrayDadosClassif = colunasEmOrdemEmArrayBidimensional(arrLinhasEColunas, arrPathsColsAExibir);

			var csv = arrayCabecClassif.mapBy('titulo').join(delimitadorCampos) + '\r\n';

			var registros = new Set();
			for (var x = 0; x < arrayDadosClassif.length; x++) {
				registros.add(arrayDadosClassif[x].join(delimitadorCampos));
			}
			var arrRegistros = Array.from(registros);

			csv += arrRegistros.join('\r\n');

			var retorno = {
				colunasNaoEncontradas: colunasNaoEncontradas,
				csv: csv
			};
			return retorno;
		},

		// MUITO IMPORTANTE!!!!
		//    O model recebido como parâmetro na função montaCSV() é o model correspondente ao model d

		montaCSV: function montaCSV(model, arrDados, arrTituloColsAExibir) {
			var retorno = this.converteArrayDeObjetosParaCSV({
				model: model,
				dados: arrDados,
				arrPathsColsAExibir: arrTituloColsAExibir.mapBy("path"),
				arrTitulosColsAExibir: arrTituloColsAExibir.mapBy("titulo")
			});
			return retorno;
		},

		downloadCSV: function downloadCSV(csv, nomeArquivo) {
			var filename = nomeArquivo + '.csv';

			var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

			if (navigator.msSaveBlob) {
				// IE 10+
				navigator.msSaveBlob(blob, filename);
			} else {
				var link = document.createElement("a");
				if (link.download !== undefined) {

					// feature detection, Browsers that support HTML5 download attribute
					var url = URL.createObjectURL(blob);
					link.setAttribute("href", url);
					link.setAttribute("download", filename);
					link.style = "visibility:hidden";
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				}
			}
		},

		removerAcentos: function removerAcentos(newStringComAcento) {
			var string = newStringComAcento;
			var mapaAcentosHex = {
				a: /[\xE0-\xE6]/g,
				e: /[\xE8-\xEB]/g,
				i: /[\xEC-\xEF]/g,
				o: /[\xF2-\xF6]/g,
				u: /[\xF9-\xFC]/g,
				c: /\xE7/g,
				n: /\xF1/g
			};

			for (var letra in mapaAcentosHex) {
				var expressaoRegular = mapaAcentosHex[letra];
				string = string.replace(expressaoRegular, letra);
			};

			return string;
		},

		stringParaComparacao: function stringParaComparacao(s) {
			return this.removerAcentos(s.trim().toLowerCase());
		},

		sleep: function sleep(time, callback) {
			var stop = new Date().getTime();
			while (new Date().getTime() < stop + time) {
				;
			}
			if (callback) {
				callback();
			}
		},

		compoeProcAdm: function compoeProcAdm(nro, ano) {
			return nro + "/" + ano;
		},

		decompoeProAdm: function decompoeProAdm(procAdm) {
			return procAdm.split("/");
		},

		disableF5: function disableF5(e) {
			if ((e.which || e.keyCode) == 116) e.preventDefault();
		},

		desabilitaTeclaF5: function desabilitaTeclaF5() {
			$(document).on("keydown", this.disableF5);
		},

		habilitaTeclaF5: function habilitaTeclaF5() {
			$(document).off("keydown", this.disableF5);
		},

		verificarTipoArquivoValido: function verificarTipoArquivoValido(tipoArquivoValido) {
			var tiposValidos = ['pdf', 'txt', 'rtf', 'doc', 'docx', 'xls', 'xlsx', 'odt', 'ods', 'sxw', 'zip', '7z', 'rar', 'dwg', 'dwt', 'dxf', 'dwf', 'dwfx', 'svg', 'sldprt', 'sldasm', 'dgn', 'ifc', 'skp', '3ds', 'dae', 'obj', 'rfa', 'rte'];
			var tipoValido = false;
			var _iteratorNormalCompletion = true;
			var _didIteratorError = false;
			var _iteratorError = undefined;

			try {
				for (var _iterator = tiposValidos[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
					var t = _step.value;

					if (tipoArquivoValido.includes(t)) {
						tipoValido = true;
						break;
					}
				}
			} catch (err) {
				_didIteratorError = true;
				_iteratorError = err;
			} finally {
				try {
					if (!_iteratorNormalCompletion && _iterator["return"]) {
						_iterator["return"]();
					}
				} finally {
					if (_didIteratorError) {
						throw _iteratorError;
					}
				}
			}

			return tipoValido;
		},

		verificarTamanhoArquivoValido: function verificarTamanhoArquivoValido(tamanho) {
			var tamanhoValido = true;

			if (tamanho > 30 * 1048576) {
				tamanhoValido = false;
			}

			return tamanhoValido;
		},

		separaMensAdicional: function separaMensAdicional(mensAdicional) {
			return mensAdicional.split(Defs['default'].SEPARADOR_DE_MENS_ADICIONAL);
		},

		trocaQuebrasDeLinhaPorTagsBR: function trocaQuebrasDeLinhaPorTagsBR(str) {
			var strComTagsBR = str.replace(/\\n/g, '<BR />');
			return strComTagsBR;
		},

		varToArray: function varToArray(variavel) {
			return variavel && !Array.isArray(variavel) ? [variavel] : variavel;
		}

	};

});