define('webapp/pods/pncp/constantes-pncp-envio', ['exports'], function (exports) {

  'use strict';

  exports['default'] = {

    operacoes: [{ id: 'I', nome: 'Inclusão' }, { id: 'A', nome: 'Retificação' }, { id: 'E', nome: 'Exclusão' }],
    descricoesUnidades: [{ id: 1, nome: 'Aquisição' }, { id: 2, nome: 'Item da Aquisição' }, { id: 3, nome: 'Documento da Aquisição' }, { id: 4, nome: 'Adjudicação de Item da Aquisição' }, { id: 5, nome: 'Empenho' }, { id: 6, nome: 'Documento do Empenho' }, { id: 7, nome: 'Contrato' }, { id: 8, nome: 'Documento do Contrato' }, { id: 9, nome: 'Aditivo do Contrato' }, { id: 10, nome: 'Documento de Aditivo do Contrato' }, { id: 11, nome: 'Rescisão de Contrato' }, { id: 12, nome: 'Documento de Rescisão de Contrato' }, { id: 13, nome: 'Ata' }, { id: 14, nome: 'Documento de Ata' }]
  };

});