define('webapp/pods/aquisicao/compra/item/identificacao/template', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.12.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(fragment,1,1,contextualElement);
            inline(env, morph0, context, "date-input", [], {"texto": "Data", "valor": get(env, context, "model.data"), "tamanho": 4, "id": "tag||data|aquisicao/compra/item|identificacao"});
            return fragment;
          }
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            isHTMLBars: true,
            revision: "Ember@1.12.1",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","row");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("label");
              dom.setAttribute(el2,"class","col-md-4");
              var el3 = dom.createTextNode("Tipo de Acréscimo*");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","row");
              dom.setAttribute(el1,"style","padding-bottom:7px;");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","col-md-8");
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var element3 = dom.childAt(fragment, [3, 1]);
              var morph0 = dom.createMorphAt(element3,1,1);
              var morph1 = dom.createMorphAt(element3,3,3);
              inline(env, morph0, context, "tag-radio-button", [], {"texto": "Quantitativo", "labelAcessivel": "Acréscimo Quantitativo", "valor": "ACRESCIMO_QUANTITATIVO", "atributoDestino": get(env, context, "model.motivoCriacao"), "autoFoco": true, "desabilitado": get(env, context, "model.version"), "labelNegrito": true, "tamanho": 6, "id": "radio||acrescimoQuantitativo|aquisicao/compra/item|identificacao"});
              inline(env, morph1, context, "tag-radio-button", [], {"texto": "Qualitativo", "labelAcessivel": "Acréscimo Qualitativo", "valor": "ACRESCIMO_QUALITATIVO", "atributoDestino": get(env, context, "model.motivoCriacao"), "desabilitado": get(env, context, "model.version"), "labelNegrito": true, "tamanho": 6, "id": "radio||acrescimoQualitativo|aquisicao/compra/item|identificacao"});
              return fragment;
            }
          };
        }());
        return {
          isHTMLBars: true,
          revision: "Ember@1.12.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, block = hooks.block;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, null);
            dom.insertBoundary(fragment, 0);
            block(env, morph0, context, "if", [get(env, context, "ehAcrescimo")], {}, child0, null);
            return fragment;
          }
        };
      }());
      var child2 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.12.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","row");
            dom.setAttribute(el1,"style","padding-bottom:7px;");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","col-md-8");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("fieldset");
            dom.setAttribute(el3,"style","font-weight: bold;");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("legend");
            dom.setAttribute(el4,"style","margin: 0px 10px 5px 0px; font-size: 14px");
            var el5 = dom.createTextNode("Natureza*");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            \n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("fieldset");
            dom.setAttribute(el3,"style","font-weight: bold");
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("legend");
            dom.setAttribute(el4,"style","margin-top: 10px; margin-bottom: 5px; font-size: 14px");
            var el5 = dom.createTextNode("Orçamento Sigiloso*");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element0 = dom.childAt(fragment, [1, 1]);
            var element1 = dom.childAt(element0, [1]);
            var element2 = dom.childAt(element0, [3]);
            var morph0 = dom.createMorphAt(element1,3,3);
            var morph1 = dom.createMorphAt(element1,5,5);
            var morph2 = dom.createMorphAt(element2,3,3);
            var morph3 = dom.createMorphAt(element2,5,5);
            inline(env, morph0, context, "tag-radio-button", [], {"texto": "Material", "labelAcessivel": "Material", "valor": "MATERIAL", "atributoDestino": get(env, context, "model.naturezaItem"), "autoFoco": true, "desabilitado": get(env, context, "model.version"), "labelNegrito": true, "tamanho": 6, "id": "radio||material|aquisicao/compra/item|identificacao"});
            inline(env, morph1, context, "tag-radio-button", [], {"texto": "Serviço", "labelAcessivel": "Serviço", "valor": "SERVICO", "atributoDestino": get(env, context, "model.naturezaItem"), "desabilitado": get(env, context, "model.version"), "labelNegrito": true, "tamanho": 6, "id": "radio||servico|aquisicao/compra/item|identificacao"});
            inline(env, morph2, context, "tag-radio-button", [], {"texto": "Sim", "labelAcessivel": "Sim", "valor": true, "atributoDestino": get(env, context, "model.orcamentoSigiloso"), "autoFoco": true, "desabilitado": get(env, context, "model.version"), "labelNegrito": true, "tamanho": 6, "id": "radio||temOrcamentoSigiloso|aquisicao/compra/item|identificacao"});
            inline(env, morph3, context, "tag-radio-button", [], {"texto": "Não", "labelAcessivel": "Não", "valor": false, "atributoDestino": get(env, context, "model.orcamentoSigiloso"), "desabilitado": get(env, context, "model.version"), "labelNegrito": true, "tamanho": 6, "id": "radio||naoTemOrcamentoSigiloso|aquisicao/compra/item|identificacao"});
            return fragment;
          }
        };
      }());
      var child3 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.12.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(fragment,1,1,contextualElement);
            inline(env, morph0, context, "decimal-input", [], {"texto": "Duração (meses)", "valor": get(env, context, "model.itemAquisicao.duracao"), "tamanho": 4, "somenteLeitura": get(env, context, "proibidoAlterarDadosItemAquisicao"), "id": "tag||duracao|aquisicao/compra/item|identificacao"});
            return fragment;
          }
        };
      }());
      return {
        isHTMLBars: true,
        revision: "Ember@1.12.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          dom.setAttribute(el1,"style","padding-bottom:7px;");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-md-4");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, inline = hooks.inline, block = hooks.block;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element4 = dom.childAt(fragment, [1]);
          var element5 = dom.childAt(fragment, [15]);
          var element6 = dom.childAt(fragment, [17]);
          var element7 = dom.childAt(fragment, [19]);
          var morph0 = dom.createMorphAt(element4,1,1);
          var morph1 = dom.createMorphAt(element4,3,3);
          var morph2 = dom.createMorphAt(fragment,3,3,contextualElement);
          var morph3 = dom.createMorphAt(fragment,5,5,contextualElement);
          var morph4 = dom.createMorphAt(dom.childAt(fragment, [7]),1,1);
          var morph5 = dom.createMorphAt(dom.childAt(fragment, [9]),1,1);
          var morph6 = dom.createMorphAt(dom.childAt(fragment, [11]),1,1);
          var morph7 = dom.createMorphAt(dom.childAt(fragment, [13]),1,1);
          var morph8 = dom.createMorphAt(dom.childAt(element5, [1]),1,1);
          var morph9 = dom.createMorphAt(element5,3,3);
          var morph10 = dom.createMorphAt(element6,1,1);
          var morph11 = dom.createMorphAt(element6,3,3);
          var morph12 = dom.createMorphAt(element6,5,5);
          var morph13 = dom.createMorphAt(element7,1,1);
          var morph14 = dom.createMorphAt(element7,3,3);
          var morph15 = dom.createMorphAt(element7,5,5);
          var morph16 = dom.createMorphAt(fragment,21,21,contextualElement);
          inline(env, morph0, context, "tag-input", [], {"texto": "Nº na Compra", "labelAcessivel": "Número do item na compra", "valor": get(env, context, "model.numeroApresentacao"), "mascara": "numeros", "autoFoco": true, "somenteLeitura": true, "tamanho": 4, "id": "tag||numero|aquisicao/compra/item|identificacao"});
          block(env, morph1, context, "unless", [get(env, context, "ehOriginal")], {}, child0, null);
          block(env, morph2, context, "if", [get(env, context, "ehItemNovo")], {}, child1, null);
          block(env, morph3, context, "if", [get(env, context, "mostraCamposItemAquisicao")], {}, child2, null);
          inline(env, morph4, context, "tag-select", [], {"texto": get(env, context, "textoItemComprar"), "obrigatorio": get(env, context, "permitidoEscolherItemAquisicao"), "conteudo": get(env, context, "itensAquisicaoElegiveis"), "opcaoLabel": "numeroApresentacao", "opcaoLabelSelecionada": "numeroApresentacao", "opcaoDescricao": "descricao", "valor": get(env, context, "model.itemAquisicao"), "textopadrao": "Selecione", "permiteLimpar": true, "id": "select||itensPagosEmpenho|aquisicao/compra/item|identificacao", "desabilitado": get(env, context, "proibidoEscolherItemAquisicao")});
          inline(env, morph5, context, "tag-select", [], {"texto": "Classificação", "obrigatorio": true, "conteudo": get(env, context, "classificacoesItem"), "opcaoLabel": "nome", "valor": get(env, context, "model.itemAquisicao.classificacao"), "textopadrao": "Selecione uma classificação", "permiteLimpar": true, "id": "select||classificacoesItens|aquisicao/compra/item|identificacao", "desabilitado": get(env, context, "proibidoAlterarDadosItemAquisicao")});
          inline(env, morph6, context, "tag-textarea", [], {"texto": "Descrição", "obrigatorio": true, "numeroLinhas": "3", "valor": get(env, context, "model.itemAquisicao.descricao"), "somenteLeitura": get(env, context, "proibidoAlterarDadosItemAquisicao"), "id": "tag||descricaoItemAquisicao|aquisicao/compra/item|identificacao"});
          inline(env, morph7, context, "tag-textarea", [], {"texto": "Especificação", "numeroLinhas": "3", "valor": get(env, context, "model.especificacao"), "id": "tag||especificacaoItemCompra|aquisicao/compra/item|identificacao"});
          inline(env, morph8, context, "tag-checkbox", [], {"texto": "Item Periódico", "nome": "ehPeriodico", "atributoDestino": get(env, context, "model.itemAquisicao.periodico"), "labelNegrito": true, "desabilitado": get(env, context, "proibidoAlterarDadosItemAquisicao"), "id": "checkbox||periodico|aquisicao/compra/item|identificacao"});
          block(env, morph9, context, "if", [get(env, context, "model.itemAquisicao.periodico")], {}, child3, null);
          inline(env, morph10, context, "tag-select", [], {"texto": "Unidade Medida", "obrigatorio": true, "conteudo": get(env, context, "unidadesDeMedida"), "opcaoLabel": "nome", "valor": get(env, context, "model.itemAquisicao.unidadeMedida"), "textopadrao": "Selecione uma unidade de medida", "permiteLimpar": true, "id": "select||unidadesMedida|aquisicao/compra/item|identificacao", "desabilitado": get(env, context, "proibidoAlterarDadosItemAquisicao"), "tamanho": 4});
          inline(env, morph11, context, "decimal-input", [], {"texto": "Qtd. Adjudicada", "labelAcessivel": "Quantidade adjudicada", "valor": get(env, context, "model.itemAquisicao.quantidadePropria"), "somenteLeitura": true, "tamanho": 4, "id": "tag||quantidadeAdjudicada|aquisicao/compra/item|identificacao"});
          inline(env, morph12, context, "decimal-input", [], {"texto": "Disponível Compra", "valor": get(env, context, "model.itemAquisicao.quantidadeDisponivelCompra"), "somenteLeitura": true, "tamanho": 4, "id": "tag||quantidadeDisponivelCompra|aquisicao/compra/item|identificacao"});
          inline(env, morph13, context, "moeda-input", [], {"texto": "Quantidade", "valor": get(env, context, "model.quantidade"), "obrigatorio": true, "tamanho": 4, "id": "tag||quantidade|aquisicao/compra/item|identificacao"});
          inline(env, morph14, context, "moeda-input", [], {"texto": "Valor Unitário", "valor": get(env, context, "model.valorUnitario"), "obrigatorio": true, "somenteLeitura": get(env, context, "proibidoAlterarValorUnitarioItemCompra"), "tamanho": 4, "id": "tag||valorUnitario|aquisicao/compra/item|identificacao"});
          inline(env, morph15, context, "moeda-input", [], {"texto": "Valor Total", "valor": get(env, context, "model.valorTotal"), "somenteLeitura": true, "tamanho": 4, "id": "tag||valorTotal|aquisicao/compra/item|identificacao"});
          inline(env, morph16, context, "partial", ["aquisicao/compra/item/execucao"], {});
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, null);
        dom.insertBoundary(fragment, 0);
        block(env, morph0, context, "tag-bloco", [], {"titulo": get(env, context, "tituloTela")}, child0, null);
        return fragment;
      }
    };
  }()));

});