define('webapp/pods/aquisicao/contrato/documento/controller', ['exports', 'ember', 'webapp/defs', 'webapp/utils/util', 'webapp/pods/transparencia/documento/constantes-documento', 'webapp/mixins/crud-mixin'], function (exports, Ember, Defs, Util, ConstantesDocumento, CrudMixin) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {

    needs: ['aquisicao/contrato'],

    breadCrumbConfig: {
      fnNomeApresentacao: 'fnNomeApresentacao'
    },

    tipos: ConstantesDocumento['default'].tipos,
    tiposPNCP: [],
    opcoesAditivos: [],

    fnNomeApresentacao: function fnNomeApresentacao() {
      return 'Documento ' + (this.model.version ? this.model.numeroApresentacao : '(Novo)');
    },

    crudConfig: {
      criarComUpload: {
        preparaUploadFn: function preparaUploadFn() {
          return this.criarDoc();
        },
        okFn: function okFn(opcao) {
          this.get('controllers.aquisicao/contrato').send('ler');
          if (opcao === 'CRIAR_E_PROXIMO') {
            this.set('model', []);
            this.get('controllers.aquisicao/contrato').send('ler');
            this.send('refreshRota'); //ação "borbulhará" para a rota
            return;
          }
          this.get('controllers.aquisicao/contrato').send('ler');
          this.transitionToRoute('aquisicao/contrato');
        }
      },
      excluir: {
        urlFn: function urlFn(id) {
          return 'transparencia/documento/contrato/' + id;
        },
        okFn: function okFn() {
          this.get('controllers.aquisicao/contrato').send('ler');
        }
      }
    },

    criarDoc: function criarDoc() {
      var formData = new FormData();
      formData.append("idContrato", this.get("controllers.aquisicao/contrato").model.id);
      formData.append("modulo", "CONTRATO");
      if (this.get("model.tipo")) {
        formData.append("tipo", this.model.tipo);
      }
      if (this.model.tipoPNCP) {
        formData.append("tipoPNCP", this.model.tipoPNCP);
      }
      formData.append("ordem", "0");
      if (this.model.idAditivo) {
        formData.append("idAditivo", this.model.idAditivo);
      }
      if (this.model.descricao) {
        formData.append("descricao", this.model.descricao);
      }
      var uploadInput = Ember['default'].$(".row-input-upload input[type=file]").get(0);

      if (uploadInput.files[0]) {
        if (!Util['default'].verificarTipoArquivoValido(uploadInput.files[0].name)) {
          Util['default'].alertaErro('Formato inválido para o arquivo.');
          return;
        }

        if (!Util['default'].verificarTamanhoArquivoValido(uploadInput.files[0].size)) {
          Util['default'].alertaErro('Arquivo com tamanho superior a 30MB.');
          return;
        }

        formData.append('documento', uploadInput.files[0]);
      }

      return formData;
    },

    carregaTiposDeDocumento: (function () {
      var _this = this;

      this.get('comunicador').servico('GET', 'tiposDeDocumentoPNCP/' + this.get('controllers.aquisicao/contrato').model.id + '?tipoEntidade=contrato').then(function (resultado) {
        _this.set('tiposPNCP', resultado);
      });
    }).observes('model.contrato'),

    carregaAditivos: (function () {
      var _this2 = this;

      this.get('comunicador').servico('GET', 'aquisicao/contrato/' + this.get('controllers.aquisicao/contrato').model.id).then(function (resultado) {
        var aditivos = [];
        resultado.apostilas.forEach(function (apostila) {
          var itemArray = { "id": apostila.id,
            "descricao": 'Apostila ' + apostila.numeroApresentacao + ' - ' + apostila.tiposExibicao };
          aditivos.push(itemArray);
        });
        resultado.aditivos.forEach(function (aditivo) {
          var itemArray = { "id": aditivo.id,
            "descricao": 'Aditivo ' + aditivo.numeroApresentacao + ' - ' + aditivo.tiposExibicao };
          aditivos.push(itemArray);
        });
        _this2.set('opcoesAditivos', aditivos);
      });
    }).observes('model.contrato'),

    tipoDocumentoDoPNCP: (function () {
      return this.get("controllers.aquisicao/contrato").model.enviaPNCP;
    }).property('model.contrato'),

    getDescricaoTipoDocumento: function getDescricaoTipoDocumento(documento) {
      if (!!documento.tipoPNCPDescricao) {
        return documento.tipoPNCPDescricao;
      }

      var tipo = this.tipos.find(function (t) {
        return t.id === documento.tipo;
      });
      if (tipo) {
        return tipo.nome;
      }

      return documento.tipo;
    },

    getLink: function getLink(documento) {
      return Defs['default'].enderecoServer + documento.urlDownload;
    },

    getTextoAcessivel: function getTextoAcessivel(textoAcessivelDefaultDoComponente) {
      return textoAcessivelDefaultDoComponente + ", download";
    },

    getAditivoContrato: function getAditivoContrato(documento) {
      if (documento.aditivo && documento.aditivo.apostila === 'S') {
        return 'Apostila ' + documento.aditivo.numero + ' (' + documento.aditivo.tiposExibicao + ')';
      }
      if (documento.aditivo) {
        return 'Aditivo ' + documento.aditivo.numero + ' (' + documento.aditivo.tiposExibicao + ')';
      }
      return;
    }

  });

});