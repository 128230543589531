define('webapp/pods/aquisicao/compra/empenho/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin'], function (exports, Ember, CrudMixin) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {

    needs: ['aquisicao', 'aquisicao/compra'],

    breadCrumbConfig: {
      fnNomeApresentacao: 'fnNomeApresentacao'
    },

    fnNomeApresentacao: function fnNomeApresentacao() {
      return 'Empenho ' + (this.model.version ? 'Nº ' + this.model.numeroApresentacao : ' (Novo)');
    },

    inicializaCampos: function inicializaCampos(model) {

      this.set('model', model);

      this.get('comunicador').leParaPropriedade(this, 'categoriaProcesso', 'categoriasProcesso', 'Não foi possível obter as categorias do processo.');

      this.set('aquisicao', this.get("controllers.aquisicao").model);
    },

    crudConfig: {
      criar: {
        urlFn: function urlFn() {
          return 'compra/' + this.get("controllers.aquisicao/compra").model.id + '/empenho';
        },
        okFn: function okFn(opcao) {
          this.get('controllers.aquisicao/compra').send('ler');
          if (opcao === 'CRIAR_E_PROXIMO') {
            //ação "borbulhará" para a rota
            this.transitionToRoute('aquisicao/compra/empenho', this.model.id);
            return;
          }
          this.transitionToRoute('aquisicao/compra');
        }
      },
      atualizar: {
        okFn: function okFn() {
          this.get('controllers.aquisicao/compra').send('ler');
          //window.location.reload();
        }
      },
      excluir: {
        okFn: function okFn() {
          this.get('controllers.aquisicao/compra').send('ler');
        }
      }
    },

    ehEmpenhoExistente: (function () {
      return this.model.id != null;
    }).property('model.id'),

    aquisicaoDivulgadaNoPncp: (function () {
      if (this.get('controllers.aquisicao.model.pncpano') && this.get('controllers.aquisicao.model.pncpseq')) {
        return true;
      }
      return false;
    }).property('controllers.aquisicao.model')
  });

});