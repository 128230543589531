define('webapp/pods/aquisicao/compra/empenho/criar/route', ['exports', 'ember', 'webapp/pods/aquisicao/compra/empenho/model', 'webapp/utils/util', 'webapp/mixins/suprimir-templates-superiores-mixin'], function (exports, Ember, Empenho, Util, SuprimirTemplatesSuperioresMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(SuprimirTemplatesSuperioresMixin['default'], {
    controllerName: 'aquisicao/compra/empenho',
    setupController: function setupController(controller, model) {
      controller.set('model', Util['default'].clona(model));
      controller.inicializaCampos(Util['default'].clona(model));
    },

    model: function model() {
      return Empenho['default'].create();
    }
  });

});