define('webapp/pods/aquisicao/ata/model', ['exports', 'ember', 'webapp/pods/fornecedor/model'], function (exports, Ember, Fornecedor) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		id: null,
		numeroApresentacao: '',
		numeroFisico: '',
		dataVigenciaInicio: '',
		dataVigencia: '',
		fornecedor: Fornecedor['default'].create({}),
		nomeAssinante: '',
		itens: [], //item da ata
		compras: [],
		adesoesExternas: [], //aquisicao/ata/adesao

		inicializar: (function () {
			this.set('id', null);
			this.set('numeroFisico', null);
			this.set('dataVigencia', '');
			this.set('fornecedor', Fornecedor['default'].create({}));
			this.set('nomeAssinante', '');
			this.set('itens', []);
			this.set('compras', []);
			this.set('adesoesExternas', []);
		}).on('init'),

		findUrlFn: function findUrlFn(id) {
			return 'aquisicao/ata/' + id.toString();
		}
	});

});