define('webapp/pods/aquisicao/compra/empenho/identificacao/dados-basicos/template', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.12.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n   ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n   ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col-md-12 text-left");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("fieldset");
          dom.setAttribute(el2,"style","font-weight: bold");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("legend");
          dom.setAttribute(el3,"style","margin-top: 10px; margin-bottom: 5px; font-size: 14px");
          var el4 = dom.createTextNode("Receita*");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(fragment, [5]);
          var element2 = dom.childAt(fragment, [7]);
          var element3 = dom.childAt(fragment, [9]);
          var element4 = dom.childAt(fragment, [11]);
          var element5 = dom.childAt(fragment, [15, 1]);
          var morph0 = dom.createMorphAt(element0,1,1);
          var morph1 = dom.createMorphAt(element0,3,3);
          var morph2 = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
          var morph3 = dom.createMorphAt(element1,1,1);
          var morph4 = dom.createMorphAt(element1,3,3);
          var morph5 = dom.createMorphAt(element2,1,1);
          var morph6 = dom.createMorphAt(element2,3,3);
          var morph7 = dom.createMorphAt(element3,1,1);
          var morph8 = dom.createMorphAt(element3,3,3);
          var morph9 = dom.createMorphAt(element4,1,1);
          var morph10 = dom.createMorphAt(element4,3,3);
          var morph11 = dom.createMorphAt(dom.childAt(fragment, [13]),1,1);
          var morph12 = dom.createMorphAt(element5,3,3);
          var morph13 = dom.createMorphAt(element5,5,5);
          inline(env, morph0, context, "tag-input", [], {"tamanho": 6, "texto": "Nº na Compra", "labelAcessivel": "Número do empenho na compra", "valor": get(env, context, "model.numeroApresentacao"), "mascara": "numeros", "autoFoco": true, "somenteLeitura": true, "id": "tag||id|aquisicao/compra/empenho|identificacao"});
          inline(env, morph1, context, "tag-input", [], {"tamanho": 6, "texto": "Nº Empenho", "labelAcessivel": "Número oficial do empenho", "obrigatorio": true, "valor": get(env, context, "model.numeroFisico"), "mascara": "empenho", "somenteLeitura": get(env, context, "ehEmpenhoExistente"), "id": "tag||numero|aquisicao/compra/empenho|identificacao"});
          inline(env, morph2, context, "tag-select", [], {"texto": "Categoria do Processo", "obrigatorio": true, "conteudo": get(env, context, "categoriasProcesso"), "opcaoLabel": "nome", "valor": get(env, context, "model.categoriaProcesso"), "textopadrao": "Selecione a categoria do processo", "permiteLimpar": true});
          inline(env, morph3, context, "moeda-input", [], {"tamanho": 6, "texto": "Valor Inicial", "valor": get(env, context, "model.valorInicial"), "obrigatorio": true, "id": "tag||valorInicial|aquisicao/compra/empenho|identificacao"});
          inline(env, morph4, context, "decimal-input", [], {"tamanho": 6, "texto": "Número de Parcelas", "valor": get(env, context, "model.numeroParcelas"), "obrigatorio": true, "id": "tag||numeroParcelas|aquisicao/compra/empenho|identificacao"});
          inline(env, morph5, context, "moeda-input", [], {"tamanho": 6, "texto": "Valor da Parcela", "valor": get(env, context, "model.valorParcela"), "id": "tag||valorParcela|aquisicao/compra/empenho|identificacao"});
          inline(env, morph6, context, "moeda-input", [], {"tamanho": 6, "texto": "Valor Global", "valor": get(env, context, "model.valorGlobal"), "obrigatorio": true, "id": "tag||valorGlobal|aquisicao/compra/empenho|identificacao"});
          inline(env, morph7, context, "moeda-input", [], {"tamanho": 6, "texto": "Valor Acumulado", "valor": get(env, context, "model.valorAcumulado"), "id": "tag||valorAcumulado|aquisicao/compra/empenho|identificacao"});
          inline(env, morph8, context, "date-input", [], {"tamanho": 6, "texto": "Data da Assinatura", "valor": get(env, context, "model.dataAssinatura"), "obrigatorio": true, "id": "tag||dataAssinatura|aquisicao/compra/empenho|identificacao"});
          inline(env, morph9, context, "date-input", [], {"tamanho": 6, "texto": "Data de Início da Vigência", "valor": get(env, context, "model.dataVigenciaInicio"), "obrigatorio": true, "id": "tag||dataVigenciaInicio|aquisicao/compra/empenho|identificacao"});
          inline(env, morph10, context, "date-input", [], {"tamanho": 6, "texto": "Data de Fim da Vigência", "valor": get(env, context, "model.dataVigenciaFim"), "obrigatorio": true, "id": "tag||dataVigenciaFim|aquisicao/compra/empenho|identificacao"});
          inline(env, morph11, context, "tag-input", [], {"texto": "Informação Complementar", "valor": get(env, context, "model.informacaoComplementar"), "id": "tag||informacaoComplementar|aquisicao/compra/empenho|identificacao"});
          inline(env, morph12, context, "tag-radio-button", [], {"texto": "Sim", "valor": true, "atributoDestino": get(env, context, "model.receita"), "labelNegrito": true, "tamanho": 6, "id": "radio||eh-receita|aquisicao/compra/empenho|identificacao"});
          inline(env, morph13, context, "tag-radio-button", [], {"texto": "Não", "valor": false, "atributoDestino": get(env, context, "model.receita"), "labelNegrito": true, "tamanho": 6, "id": "radio||nao-eh-receita|aquisicao/compra/empenho|identificacao"});
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, null);
        dom.insertBoundary(fragment, 0);
        block(env, morph0, context, "tag-bloco", [], {"titulo": "Dados Básicos"}, child0, null);
        return fragment;
      }
    };
  }()));

});