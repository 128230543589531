define('webapp/pods/aquisicao/compra/empenho/documento/controller', ['exports', 'ember', 'webapp/mixins/crud-mixin', 'webapp/utils/util', 'webapp/defs', 'webapp/pods/transparencia/documento/constantes-documento'], function (exports, Ember, CrudMixin, Util, Defs, ConstantesDocumento) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(CrudMixin['default'], {
        needs: ['aquisicao/compra/empenho', 'aquisicao'],
        breadCrumbConfig: {
            fnNomeApresentacao: 'fnNomeApresentacao'
        },

        fnNomeApresentacao: function fnNomeApresentacao() {
            return 'Documentos ' + (this.model.descricao ? this.model.descricao : '(Novo)');
        },

        constantesDocumento: ConstantesDocumento['default'].tipos,

        crudConfig: {
            criarComUpload: {
                preparaUploadFn: function preparaUploadFn() {
                    return this.criarDoc();
                },
                okFn: function okFn(opcao) {
                    this.get('controllers.aquisicao').send('ler');
                    if (opcao === 'CRIAR_E_PROXIMO') {
                        this.set('model', []);
                        this.get('controllers.aquisicao/compra/empenho').send('ler');
                        this.send('refreshRota'); //ação "borbulhará" para a rota
                        return;
                    }
                    this.get('controllers.aquisicao/compra/empenho').send('ler');
                    this.transitionToRoute('aquisicao/compra/empenho');
                }
            },
            excluir: {
                urlFn: function urlFn(id) {
                    return 'transparencia/documento/contrato/' + id;
                },
                okFn: function okFn() {
                    this.get('controllers.aquisicao/compra/empenho').send('ler');
                }
            }
        },

        tiposPNCP: [],

        criarDoc: function criarDoc() {
            var formData = new FormData();
            formData.append("idEmpenho", this.get("controllers.aquisicao/compra/empenho").model.id);
            formData.append("modulo", "EMPENHO");
            if (this.model.tipo) {
                formData.append("tipo", "EDITAL");
            }
            formData.append("tipoPNCP", this.get('model.tipoPNCP'));
            formData.append("ordem", "0");
            if (this.model.descricao) {
                formData.append("descricao", this.model.descricao);
            }
            var uploadInput = Ember['default'].$(".row-input-upload input[type=file]").get(0);

            if (uploadInput.files[0]) {
                if (!Util['default'].verificarTipoArquivoValido(uploadInput.files[0].name)) {
                    Util['default'].alertaErro('Formato inválido para o arquivo.');
                    return;
                }

                if (!Util['default'].verificarTamanhoArquivoValido(uploadInput.files[0].size)) {
                    Util['default'].alertaErro('Arquivo com tamanho superior a 30MB.');
                    return;
                }

                formData.append('documento', uploadInput.files[0]);
            }

            return formData;
        },

        carregaTiposDeDocumento: (function () {
            var _this = this;

            this.get('comunicador').servico('GET', 'tiposDeDocumentoPNCP/' + this.get('controllers.aquisicao').model.id + '?tipoEntidade=empenho').then(function (resultado) {
                _this.set('tiposPNCP', resultado);
            });
        }).observes('model'),

        getDescricaoTipoDocumento: function getDescricaoTipoDocumento(documento) {
            if (!!documento.tipoPNCPDescricao) {
                return documento.tipoPNCPDescricao;
            }

            var tipo = this.constantesDocumento.find(function (t) {
                return t.id === documento.tipo;
            });
            if (tipo) {
                return tipo.nome;
            }

            return documento.tipo;
        },

        // Link para o documento
        getLink: function getLink(documento) {
            return Defs['default'].enderecoServer + documento.urlDownload;
        },

        // Texto acessível do documento
        getTextoAcessivel: function getTextoAcessivel(valor, tituloColuna, textoAcessivelDefaultDoComponente) {
            return textoAcessivelDefaultDoComponente + ", download";
        }
    });

});