define('webapp/components/tag-situacao-contrato', ['exports', 'ember', 'webapp/utils/util', 'webapp/pods/pncp/constantes-pncp-envio'], function (exports, Ember, Util, Constantes) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({

    constantesOperacoes: Constantes['default'].operacoes,
    constantesUnidades: Constantes['default'].descricoesUnidades,

    comunicador: Ember['default'].inject.service(),

    tiposSituacao: [{ id: "VIGENTE", codigo: 1, nome: "Vigente", cor: "verde" }, { id: "NAO_VIGENTE", codigo: 2, nome: "Não Vigente", cor: "vemelho" }, { id: "RESCINDIDO", codigo: 3, nome: "Rescindido", cor: "vemelho" }],
    situacaoContrato: null,
    mostrarModalPendenciasDeEnvioAoPNCP: false,

    getDescricaoDaOperacao: function getDescricaoDaOperacao(envioPNCP) {
      // Tenta converter o tipo para uma das opções nas constantes
      var operacao = this.constantesOperacoes.find(function (operacao) {
        return operacao.id === envioPNCP.descricaoDaOperacao;
      });
      if (operacao) {
        return operacao.nome;
      }
      return envioPNCP.descricaoDaOperacao;
    },

    getDescricaoDaUnidade: function getDescricaoDaUnidade(envioPNCP) {
      // Tenta converter o tipo para uma das opções nas constantes
      var unidadeDeEnvio = this.constantesUnidades.find(function (unidade) {
        return unidade.id === envioPNCP.idUnidadeDeEnvio;
      });
      if (unidadeDeEnvio) {
        return unidadeDeEnvio.nome;
      }
      return envioPNCP.descricaoDaUnidadeDeEnvio;
    },

    actions: {
      enviarAgora: function enviarAgora() {
        this.get('comunicador').servico('post', 'aquisicao/contrato/' + this.contrato.id + '/enviaPNCP', null).then(function () {
          window.location.reload();
        });
      },
      exibirModalRescisao: function exibirModalRescisao() {
        this.set("exibeModalRescisao", true);
      },
      cancelarModalRescisao: function cancelarModalRescisao() {
        this.set("exibeModalRescisao", false);
      },
      abrirModalPendenciasDeEnvioAoPNCP: function abrirModalPendenciasDeEnvioAoPNCP() {
        this.set('mostrarModalPendenciasDeEnvioAoPNCP', true);
      }
    },

    corPainel: (function () {
      var _this = this;

      return this.tiposSituacao.find(function (tipo) {
        return tipo.id === _this.situacaoContrato.id;
      }) ? this.tiposSituacao.find(function (tipo) {
        return tipo.id === _this.situacaoContrato.id;
      }).cor : 'verde';
    }).property('contrato'),

    defineSituacaoContrato: (function () {
      var dataHoje = new Date().setHours(0, 0, 0, 0);
      if (!!this.contrato.dataFimVigenciaAtualizada && this.contrato.dataFimVigenciaAtualizada < dataHoje) {
        this.situacaoContrato = this.tiposSituacao.find(function (tipo) {
          return tipo.id === "NAO_VIGENTE";
        });
      } else if (this.ehContratoRescindido()) {
        this.situacaoContrato = this.tiposSituacao.find(function (tipo) {
          return tipo.id === "RESCINDIDO";
        });
      } else {
        this.situacaoContrato = this.tiposSituacao.find(function (tipo) {
          return tipo.id === "VIGENTE";
        });
      }
    }).on('init'),

    descricaoSituacao: (function () {
      var descricao = "";
      switch (this.situacaoContrato.id) {
        case "VIGENTE":
          {
            descricao = "Contrato Vigente" + (this.ehContratoQueTemRescisaoFutura() ? " com rescisão de tipo " + this.contrato.rescisao.tipo + " prevista para " + this.getDataRescisaoFormatada() : "");
            return this.contrato.enviaPNCP ? this.adicionaSituacaoPNCP(descricao) : descricao;
          }
        case "NAO_VIGENTE":
          {
            descricao = "Contrato Encerrado";
            return this.contrato.enviaPNCP ? this.adicionaSituacaoPNCP(descricao) : descricao;
          }
        case "RESCINDIDO":
          {
            descricao = "Contrato com rescisão de tipo " + this.contrato.rescisao.tipo + "em " + this.getDataRescisaoFormatada();
            return this.contrato.enviaPNCP ? this.adicionaSituacaoPNCP(descricao) : descricao;
          }
        default:
          return descricao;
      }
    }).property('contrato.rescisao', 'contrato.enviaPNCP'),

    adicionaSituacaoPNCP: function adicionaSituacaoPNCP(descricaoSituacao) {
      if (this.contrato.pendenteEnvioAoPNCP) {
        return descricaoSituacao.concat(" (Pendente de Envio ao PNCP)");
      }
      return !!this.contrato.pncpseq && this.contrato.pncpano ? descricaoSituacao.concat(" (Divulgado no PNCP)") : descricaoSituacao.concat(" (Pendente de Envio ao PNCP)");
    },

    ehContratoQueTemRescisaoFutura: function ehContratoQueTemRescisaoFutura() {
      var dataHoje = new Date().setHours(0, 0, 0, 0);
      return !!this.contrato.rescisao.data && this.contrato.rescisao.data !== '' && dataHoje < this.contrato.rescisao.data;
    },

    ehContratoQueTemRescisaoFuturaProperty: (function () {
      var dataHoje = new Date().setHours(0, 0, 0, 0);
      return !!this.contrato.rescisao.data && this.contrato.rescisao.data !== '' && dataHoje < this.contrato.rescisao.data;
    }).property('contrato.rescisao.data'),

    ehContratoEncerrado: function ehContratoEncerrado() {
      var dataHoje = new Date().setHours(0, 0, 0, 0);
      return !this.ehContratoRescindido() && !!this.contrato.dataFimVigenciaAtualizada && this.contrato.dataFimVigenciaAtualizada < dataHoje;
    },

    ehContratoRescindido: function ehContratoRescindido() {
      var dataHoje = new Date().setHours(0, 0, 0, 0);
      return !!this.contrato.rescisao.data && dataHoje >= this.contrato.rescisao.data;
    },

    getDataRescisaoFormatada: function getDataRescisaoFormatada() {
      if (this.contrato.rescisao.data) {
        return Util['default'].formataData(this.contrato.rescisao.data);
      }
    },

    mostrarLinkPNCP: (function () {
      return this.contrato.urlPNCP;
    }).property('contrato.urlPNCP'),

    ehEnvioAoPNCP: (function () {
      return this.contrato.enviaPNCP;
    }).property('contrato.enviaPNCP'),

    mostrarBotaoEnviarAgora: (function () {
      return this.contrato.enviaPNCP && this.contrato.pncpenvioDTO;
    }).property('contrato.enviaPNCP', 'contrato.pncpenvioDTO'),

    mostrarBotaoVincularIdPNCP: (function () {
      return !this.contrato.enviaPNCP && !this.contrato.urlPNCP;
    }).property('contrato.enviaPNCP', 'contrato.urlPNCP')
  });

});